import { React, useEffect, useState } from "react";
import { material } from "../../library/material";
import { useFieldArray } from "react-hook-form";
import Agriculture from "../agriculture-form/Agriculture";
import StructureForm from "../structure-details-form/StructureForm";


function ParcelLandUseForm({ control, watch, register, setValue }) {
  const landCategoriesList = [
    { name: "Agricultural Land", value: "Agricultural Land" },
    { name: "Fallow Land", value: "Fallow Land" },
    {
      name: "Homestead & non-agricultural land",
      value: "Homestead & non-agricultural land",
    },
    { name: "Wetland/Pond", value: "Wetland/Pond" },
    { name: "Commercial", value: "Commercial" },
    { name: "Others", value: "Others" },
  ];

  const landUsePerposeList = [
    { name: "Farm Activities", value: "Farm Activities" },
    { name: "Structures", value: "Structures" },
    { name: "Others", value: "Others" },
  ];

  const farmActivitiesList = [
    { name: "Agriculture", value: "Agriculture" },
    { name: "Trees", value: "Trees" },
    { name: "Pisciculture", value: "Pisciculture" },
    { name: "Livestock rearing", value: "Livestock rearing" },
    { name: "Cash Crops", value: "Cash Crops" },
  ];

  const structureTypeList = [
    { name: "Residential", value: "Residential" },
    { name: "Commercial/Industrial", value: "Commercial/Industrial" },
  ];

  const {
    fields: agricultureField,
    append: appendAgriculture,
    remove: removeAgriculture,
  } = useFieldArray({
    control: control,
    name: "agricultureDetails",
  });
  const {
    fields: residentialField,
    append: appendResidential,
    remove: removeResidential,
  } = useFieldArray({
    control: control,
    name: "residentialDetails",
  });
  const {
    fields: commercialField,
    append: appendCommercial,
    remove: removeCommercial,
  } = useFieldArray({
    control: control,
    name: "commercialDetails",
  });
  let selectedFarmActivities = watch("selectedFarmActivities");
  let selectedStructureType = watch("selectedStructureType");
  // useEffect(() => {
  //   setValue("selectedFarmActivities", []);
  //   selectedFarmActivities = watch("selectedFarmActivities");
  // }, [watch("selectedFarmActivities")]);

  return (
    <div className="row">
      <div className="col-sm-12 col-md-6 col-lg-4 px-2 mt-3">
        <material.FormControl size="small" variant="standard" className="w-100">
          <material.InputLabel id="demo-select-small-label">
            Land Use as per Revenue Records
          </material.InputLabel>
          <material.Select
            name="landUseAsPerRevenueRecords"
            {...register("landUseAsPerRevenueRecords", { required: true })}
            labelId="demo-select-small-label"
            id="demo-select-small"
            // value={selectedRelation}
            label="Select an option"
            // onChange={onChangeRelationship}
            required
          >
            {landCategoriesList.map((ele, i) => (
              <material.MenuItem key={i} value={ele.value}>
                {ele.name}
              </material.MenuItem>
            ))}
          </material.Select>
        </material.FormControl>
      </div>
      <div className="col-sm-12 col-md-6 col-lg-4 px-2 mt-3">
        <material.FormControl size="small" variant="standard" className="w-100">
          <material.InputLabel id="demo-select-small-label">
            Current Land Use Based on Parcel Area acquired for the Project
          </material.InputLabel>
          <material.Select
            name="landUsePerpose"
            {...register("landUsePerpose", { required: true })}
            labelId="demo-select-small-label"
            id="demo-select-small"
            // value={selectedRelation}
            label="Select an option"
            // onChange={onChangeRelationship}
            required
          >
            {landUsePerposeList.map((ele, i) => (
              <material.MenuItem key={i} value={ele.value}>
                {ele.name}
              </material.MenuItem>
            ))}
          </material.Select>
        </material.FormControl>
      </div>
      {watch("landUsePerpose") === "Farm Activities" ? (
        <>
          <div className="col-sm-12 col-md-6 col-lg-4 px-2 mt-3">
            <material.FormControl
              size="small"
              variant="standard"
              className="w-100"
            >
              <material.InputLabel id="demo-select-small-label">
                Select Farm Activities
              </material.InputLabel>
              <material.Select
                name="selectedFarmActivities"
                {...register("selectedFarmActivities", { required: true })}
                labelId="demo-select-small-label"
                id="demo-select-small"
                label="Select an option"
                required
                multiple
                value={selectedFarmActivities}
                onChange={(e) =>
                  setValue("selectedFarmActivities", e.target.value, {
                    shouldValidate: true,
                  })
                }
                // error={!!errors.selectedFarmActivities}
                renderValue={(selected) => (
                  <div>
                    {selected.map((value) => (
                      <span key={value}>{value}, </span>
                    ))}
                  </div>
                )}
              >
                {farmActivitiesList.map((ele, i) => (
                  <material.MenuItem key={i} value={ele.value}>
                    <material.Checkbox
                      color="primary"
                      checked={selectedFarmActivities.includes(ele.value)}
                    />
                    <material.ListItemText primary={ele.name} />
                  </material.MenuItem>
                ))}
              </material.Select>
              {/* {errors.selectedFarmActivities && (
            <material.FormHelperText error>
              Please select at least one option
            </material.FormHelperText>
          )} */}
            </material.FormControl>
          </div>
          {        console.log(watch('selectedFarmActivities').includes("Agriculture"))}
          {
            watch('selectedFarmActivities').includes("Agriculture")?(
              <Agriculture control={control} watch={watch} register={register} setValue={setValue}/>
            ):""
          }
        </>
      ) : watch("landUsePerpose") === "Structures" ? (
        <>
          <div className="col-sm-12 col-md-6 col-lg-4 px-2 mt-3">
            <material.FormControl
              size="small"
              variant="standard"
              className="w-100"
            >
              <material.InputLabel id="demo-select-small-label">
                Select Structure Type
              </material.InputLabel>
              <material.Select
                name="selectedStructureType"
                {...register("selectedStructureType", { required: true })}
                labelId="demo-select-small-label"
                id="demo-select-small"
                label="Select an option"
                required
                multiple
                value={selectedStructureType}
                onChange={(e) =>
                  setValue("selectedStructureType", e.target.value, {
                    shouldValidate: true,
                  })
                }
                // error={!!errors.selectedStructureType}
                renderValue={(selected) => (
                  <div>
                    {selected.map((value) => (
                      <span key={value}>{value}, </span>
                    ))}
                  </div>
                )}
              >
                {structureTypeList.map((ele, i) => (
                  <material.MenuItem key={i} value={ele.value}>
                    <material.Checkbox
                      color="primary"
                      checked={selectedStructureType?.includes(ele.value)}
                    />
                    <material.ListItemText primary={ele.name} />
                  </material.MenuItem>
                ))}
              </material.Select>
              {/* {errors.selectedFarmActivities && (
            <material.FormHelperText error>
              Please select at least one option
            </material.FormHelperText>
          )} */}
            </material.FormControl>
          </div>
          {
           watch('selectedStructureType').includes('Residential')&&(
            <StructureForm control={control} watch={watch} register={register} setValue={setValue} callFrom={'Residential'}/>
           )
          }
          {
            watch('selectedStructureType').includes('Commercial/Industrial')&&(
              <StructureForm control={control} watch={watch} register={register} setValue={setValue} callFrom={'Commercial/Industrial'}/>
             )
          }
        </>
      ) : (
        ""
      )}
    </div>
  );
}
export default ParcelLandUseForm;
