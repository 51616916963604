import { createChartDataForCensus2011, createReportChartDataForCensus2011, createTableDataStructureForCensus2011 } from "./createChartDataForCensus";
import { createLuLcChartData, createReportChartDataForLuLc } from "./createChartDataForLuLc";
import {
  createChartDataForNoise,
  generateReportChartDataStructureForNoise,
} from "./createChartDataForNoise";
import {
  createChartDataForSurfaceWater,
  generateReportChartDataForSurfaceWater,
} from "./createChartDataForSurfaceWater";
import {
  generateAirTableData,
  generateDataForChart,
  generateReportChartDataStructure,
  getAverages,
} from "./generateAirChartData";

export const creatDataFormatToGenerateReport = (spatialQueryData) => {
  if (spatialQueryData.length > 0) {
    return spatialQueryData.map((layerData, layerInd) => {
      let obj = { ...layerData };
      obj["charts"] = [];
      obj["reportCharts"] = [];
      // HERE I GENERATE THE DATA FOR REPORT
      if (obj.layerName === "aqdata") {
        obj.columnName = [
          { header: "Station Code", dataKey: "code" },
          { header: "Monitoring Locations", dataKey: "location" },
          { header: "Geo-Coordinate", dataKey: "geo" },
          { header: "Reporting Format", dataKey: "format" },
          { header: "PM10 (µg/m³)", dataKey: "pm10" },
          { header: "PM2.5 (µg/m³)", dataKey: "pm25" },
          { header: "SO2 (µg/m³)", dataKey: "so2" },
          { header: "NO2 (µg/m³)", dataKey: "no2" },
          { header: "CO (mg/m³)", dataKey: "co" },
          { header: "O3 (µg/m³)", dataKey: "o3" },
          { header: "C6H6 (µg/m³)", dataKey: "c6h6" },
          { header: "BaP (ng/m³)", dataKey: "bap" },
          { header: "NH3 (µg/m³)", dataKey: "nh3" },
          { header: "As (ng/m³)", dataKey: "as" },
          { header: "Pb (ng/m³)", dataKey: "pb" },
          { header: "Ni (ng/m³)", dataKey: "ni" },
          { header: "Distance", dataKey: "distance" },
        ];

        let transformedData = generateAirTableData(obj.tableData);
        console.log(transformedData);

        obj.tableData = transformedData.map((data) => ({
          code: data.code,
          location: data.location,
          geo: data.geo,
          format: "Min\nMax\nAvg.\n98%",
          pm10: `${data.pm10.min}\n${data.pm10.max}\n${data.pm10.avg}\n${data.pm10.percentile_98}`,
          pm25: `${data.pm25.min}\n${data.pm25.max}\n${data.pm25.avg}\n${data.pm25.percentile_98}`,
          so2: `${data.so2.min}\n${data.so2.max}\n${data.so2.avg}\n${data.so2.percentile_98}`,
          no2: `${data.no2.min}\n${data.no2.max}\n${data.no2.avg}\n${data.no2.percentile_98}`,
          co: `${data.co.min}\n${data.co.max}\n${data.co.avg}\n${data.co.percentile_98}`,
          o3: `${data.o3.min}\n${data.o3.max}\n${data.o3.avg}\n${data.o3.percentile_98}`,
          c6h6: `${data.c6h6.min}\n${data.c6h6.max}\n${data.c6h6.avg}\n${data.c6h6.percentile_98}`,
          bap: `${data.bap.min}\n${data.bap.max}\n${data.bap.avg}\n${data.bap.percentile_98}`,
          nh3: `${data.nh3.min}\n${data.nh3.max}\n${data.nh3.avg}\n${data.nh3.percentile_98}`,
          as: `${data.as.min}\n${data.as.max}\n${data.as.avg}\n${data.as.percentile_98}`,
          pb: `${data.pb.min}\n${data.pb.max}\n${data.pb.avg}\n${data.pb.percentile_98}`,
          ni: `${data.ni.min}\n${data.ni.max}\n${data.ni.avg}\n${data.ni.percentile_98}`,
        }));

        let getAvgValuesOfPollutant = getAverages(transformedData);
        // console.log(getAvgValuesOfPollutant)
        let chartData = generateDataForChart(getAvgValuesOfPollutant);
        obj.charts = chartData.map((cData, cInd) => {
          let chaartDataObj = {
            chartData: cData[0],
            chartOptions: cData[1],
            chartType: "ComboChart",
          };
          return chaartDataObj;
        });
        obj.reportCharts = generateReportChartDataStructure(
          getAvgValuesOfPollutant
        );
      } else if (obj.layerName === "noise data") {
        obj.columnName = [
          { header: "Monitoring Location", dataKey: "monitoring" },
          { header: "Station Number", dataKey: "station nu" },
          { header: "Geo-Location", dataKey: "geo" },
          { header: "Category Of Area", dataKey: "category o" },
          { header: "Leq Day (dB/(A))", dataKey: "leq day (d" },
          { header: "Day time Standared (dB/(A))", dataKey: "day time s" },
          { header: "Leq Night (dB/(A))", dataKey: "leq night" },
          { header: "Night time Standared (dB/(A))", dataKey: "night time" },
          { header: "Distance", dataKey: "distance" },
        ];
        obj.charts = createChartDataForNoise(obj.tableData);
        obj.reportCharts = generateReportChartDataStructureForNoise(
          obj.tableData
        );
      } else if (obj.layerName === "sw_latin") {
        obj.columnName = [
          { header: "Name", dataKey: "name" },
          { header: "Station Number", dataKey: "sw stn no." },
          // { header: "Geo-Location", dataKey: "geo" },
          { header: "Total Coliform", dataKey: "total coli" },
          { header: "pH@ 25°c", dataKey: "ph @ 25 ??" },
          { header: "Dissolved Oxygen", dataKey: "dissolved" },
          {
            header: "Biochemical Oxygen Demand(3 days at 27°c",
            dataKey: "biochemica",
          },
          { header: "Ammonia", dataKey: "ammonia" },
          { header: "Conductivity", dataKey: "conductivi" },
          { header: "Sodium Absorption Ratio", dataKey: "sodium abs" },
          { header: "Oil & Grease", dataKey: "oil & grea" },
          { header: "Colour", dataKey: "colour" },
          { header: "Chemical Oxygen Demand", dataKey: "chemical o" },
          { header: "Distance", dataKey: "distance" },
        ];
        obj.charts = createChartDataForSurfaceWater(obj.tableData);
        obj.reportCharts = generateReportChartDataForSurfaceWater(
          obj.tableData
        );
        console.log(obj.reportCharts);
      } else if (obj.layerName === "WB_LULC50K_1516") {
        obj.columnName = obj.columnName.map((column, clInd) => {
          return { header: column, dataKey: column };
        });
        obj.charts = createLuLcChartData(obj.tableData, obj.totalSelectedArea);
        obj.reportCharts = createReportChartDataForLuLc(
          obj.tableData,
          obj.totalSelectedArea
        );
      } else if (obj.layerName === "east_medinipur_Census_2011") {
        const calculateValue = (numerator, denominator, multiplier = 100) => 
          isNaN(numerator / denominator) || denominator === 0 ? 0 : Number(((numerator / denominator) * multiplier).toFixed(2));
      
      const calculateDifference = (total, subtrahend) => 
          isNaN(total - subtrahend) ? 0 : Number((total - subtrahend).toFixed(2));
      
      const calculateSum = (...values) => 
          values.some(isNaN) ? 0 : Number(values.reduce((acc, value) => acc + value, 0).toFixed(2));
          let data = obj.tableData.map((ele) => {
              // Calculating sex ratio
              ele["sex_ratio"] = calculateValue(ele.tot_f, ele.tot_m, 1000);
      
              // Calculating indigenous population percentage
              ele["indigenous_population"] = calculateValue(ele.p_st, ele.tot_p);
      
              // Calculating literacy rates
              ele["lit_rate"] = calculateValue(ele.p_lit, ele.tot_p);
              ele["m_lit_rate"] = calculateValue(ele.m_lit, ele.tot_m);
              ele["f_lit_rate"] = calculateValue(ele.f_lit, ele.tot_f);
      
              // Calculating non-worker population excluding age group 0-6
              ele["tot_non_workers_exc_0_6"] = calculateDifference(ele.tot_p, ele['p_06']);
              ele["tot_non_workers_m_exc_0_6"] = calculateDifference(ele.tot_m, ele['m_06']);
              ele["tot_non_workers_f_exc_0_6"] = calculateDifference(ele.tot_f, ele['f_06']);
      
              // Calculating worker population rates
              ele["worker_population_rate"] = calculateValue(ele.tot_work_p, ele.tot_non_workers_exc_0_6);
              ele["worker_population_rate_m"] = calculateValue(ele.tot_work_m, ele.tot_non_workers_m_exc_0_6);
              ele["worker_population_rate_f"] = calculateValue(ele.tot_work_f, ele.tot_non_workers_f_exc_0_6);
      
              // Calculating female worker percentage
              ele["worker_percentage_f"] = calculateValue(ele.tot_work_f, ele.tot_non_workers_exc_0_6);
      
              // Calculating agricultural workers and their percentage
              ele["tot_ag_workers"] = calculateSum(ele.main_cl_p, ele.main_al_p, ele.marg_cl_p, ele.marg_al_p);
              ele["tot_ag_workers_percentage"] = calculateValue(ele.tot_ag_workers, ele.tot_work_p);
      
              // Calculating industrial workers and their percentage
              ele["tot_indu_workers"] = calculateSum(ele.main_ot_p, ele.marg_ot_p);
              ele["indu_workers_percentage"] = calculateValue(ele.tot_indu_workers, ele.tot_work_p);
      
              return ele;
          });
        obj.charts=createChartDataForCensus2011(data)
        obj.reportCharts=createReportChartDataForCensus2011(data)
        obj.tableData=createTableDataStructureForCensus2011(data)
      } else {
        obj.columnName = obj.columnName.map((column, clInd) => {
          return { header: column, dataKey: column };
        });
      }
      return obj;
    });
  }
};

export const mergeColumnAndChangeDataStructure = (data) => {
  let obj = { ...data };
  let airColumnList = ["code", "location", "geo"];
  let waterColumnList = ["name", "sw stn no."];
  let noiseColumnList = ["monitoring", "station nu", "geo"];
  let columnList = [];
  if (data.layerName === "noise data") {
    columnList = noiseColumnList;
    obj.columnName = [
      { header: "Monitoring Location", dataKey: "monitoringLocation" },
      { header: "Category Of Area", dataKey: "category o" },
      { header: "Leq Day (dB/(A))", dataKey: "leq day (d" },
      { header: "Day time Standared (dB/(A))", dataKey: "day time s" },
      { header: "Leq Night (dB/(A))", dataKey: "leq night" },
      { header: "Night time Standared (dB/(A))", dataKey: "night time" },
    ];
  } else if (data.layerName === "sw_latin") {
    columnList = waterColumnList;
    obj.columnName = [
      { header: "Monitoring Location", dataKey: "monitoringLocation" },
      { header: "Total Coliform", dataKey: "total coli" },
      { header: "pH@ 25°c", dataKey: "ph @ 25 ??" },
      { header: "Dissolved Oxygen", dataKey: "dissolved" },
      {
        header: "Biochemical Oxygen Demand(3 days at 27°c",
        dataKey: "biochemica",
      },
      { header: "Ammonia", dataKey: "ammonia" },
      { header: "Conductivity", dataKey: "conductivi" },
      { header: "Sodium Absorption Ratio", dataKey: "sodium abs" },
      { header: "Oil & Grease", dataKey: "oil & grea" },
      { header: "Colour", dataKey: "colour" },
      { header: "Chemical Oxygen Demand", dataKey: "chemical o" },
    ];
  } else if (data.layerName === "aqdata") {
    columnList = airColumnList;
    obj.columnName = [
      { header: "Monitoring Location", dataKey: "monitoringLocation" },
      { header: "Reporting Format", dataKey: "format" },
      { header: "PM10 (µg/m³)", dataKey: "pm10" },
      { header: "PM2.5 (µg/m³)", dataKey: "pm25" },
      { header: "SO2 (µg/m³)", dataKey: "so2" },
      { header: "NO2 (µg/m³)", dataKey: "no2" },
      { header: "CO (mg/m³)", dataKey: "co" },
      { header: "O3 (µg/m³)", dataKey: "o3" },
      { header: "C6H6 (µg/m³)", dataKey: "c6h6" },
      { header: "BaP (ng/m³)", dataKey: "bap" },
      { header: "NH3 (µg/m³)", dataKey: "nh3" },
      { header: "As (ng/m³)", dataKey: "as" },
      { header: "Pb (ng/m³)", dataKey: "pb" },
      { header: "Ni (ng/m³)", dataKey: "ni" },
    ];
  } else {
    columnList = null;
  }

  if (columnList === null) return data;

  let tableData = [...obj.tableData];
  tableData.forEach((row) => {
    let mergeData = "";
    columnList.map((columnName) => {
      mergeData +=
        typeof row[columnName] === "undefined" ? "" : row[columnName];
      return null;
    });
    row["monitoringLocation"] = mergeData;
    columnList.map((columnName) => {
      delete row[columnName];
      return null;
    });
    mergeData = "";
  });

  obj.tableData = tableData;
  console.log(obj);
  return obj;
};
