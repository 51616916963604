import React, { useEffect, useState } from 'react'
import "./Login.css"
import { material } from '../../library/material'
import { useForm } from 'react-hook-form';
import { userLogin } from '../../services/LoginService';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { loginSuccess } from '../../authentication/AuthActions';
import Alert from '../alert-message/Alert';
import { setNavigateCallback } from '../interceptor/Iterceptor';
import logo from '../../assets/map-image/app_logo.png'

function Login() {
    const navigate = useNavigate()
    const [show, setShow] = useState(false);
    const [messageInfo, setMessageInfo] = useState({});
    const dispatch = useDispatch();

    const {
        register,
        getValues,
        handleSubmit, reset,
        formState: { errors, isValid }
    } = useForm();

    useEffect(() => {
        setNavigateCallback(navigate);
    }, []);

    const showHidePassWord = () => {
        setShow(!show);
    };

    const logIn = (formData) => {
        // navigate('/home')
        userLogin(formData).then((res) => {
            setMessageInfo({
                message: 'You have successfully loged in',
                type: 'success',
                isVisiable: true
            })
            setTimeout(() => {
                dispatch(loginSuccess(res.data));
                navigate('/home')
            }, 2000)
        }).catch((error) => {
            console.log(error)
            setMessageInfo({
                message: error?.response?.data?.message || error.message,
                type: 'error',
                isVisiable: true
            })
        })
    }

    return (
        <>
            <div className='main-login-container'>
                {/* <div classNameName="login-box">
                <h2>Login</h2>
                <form>
                    <div classNameName="user-box">
                        <input type="text" name="" required="" />
                        <label>Username</label>
                    </div>
                    <div classNameName="user-box">
                        <input type="password" name="" required="" />
                        <label>Password</label>
                    </div>
                    <a href="#">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        Submit
                    </a>
                </form>
            </div> */}

                {/* <div className="form-bg"> */}
                {/* <div className="container-fluid "> */}
                <div className="row d-flex align-items-center justify-content-center main-login-container m-0">
                    <div className="col-lg-offset-3 col-lg-6 col-md-offset-2 col-md-8">
                        <div className="form-container">
                            <div className="form-icon">
                                <i className="fa fa-user-circle"></i>
                                <span className="signup">Lets,start</span>
                            </div>
                            <form className="form-horizontal">
                                <div className='d-flex justify-content-center align-items-center px-2'> <img src={logo} alt="" /></div>
                                <h3 className="title">Member Login</h3>
                                <div className="form-group">
                                    <span className="input-icon1"><i className="fa fa-envelope"></i></span>
                                    <input  {...register("username", { required: true })} className="form-control" type="email" placeholder="Username*" required />
                                </div>
                                <div className="form-group">
                                    <span className="input-icon1"><i className="fa fa-lock"></i></span>
                                    {
                                        show ? <span className="input-icon3" onClick={showHidePassWord}><i className="fa-solid fa-eye-slash"></i></span> : <span className="input-icon2" onClick={showHidePassWord}><i className="fa-solid fa-eye"></i></span>
                                    }
                                    <input  {...register("password", { required: true })} className="form-control" type={show ? "text" : "password"} placeholder="Password*" required />
                                </div>
                                <button className="btn signin" onClick={handleSubmit(logIn)} disabled={!isValid}>Login</button>
                                {/* <span className="forgot-pass"><a href="#">Forgot Username/Password?</a></span> */}
                            </form>
                        </div>
                    </div>
                </div>
                {/* </div> */}
                {/* </div> */}
            </div>
            <Alert messageInfo={messageInfo} />
        </>
    )
}

export default Login