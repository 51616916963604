import React from 'react'
import MapComponent from '../map/Map'


function Home() {
  return (
    <div>
      <MapComponent/>
    </div>
  )
}

export default Home