import React, { useEffect, useState } from "react";
import { useFieldArray } from "react-hook-form";
import { material } from "../../library/material";
//import { Key } from "@mui/icons-material";
import "./ParcelOwnershipDetailsForm.css";
import SocioEconomicDetailsForm from "../socio-economic-details-form/SocioEconomicDetailsForm";

function ParcelOwnershipDetailsForm({
  control,
  Controller,
  fields,
  remove,
  watch,
  register,
  setValue,
}) {
  const [isParcelOwnerSameAsRor, setIsParcelOwnerSameAsRor] = useState(true);
  const [isParcelOwnerPresent, setIsParcelOwnerPresent] = useState(true);
  const [governmentLand, setGovernmentLand] = useState(false);
  const [isAnyUserUseThisLand, setIsAnyUserUseThisLand] = useState(false);
  const [landUsers, setLandUsers] = useState(true);
  const [selectedRelation, setSelectedRelation] = useState();
  const [selectedLansuserType, setSelectedLanduserType] = useState();
  const [selectedDurationType, setSelectedDurationType] = useState();
  const [isLandUserPresent, setIsLandUserPresent] = useState(true);

  const relationList = [
    { relationName: "Kin (within the family)", value: "01" },
    { relationName: "Relative", value: "02" },
    { relationName: "Representative", value: "03" },
  ];

  const landUserTypeList = [
    { landUserType: "Sharecropper", value: "01" },
    { landUserType: "Renter", value: "02" },
    { landUserType: "Leaser", value: "03" },
    { landUserType: "Labourer", value: "04" },
  ];

  const {
    fields: landUserDetailsField,
    append: appendLandUser,
    remove: removelandUser,
  } = useFieldArray({
    control: control,
    name: "landUserDetails",
  });

  const {
    fields: govLandUserDetailsField,
    append: appendGovLandUser,
    remove: removeGovlandUser,
  } = useFieldArray({
    control: control,
    name: "govLandUserDetails",
  });

  useEffect(() => {
    setIsParcelOwnerSameAsRor(watch("isParcelOwnerSameAsRor"));
    setIsParcelOwnerPresent(watch("isParcelOwnerPresent"));
    setGovernmentLand(watch("isGovernmentLand"));
    setIsAnyUserUseThisLand(watch("isAnyUserPresent"));
    setLandUsers(watch("landUsers"));
    setIsLandUserPresent(watch("isLandUserPresent"));
    console.log(watch("landUsers"));
  }, [
    watch("isParcelOwnerSameAsRor"),
    watch("isGovernmentLand"),
    watch("isAnyUserPresent"),
    watch("isParcelOwnerPresent"),
    watch("landUsers"),
    watch("isLandUserPresent"),
  ]);

  // useEffect(() => {
  //   let inputedLandUser = watch("numberOfLandUser");
  //   if (inputedLandUser > 0) {
  //     for (let i = 0; i < inputedLandUser; i++) {
  //       appendLandUser();
  //     }
  //   }
  // }, [watch("numberOfLandUser")]);

  const onChangeRelationship = (event) => {
    setSelectedRelation(event.target.value);
  };

  const onChangeLanduserType = (event) => {
    setSelectedLanduserType(event.target.value);
  };

  const onChangeDurationType = (event) => {
    setSelectedDurationType(event.target.value);
  };

  return (
    <div>
      <form className="row">
      <div className="col-12 col-md-12 col-sm-12 d-flex flex-row align-items-center mt-3">
          <label
            id="demo-row-radio-buttons-group-label"
            className="me-4 fs-4 fw-bold"
          >
            Is the land is under government ?
          </label>
          <Controller
            name="isGovernmentLand"
            control={control}
            defaultValue={governmentLand}
            rules={{ required: "Please select an option" }}
            render={({ field }) => (
              <material.RadioGroup row aria-label="selectedOption" {...field}>
                <material.FormControlLabel
                  value="true"
                  control={<material.Radio />}
                  label="Yes"
                />
                <material.FormControlLabel
                  value="false"
                  control={<material.Radio />}
                  label="No"
                />
              </material.RadioGroup>
            )}
          />
        </div>
      <div className="col-4 col-md-6 col-sm-12">
              <material.TextField
                name="parcelOwnerName"
                {...register("parcelOwnerName", { required: true })}
                label="Parcel Owner’s Name"
                id="outlined-size-small"
                size="small"
                type="text"
                fullWidth
                variant="standard"
                className="mt-3 w-100"
                required
              />
            </div>
            <div className="col-4 col-md-6 col-sm-12">
              <material.TextField
                name="poltNo"
                {...register("poltNo", { required: true })}
                label="Plot/DAG/Khasra No."
                id="outlined-size-small"
                size="small"
                type="text"
                fullWidth
                variant="standard"
                className="mt-3 w-100"
                required
              />
            </div>

            <div className="col-4 col-md-6 col-sm-12 d-flex flex-row align-items-center mt-3">
              <label id="demo-row-radio-buttons-group-label" className="me-2">
                Parcel Owner same as RoR
              </label>
              <Controller
                name="isParcelOwnerSameAsRor"
                control={control}
                defaultValue=""
                rules={{ required: "Please select an option" }}
                render={({ field }) => (
                  <material.RadioGroup
                    row
                    aria-label="selectedOption"
                    {...field}
                  >
                    <material.FormControlLabel
                      value="true"
                      control={<material.Radio />}
                      label="Yes"
                    />
                    <material.FormControlLabel
                      value="false"
                      control={<material.Radio />}
                      label="No"
                    />
                  </material.RadioGroup>
                )}
              />
            </div>
        
        {governmentLand === "true" ? (
          <>
            <div className="col-12 col-md-12 col-sm-12 d-flex flex-row align-items-center mt-3">
              <label
                id="demo-row-radio-buttons-group-label"
                className="me-4 fs-4 fw-bold"
              >
                Does any user use this land ?
              </label>
              <Controller
                name="isAnyUserPresent"
                control={control}
                defaultValue=""
                rules={{ required: "Please select an option" }}
                render={({ field }) => (
                  <material.RadioGroup
                    row
                    aria-label="selectedOption"
                    {...field}
                  >
                    <material.FormControlLabel
                      value="true"
                      control={<material.Radio />}
                      label="Yes"
                    />
                    <material.FormControlLabel
                      value="false"
                      control={<material.Radio />}
                      label="No"
                    />
                  </material.RadioGroup>
                )}
              />
            </div>
          </>
        ) : (
          <>
            {/* Conditionally render TextField if 'isParcelOwnerSameAsRor' is 'true' */}
            {isParcelOwnerSameAsRor == "false" && (
              <>
                <div className="col-4 col-sm-12 col-md-6">
                  <material.TextField
                    name="currentParcelOwnerName"
                    {...register("currentParcelOwnerName", {
                      required: true,
                    })}
                    label="Name of Current Parcel Owner"
                    id="outlined-size-small"
                    size="small"
                    type="text"
                    fullWidth
                    variant="standard"
                    className="mt-3 w-100"
                    required
                  />
                </div>
              </>
            )}

            <div className="col-4 col-md-6 col-sm-12 d-flex flex-row align-items-center mt-3">
              <label id="demo-row-radio-buttons-group-label" className="me-2">
                Is Parcel Owner present?
              </label>
              <Controller
                name="isParcelOwnerPresent"
                control={control}
                defaultValue=""
                rules={{ required: "Please select an option" }}
                render={({ field }) => (
                  <material.RadioGroup
                    row
                    aria-label="selectedOption"
                    {...field}
                  >
                    <material.FormControlLabel
                      value="true"
                      control={<material.Radio />}
                      label="Yes"
                    />
                    <material.FormControlLabel
                      value="false"
                      control={<material.Radio />}
                      label="No"
                    />
                  </material.RadioGroup>
                )}
              />
            </div>

            {isParcelOwnerPresent == "false" && (
              <>
                <div className="col-4 col-sm-12 col-md-6">
                  <material.TextField
                    name="nameOfRespondent"
                    {...register("nameOfRespondent", {
                      required: true,
                    })}
                    label="Name of Respondent"
                    id="outlined-size-small"
                    size="small"
                    type="text"
                    fullWidth
                    variant="standard"
                    className="mt-3 w-100"
                    required
                  />
                </div>

                <div className="col-4 col-sm-12 col-md-6">
                  <material.FormControl
                    size="small"
                    variant="standard"
                    className="mt-3 w-100"
                  >
                    <material.InputLabel id="demo-select-small-label">
                      Relationship of Respondent With Parcel Owner
                    </material.InputLabel>
                    <material.Select
                      name="relationshipWithParcelOwner"
                      {...register("relationshipWithParcelOwner", {
                        required: true,
                      })}
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={selectedRelation}
                      label="Select an option"
                      onChange={onChangeRelationship}
                      required
                    >
                      {relationList.map((ele, i) => (
                        <material.MenuItem key={i} value={ele.value}>
                          {ele.relationName}
                        </material.MenuItem>
                      ))}
                    </material.Select>
                  </material.FormControl>
                </div>
              </>
            )}

            <div className="col-4 col-md-6 col-sm-12 d-flex flex-row align-items-center mt-3">
              <label id="demo-row-radio-buttons-group-label" className="me-2">
                Land Users ?
              </label>
              <Controller
                name="landUsers"
                control={control}
                defaultValue=""
                rules={{ required: "Please select an option" }}
                render={({ field }) => (
                  <material.RadioGroup
                    row
                    aria-label="selectedOption"
                    {...field}
                  >
                    <material.FormControlLabel
                      value="true"
                      control={<material.Radio />}
                      label="Yes"
                    />
                    <material.FormControlLabel
                      value="false"
                      control={<material.Radio />}
                      label="No"
                    />
                  </material.RadioGroup>
                )}
              />
            </div>
            {console.log(landUsers)}
            {landUsers === "true" && (
              <>
                <div className="col-4 col-sm-12 col-md-6 mt-3">
                  <material.TextField
                    {...register("numberOfLandUser", {
                      required: true,
                    })}
                    label="Number Of Land Users"
                    variant="standard"
                    type="number"
                    fullWidth
                    size="small"
                  />
                </div>
                <div className="col-12 mt-3">
                  <span className="float-end">
                    <material.Button
                      type="button"
                      variant="contained"
                      onClick={() => appendLandUser({})}
                    >
                      Add Landuser Details
                    </material.Button>
                  </span>
                </div>
                <div className="col-12 mt-2">
                  {landUserDetailsField.map((field, index) => (
                    <div
                      key={field.id}
                      className="row p-2 border boder-3 border-primary rounded-2 mt-2"
                    >
                      <div className="col-12">
                        <material.Button
                          type="button"
                          variant="contained"
                          hidden={watch("numberOfLandUser") == index - 1}
                          onClick={() => removelandUser(index)}
                        >
                          Remove
                        </material.Button>
                      </div>

                      <div className="col-4 col-sm-12 col-md-6">
                        <material.TextField
                          {...register(
                            `landUserDetails[${index}].landUserName`,
                            {
                              required: true,
                            }
                          )}
                          label="Name of Land User"
                          variant="standard"
                          fullWidth
                          size="small"
                        />
                      </div>
                      <div className="col-4 col-sm-12 col-md-6">
                        <material.FormControl
                          variant="standard"
                          fullWidth
                          size="small"
                        >
                          <material.InputLabel id={`type-label-${index}`}>
                            Type of Land User
                          </material.InputLabel>
                          <material.Select
                            labelId={`type-label-${index}`}
                            {...register(
                              `landUserDetails[${index}].relationshipWithParcelOwner`,
                              { required: true }
                            )}
                          >
                            {landUserTypeList.map((ele, ind) => (
                              <material.MenuItem key={ind} value={ele.value}>
                                {ele.landUserType}
                              </material.MenuItem>
                            ))}
                          </material.Select>
                        </material.FormControl>
                      </div>
                      <div className="col-4 col-sm-12 col-md-6">
                        <material.FormControl
                          variant="standard"
                          fullWidth
                          size="small"
                        >
                          <material.InputLabel id={`duration-label-${index}`}>
                            Duration Type
                          </material.InputLabel>
                          <material.Select
                            labelId={`duration-label-${index}`}
                            {...register(
                              `landUserDetails[${index}].durationType`,
                              {
                                required: true,
                              }
                            )}
                          >
                            <material.MenuItem value="Year">
                              Year
                            </material.MenuItem>
                            <material.MenuItem value="Month">
                              Month
                            </material.MenuItem>
                            <material.MenuItem value="Day">
                              Day
                            </material.MenuItem>
                          </material.Select>
                        </material.FormControl>
                      </div>
                      <div className="col-4 col-sm-12 col-md-6">
                        <material.TextField
                          {...register(
                            `landUserDetails[${index}].durationValue`,
                            {
                              required: true,
                            }
                          )}
                          label="Duration Value"
                          variant="standard"
                          fullWidth
                          size="small"
                        />
                      </div>
                      <div className="col-4 col-sm-12 col-md-6">
                        <material.TextField
                          {...register(
                            `landUserDetails[${index}].nameOfLandUser`,
                            {
                              required: true,
                            }
                          )}
                          label="Name Of Land User"
                          variant="standard"
                          fullWidth
                          size="small"
                        />
                      </div>
                      <div className="col-4 col-sm-12 col-md-6 d-flex flex-row align-items-center">
                        <label
                          id="demo-row-radio-buttons-group-label"
                          className="me-2"
                        >
                          Is Land User present?
                        </label>
                        <Controller
                          name={`landUserDetails[${index}].isLanduserPresent`}
                          control={control}
                          defaultValue=""
                          rules={{ required: "Please select an option" }}
                          render={({ field }) => (
                            <material.RadioGroup
                              row
                              aria-label="selectedOption"
                              {...field}
                            >
                              <material.FormControlLabel
                                value="true"
                                control={<material.Radio />}
                                label="Yes"
                              />
                              <material.FormControlLabel
                                value="false"
                                control={<material.Radio />}
                                label="No"
                              />
                            </material.RadioGroup>
                          )}
                        />
                      </div>
                      {watch(`landUserDetails[${index}].isLanduserPresent`) ==
                        "false" && (
                        <>
                          <div className="col-4 col-sm-12 col-md-6">
                            <material.TextField
                              {...register(
                                `landUserDetails[${index}].nameOfRespondent`,
                                {
                                  required: true,
                                }
                              )}
                              label="Name of respondent"
                              variant="standard"
                              fullWidth
                              size="small"
                            />
                          </div>
                          <div className="col-4 col-sm-12 col-md-6">
                            <material.FormControl
                              size="small"
                              variant="standard"
                              className="w-100"
                            >
                              <material.InputLabel id="demo-select-small-label">
                                Relationship of Landuser With Respondent
                              </material.InputLabel>
                              <material.Select
                                name="relationshipWithLanduser"
                                {...register(
                                  `landUserDetails[${index}].relationshipWithLanduser`,
                                  {
                                    required: true,
                                  }
                                )}
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                // value={selectedRelation}
                                label="Select an option"
                                // onChange={onChangeRelationship}
                                required
                              >
                                {relationList.map((ele, i) => (
                                  <material.MenuItem key={i} value={ele.value}>
                                    {ele.relationName}
                                  </material.MenuItem>
                                ))}
                              </material.Select>
                            </material.FormControl>
                          </div>
                        </>
                      )}
                       <div className="col-12 mt-3">
                        <SocioEconomicDetailsForm
                          control={control}
                          watch={watch}
                          register={register}
                          setValue={setValue}
                          parentIndex={index}
                          parentName={"landUserDetails"}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </>
        )}

        {/***check if any user use the government land or not ***/}

        {isAnyUserUseThisLand === "true" ? (
          <>
            <div className="col-4 col-sm-12 col-md-6 mt-3">
              <material.TextField
                {...register("numberOfGovLandUser", {
                  required: true,
                })}
                label="Number Of Land Users"
                variant="standard"
                type="number"
                fullWidth
                size="small"
              />
            </div>
            <div className="col-12 mt-3">
              <span className="float-end">
                <material.Button
                  type="button"
                  variant="contained"
                  onClick={() => appendGovLandUser()}
                >
                  Add Landuser Details
                </material.Button>
              </span>
            </div>
            <div className="col-12 mt-2">
              {govLandUserDetailsField.map((field, index) => (
                <div key={field.id} className="row py-2 px-2 border border-3 border-primary rounded mt-2">
                  <div className="col-12">
                    <material.Button
                      type="button"
                      variant="contained"
                      // hidden={watch("numberOfLandUserInGovLand") == index - 1}
                      onClick={() => removeGovlandUser(index)}
                      color="error"
                    >
                      Remove
                    </material.Button>
                  </div>
                  <div className="col-4 col-sm-12 col-md-6">
                    <material.FormControl
                      size="small"
                      variant="standard"
                      className="w-100"
                    >
                      <material.InputLabel id="demo-select-small-label">
                        Type Of Land Users On Government Land
                      </material.InputLabel>
                      <material.Select
                        name="govLandUserType"
                        {...register(
                          `govLandUserDetails[${index}].govLandUserType`,
                          {
                            required: true,
                          }
                        )}
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        // value={selectedRelation}
                        label="Select an option"
                        // onChange={onChangeRelationship}
                        required
                      >
                        <material.MenuItem value="Patta-Holder">
                          Patta Holder
                        </material.MenuItem>
                        <material.MenuItem value="Non-Patta-Holder">
                          Non-Patta Holder
                        </material.MenuItem>
                      </material.Select>
                    </material.FormControl>
                  </div>
                  <div className="col-4 col-sm-12 col-md-6">
                    <material.FormControl
                      size="small"
                      variant="standard"
                      className="w-100"
                    >
                      <material.InputLabel id="demo-select-small-label">
                        Purpose of Use
                      </material.InputLabel>
                      <material.Select
                        name="govLandUsePerpose"
                        {...register(
                          `govLandUserDetails[${index}].govLandUsePerpose`,
                          {
                            required: true,
                          }
                        )}
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        // value={selectedRelation}
                        label="Select an option"
                        // onChange={onChangeRelationship}
                        required
                      >
                        <material.MenuItem value="Encroacher for agriculture/other use">
                          Encroacher for agriculture/other use
                        </material.MenuItem>
                        <material.MenuItem value="Encroacher for residential purposes">
                          Encroacher for residential purposes
                        </material.MenuItem>
                        <material.MenuItem value="Squatter for residential purposes">
                          Squatter for residential purposes
                        </material.MenuItem>
                        <material.MenuItem value="Others">
                          Others
                        </material.MenuItem>
                      </material.Select>
                    </material.FormControl>
                  </div>
                  <div className="col-4 col-sm-12 col-md-6">
                    <material.FormControl
                      variant="standard"
                      fullWidth
                      size="small"
                    >
                      <material.InputLabel id={`duration-label-${index}`}>
                        Duration Type
                      </material.InputLabel>
                      <material.Select
                        labelId={`duration-label-${index}`}
                        {...register(
                          `govLandUserDetails[${index}].durationType`,
                          {
                            required: true,
                          }
                        )}
                      >
                        <material.MenuItem value="Year">Year</material.MenuItem>
                        <material.MenuItem value="Month">
                          Month
                        </material.MenuItem>
                        <material.MenuItem value="Day">Day</material.MenuItem>
                      </material.Select>
                    </material.FormControl>
                  </div>
                  <div className="col-4 col-sm-12 col-md-6">
                    <material.TextField
                      {...register(
                        `govLandUserDetails[${index}].durationValue`,
                        {
                          required: true,
                        }
                      )}
                      label="Duration Value"
                      variant="standard"
                      fullWidth
                      size="small"
                    />
                  </div>
                  <div className="col-4 col-sm-12 col-md-6">
                    <material.TextField
                      {...register(
                        `govLandUserDetails[${index}].landUserName`,
                        {
                          required: true,
                        }
                      )}
                      label="Name of Land User"
                      variant="standard"
                      fullWidth
                      size="small"
                    />
                  </div>
                  <div className="col-4 col-sm-12 col-md-6 d-flex flex-row align-items-center">
                    <label
                      id="demo-row-radio-buttons-group-label"
                      className="me-2"
                    >
                      Is Land User present?
                    </label>
                    <Controller
                      name={`govLandUserDetails[${index}].isLanduserPresent`}
                      control={control}
                      defaultValue=""
                      rules={{ required: "Please select an option" }}
                      render={({ field }) => (
                        <material.RadioGroup
                          row
                          aria-label="selectedOption"
                          {...field}
                        >
                          <material.FormControlLabel
                            value="true"
                            control={<material.Radio />}
                            label="Yes"
                          />
                          <material.FormControlLabel
                            value="false"
                            control={<material.Radio />}
                            label="No"
                          />
                        </material.RadioGroup>
                      )}
                    />
                  </div>
                  {watch(`govLandUserDetails[${index}].isLanduserPresent`) ==
                    "false" && (
                    <>
                      <div className="col-4 col-sm-12 col-md-6">
                        <material.TextField
                          {...register(
                            `govLandUserDetails[${index}].nameOfRespondent`,
                            {
                              required: true,
                            }
                          )}
                          label="Name of respondent"
                          variant="standard"
                          fullWidth
                          size="small"
                        />
                      </div>
                      <div className="col-4 col-sm-12 col-md-6">
                        <material.FormControl
                          size="small"
                          variant="standard"
                          className="w-100"
                        >
                          <material.InputLabel id="demo-select-small-label">
                            Relationship of Landuser With Respondent
                          </material.InputLabel>
                          <material.Select
                            name="relationshipWithLanduser"
                            {...register(
                              `govLandUserDetails[${index}].relationshipWithLanduser`,
                              {
                                required: true,
                              }
                            )}
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            // value={selectedRelation}
                            label="Select an option"
                            // onChange={onChangeRelationship}
                            required
                          >
                            {relationList.map((ele, i) => (
                              <material.MenuItem key={i} value={ele.value}>
                                {ele.relationName}
                              </material.MenuItem>
                            ))}
                          </material.Select>
                        </material.FormControl>
                      </div>
                      
                    </>
                  )}
                  <div className="col-12 mt-3">
                        <SocioEconomicDetailsForm
                          control={control}
                          watch={watch}
                          register={register}
                          setValue={setValue}
                          parentIndex={index}
                          parentName={"govLandUserDetails"}
                        />
                      </div>
                </div>
              ))}
            </div>
          </>
        ) : null}
      </form>
    </div>
  );
}

export default ParcelOwnershipDetailsForm;
