import React, { useState } from "react";
import { material } from "../../../library/material";
import "./AirQualityTable.css";
import { useEffect } from "react";
import ComboChart from "../../charts/ComboChart";
import {
  generateAirTableData,
  generateDataForChart,
  getAverages,
} from "../../../utils/generateAirChartData";

function AirQualityTable({ airData }) {
  const [modyFiedData, setModyFiedData] = useState([]);
  const [chartData, setChartData] = useState([]);
  useEffect(() => {
    const transformedData = generateAirTableData(airData || []);
    console.log(airData);
    setModyFiedData(transformedData);
    let getAvgValuesOfPollutant = getAverages(transformedData);
    // console.log(getAvgValuesOfPollutant)
    let chartData = generateDataForChart(getAvgValuesOfPollutant);
    setChartData(chartData);
  }, [airData]);

  return (
    <>
      <div className="row">
        {chartData.map((ele, i) => (
          <div className="col-6 p-1">
            <material.Paper sx={{ p: 2 }}>
              <ComboChart
                key={i}
                data={ele[0]}
                options={ele[1]}
                chartType={"ComboChart"}
              />
            </material.Paper>
          </div>
        ))}
      </div>
      <material.TableContainer component={material.Paper} sx={{ mt: 1 }}>
        <material.Table aria-label="air quality table">
          <material.TableHead>
            <material.TableRow
              sx={{ border: 2, borderStyle: "solid", borderWidth: 2 }}
            >
              <material.TableCell
                rowSpan={2}
                sx={{ border: 2, borderStyle: "solid", borderWidth: 2 }}
              >
                Station Code
              </material.TableCell>
              <material.TableCell
                sx={{ border: 2, borderStyle: "solid", borderWidth: 2 }}
                rowSpan={2}
                align="center"
              >
                Locations
              </material.TableCell>
              <material.TableCell
                sx={{ border: 2, borderStyle: "solid", borderWidth: 2 }}
                rowSpan={2}
                align="center"
              >
                Geo-Coordinate
              </material.TableCell>
              <material.TableCell
                sx={{ border: 2, borderStyle: "solid", borderWidth: 2 }}
                colSpan={4}
                align="center"
              >
                PM<sub>10</sub> (µg/m³)
              </material.TableCell>
              <material.TableCell
                sx={{ border: 2, borderStyle: "solid", borderWidth: 2 }}
                colSpan={4}
                align="center"
              >
                PM<sub>2.5</sub> (µg/m³)
              </material.TableCell>
              <material.TableCell
                sx={{ border: 2, borderStyle: "solid", borderWidth: 2 }}
                colSpan={4}
                align="center"
              >
                SO<sub>2</sub> (µg/m³)
              </material.TableCell>
              <material.TableCell
                sx={{ border: 2, borderStyle: "solid", borderWidth: 2 }}
                colSpan={4}
                align="center"
              >
                NO<sub>2</sub> (µg/m³)
              </material.TableCell>
              <material.TableCell
                sx={{ border: 2, borderStyle: "solid", borderWidth: 2 }}
                colSpan={4}
                align="center"
              >
                CO (mg/m³)
              </material.TableCell>
              <material.TableCell
                sx={{ border: 2, borderStyle: "solid", borderWidth: 2 }}
                colSpan={4}
                align="center"
              >
                O<sub>3</sub> (µg/m³)
              </material.TableCell>
              <material.TableCell
                sx={{ border: 2, borderStyle: "solid", borderWidth: 2 }}
                colSpan={4}
                align="center"
              >
                C<sub>6</sub>H<sub>6</sub> (ng/m³)
              </material.TableCell>
              <material.TableCell colSpan={4} align="center">
                BaP (ng/m³)
              </material.TableCell>
              <material.TableCell
                sx={{ border: 2, borderStyle: "solid", borderWidth: 2 }}
                colSpan={4}
                align="center"
              >
                NH<sub>3</sub> (µg/m³)
              </material.TableCell>
              <material.TableCell colSpan={4} align="center">
                As (ng/m³)
              </material.TableCell>
              <material.TableCell
                sx={{ border: 2, borderStyle: "solid", borderWidth: 2 }}
                colSpan={4}
                align="center"
              >
                Pb (ng/m³)
              </material.TableCell>
              <material.TableCell
                sx={{ border: 2, borderStyle: "solid", borderWidth: 2 }}
                colSpan={4}
                align="center"
              >
                Ni (ng/m³)
              </material.TableCell>
              <material.TableCell
                rowSpan={2}
                sx={{ border: 2, borderStyle: "solid", borderWidth: 2 }}
              >
                Distance
              </material.TableCell>
            </material.TableRow>
            <material.TableRow className="border border-2">
              {/* Repeat the column headers for each pollutant */}
              {Array.from({ length: 12 }).flatMap((_, o) =>
                ["Min", "Avg", "Max", "98%"].map((header, i) => (
                  <material.TableCell
                    sx={{ border: 2, borderStyle: "solid", borderWidth: 2 }}
                    key={`${o}-${i}`}
                  >
                    {header}
                  </material.TableCell>
                ))
              )}
            </material.TableRow>
          </material.TableHead>
          <material.TableBody>
            {modyFiedData.map((row, index) => (
              <material.TableRow key={index}>
                <material.TableCell
                  sx={{ border: 2, borderStyle: "solid", borderWidth: 2 }}
                >
                  {row.code}
                </material.TableCell>
                <material.TableCell
                  sx={{ border: 2, borderStyle: "solid", borderWidth: 2 }}
                >
                  {row.location}
                </material.TableCell>
                <material.TableCell
                  sx={{ border: 2, borderStyle: "solid", borderWidth: 2 }}
                >
                  {row.coordinates}
                </material.TableCell>
                <material.TableCell
                  sx={{ border: 2, borderStyle: "solid", borderWidth: 2 }}
                >
                  {row.pm10.min}
                </material.TableCell>
                <material.TableCell
                  sx={{ border: 2, borderStyle: "solid", borderWidth: 2 }}
                >
                  {row.pm10.avg}
                </material.TableCell>
                <material.TableCell
                  sx={{ border: 2, borderStyle: "solid", borderWidth: 2 }}
                >
                  {row.pm10.max}
                </material.TableCell>
                <material.TableCell
                  sx={{ border: 2, borderStyle: "solid", borderWidth: 2 }}
                >
                  {row.pm10.percentile_98}
                </material.TableCell>
                <material.TableCell
                  sx={{ border: 2, borderStyle: "solid", borderWidth: 2 }}
                >
                  {row.pm25.min}
                </material.TableCell>
                <material.TableCell
                  sx={{ border: 2, borderStyle: "solid", borderWidth: 2 }}
                >
                  {row.pm25.avg}
                </material.TableCell>
                <material.TableCell
                  sx={{ border: 2, borderStyle: "solid", borderWidth: 2 }}
                >
                  {row.pm25.max}
                </material.TableCell>
                <material.TableCell
                  sx={{ border: 2, borderStyle: "solid", borderWidth: 2 }}
                >
                  {row.pm25.percentile_98}
                </material.TableCell>
                <material.TableCell
                  sx={{ border: 2, borderStyle: "solid", borderWidth: 2 }}
                >
                  {row.so2.min}
                </material.TableCell>
                <material.TableCell
                  sx={{ border: 2, borderStyle: "solid", borderWidth: 2 }}
                >
                  {row.so2.avg}
                </material.TableCell>
                <material.TableCell
                  sx={{ border: 2, borderStyle: "solid", borderWidth: 2 }}
                >
                  {row.so2.max}
                </material.TableCell>
                <material.TableCell
                  sx={{ border: 2, borderStyle: "solid", borderWidth: 2 }}
                >
                  {row.so2.percentile_98}
                </material.TableCell>
                <material.TableCell
                  sx={{ border: 2, borderStyle: "solid", borderWidth: 2 }}
                >
                  {row.no2.min}
                </material.TableCell>
                <material.TableCell
                  sx={{ border: 2, borderStyle: "solid", borderWidth: 2 }}
                >
                  {row.no2.avg}
                </material.TableCell>
                <material.TableCell
                  sx={{ border: 2, borderStyle: "solid", borderWidth: 2 }}
                >
                  {row.no2.max}
                </material.TableCell>
                <material.TableCell
                  sx={{ border: 2, borderStyle: "solid", borderWidth: 2 }}
                >
                  {row.no2.percentile_98}
                </material.TableCell>
                <material.TableCell
                  sx={{ border: 2, borderStyle: "solid", borderWidth: 2 }}
                >
                  {row.co.min}
                </material.TableCell>
                <material.TableCell
                  sx={{ border: 2, borderStyle: "solid", borderWidth: 2 }}
                >
                  {row.co.avg}
                </material.TableCell>
                <material.TableCell
                  sx={{ border: 2, borderStyle: "solid", borderWidth: 2 }}
                >
                  {row.co.max}
                </material.TableCell>
                <material.TableCell
                  sx={{ border: 2, borderStyle: "solid", borderWidth: 2 }}
                >
                  {row.co.percentile_98}
                </material.TableCell>
                <material.TableCell
                  sx={{ border: 2, borderStyle: "solid", borderWidth: 2 }}
                >
                  {row.o3.min}
                </material.TableCell>
                <material.TableCell
                  sx={{ border: 2, borderStyle: "solid", borderWidth: 2 }}
                >
                  {row.o3.avg}
                </material.TableCell>
                <material.TableCell
                  sx={{ border: 2, borderStyle: "solid", borderWidth: 2 }}
                >
                  {row.o3.max}
                </material.TableCell>
                <material.TableCell
                  sx={{ border: 2, borderStyle: "solid", borderWidth: 2 }}
                >
                  {row.o3.percentile_98}
                </material.TableCell>
                <material.TableCell
                  sx={{ border: 2, borderStyle: "solid", borderWidth: 2 }}
                >
                  {row.c6h6.min}
                </material.TableCell>
                <material.TableCell
                  sx={{ border: 2, borderStyle: "solid", borderWidth: 2 }}
                >
                  {row.c6h6.avg}
                </material.TableCell>
                <material.TableCell
                  sx={{ border: 2, borderStyle: "solid", borderWidth: 2 }}
                >
                  {row.c6h6.max}
                </material.TableCell>
                <material.TableCell
                  sx={{ border: 2, borderStyle: "solid", borderWidth: 2 }}
                >
                  {row.c6h6.percentile_98}
                </material.TableCell>
                <material.TableCell
                  sx={{ border: 2, borderStyle: "solid", borderWidth: 2 }}
                >
                  {row.bap.min}
                </material.TableCell>
                <material.TableCell
                  sx={{ border: 2, borderStyle: "solid", borderWidth: 2 }}
                >
                  {row.bap.avg}
                </material.TableCell>
                <material.TableCell
                  sx={{ border: 2, borderStyle: "solid", borderWidth: 2 }}
                >
                  {row.bap.max}
                </material.TableCell>
                <material.TableCell
                  sx={{ border: 2, borderStyle: "solid", borderWidth: 2 }}
                >
                  {row.bap.percentile_98}
                </material.TableCell>
                <material.TableCell
                  sx={{ border: 2, borderStyle: "solid", borderWidth: 2 }}
                >
                  {row.nh3.min}
                </material.TableCell>
                <material.TableCell
                  sx={{ border: 2, borderStyle: "solid", borderWidth: 2 }}
                >
                  {row.nh3.avg}
                </material.TableCell>
                <material.TableCell
                  sx={{ border: 2, borderStyle: "solid", borderWidth: 2 }}
                >
                  {row.nh3.max}
                </material.TableCell>
                <material.TableCell
                  sx={{ border: 2, borderStyle: "solid", borderWidth: 2 }}
                >
                  {row.nh3.percentile_98}
                </material.TableCell>
                <material.TableCell
                  sx={{ border: 2, borderStyle: "solid", borderWidth: 2 }}
                >
                  {row.as.min}
                </material.TableCell>
                <material.TableCell
                  sx={{ border: 2, borderStyle: "solid", borderWidth: 2 }}
                >
                  {row.as.avg}
                </material.TableCell>
                <material.TableCell
                  sx={{ border: 2, borderStyle: "solid", borderWidth: 2 }}
                >
                  {row.as.max}
                </material.TableCell>
                <material.TableCell
                  sx={{ border: 2, borderStyle: "solid", borderWidth: 2 }}
                >
                  {row.as.percentile_98}
                </material.TableCell>
                <material.TableCell
                  sx={{ border: 2, borderStyle: "solid", borderWidth: 2 }}
                >
                  {row.pb.min}
                </material.TableCell>
                <material.TableCell
                  sx={{ border: 2, borderStyle: "solid", borderWidth: 2 }}
                >
                  {row.pb.avg}
                </material.TableCell>
                <material.TableCell
                  sx={{ border: 2, borderStyle: "solid", borderWidth: 2 }}
                >
                  {row.pb.max}
                </material.TableCell>
                <material.TableCell
                  sx={{ border: 2, borderStyle: "solid", borderWidth: 2 }}
                >
                  {row.pb.percentile_98}
                </material.TableCell>
                <material.TableCell
                  sx={{ border: 2, borderStyle: "solid", borderWidth: 2 }}
                >
                  {row.ni.min}
                </material.TableCell>
                <material.TableCell
                  sx={{ border: 2, borderStyle: "solid", borderWidth: 2 }}
                >
                  {row.ni.avg}
                </material.TableCell>
                <material.TableCell
                  sx={{ border: 2, borderStyle: "solid", borderWidth: 2 }}
                >
                  {row.ni.max}
                </material.TableCell>
                <material.TableCell
                  sx={{ border: 2, borderStyle: "solid", borderWidth: 2 }}
                >
                  {row.ni.percentile_98}
                </material.TableCell>
                <material.TableCell
                  sx={{ border: 2, borderStyle: "solid", borderWidth: 2 }}
                >
                  {row.distance}
                </material.TableCell>
              </material.TableRow>
            ))}
          </material.TableBody>
        </material.Table>
      </material.TableContainer>
    </>
  );
}

export default AirQualityTable;
