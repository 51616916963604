// theme.js
import { createTheme } from '@mui/material/styles';

// const theme = createTheme({
//   typography: {
//     fontFamily: 'Poppins, sans-serif',
//   },
// });

const sailorBlue = createTheme({
  palette: {
    primary: {
      main: '#00203FFF', // Primary color
    },
    secondary: {
      main: '#ADEFD1FF', // Secondary color
    },
    error: {
      main: '#d50000', // Error color
    },
    success: {
      main: '#1b5e20', // Error color
    },
    background: {
      default: '#f5f5f5',
      paper: '#ADEFD1FF', // Background color
    },
    text: {
      primary: '#00203FFF', // Text color
    },
    divider: '#00203FFF',
    border: {
      main: '#00203FFF', // Border color
    },
  },
  typography: {
    fontFamily: 'Poppins, sans-serif',
    primary: '#ADEFD1FF',
  },
    components: {
    // MuiPaper: {
    //   styleOverrides: {
    //     root: {
    //       color: '#D7C49EFF', // Example: Dark gray text color for paper
    //     },
    //   },
    // },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: '#00203FFF', // Example: Red color for radio buttons
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#00203FFF', // Example: Green color for checkboxes
        },
      },
    },
    // MuiPopover: {
    //   styleOverrides: {
    //     root: {
    //       color: '#ffff00', // Example: Yellow
    //     },
    //   },
    // },
    MuiMenu: {
      styleOverrides: {
        paper: {
          backgroundColor: '#ADEFD1FF', // Customize menu background color
          color:'#EEA47FFF'
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: '#00203FFF', // Example: Black
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: '#00203FFF', // Default text color for typography
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          borderBottom: '2px solid yellow !important', // Dialog title border
          backgroundColor:'#00203FFF',
          color:'#ADEFD1FF'
        },
      },
    },
  },
});

const royalBlueAndPeach = createTheme({
  palette: {
    primary: {
      main: '#EEA47FFF', // Primary color
    },
    secondary: {
      main: '#00539CFF', // Secondary color
    },
    error: {
      main: '#f44336', // Error color
    },
    background: {
      default: '#f5f5f5',
      paper: '#00539CFF', // Background color
    },
    text: {
      primary: '#EEA47FFF',
      secondary: '#EEA47FFF'// Text color
    },
    paper: {
      text: '#333333', // Example: Dark Gray
    },
    divider: '#00539CFF',
    border: {
      main: '#00539CFF', // Border color
    },
  },
  typography: {
    fontFamily: 'Poppins, sans-serif',
    primary: '#EEA47FFF',
  },
  components: {
    // MuiPaper: {
    //   styleOverrides: {
    //     root: {
    //       color: '#D7C49EFF', // Example: Dark gray text color for paper
    //     },
    //   },
    // },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: '#EEA47FFF', // Example: Red color for radio buttons
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#EEA47FFF', // Example: Green color for checkboxes
        },
      },
    },
    // MuiPopover: {
    //   styleOverrides: {
    //     root: {
    //       color: '#ffff00', // Example: Yellow
    //     },
    //   },
    // },
    MuiMenu: {
      styleOverrides: {
        paper: {
          backgroundColor: '#00539CFF', // Customize menu background color
          color:'#EEA47FFF'
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: '#EEA47FFF', // Example: Black
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: '#EEA47FFF', // Default text color for typography
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          borderBottom: '2px solid yellow !important', // Dialog title border
          backgroundColor:'#01579b',
          color:'#EEA47FFF'
        },
      },
    },
  },
});

const whiteTheme=createTheme({
  typography: {
    fontFamily: 'Poppins, sans-serif',
    // primary: '#EEA47FFF',
  },
})

const soybeanEclipse = createTheme({
  palette: {
    primary: {
      main: '#D7C49EFF', // Primary color
    },
    secondary: {
      main: '#343148FF', // Secondary color
    },
    error: {
      main: '#b71c1c', // Error color
    },
    background: {
      default: '#D7C49EFF',
      paper: '#343148FF', // Background color
    },
    text: {
      primary: '#D7C49EFF',
      secondary: '#343148FF'// Text color
    },
    paper: {
      text: '#343148FF', // Example: Dark Gray
    },
    divider: '#D7C49EFF',
    // border: {
    //   main: '#D7C49EFF', // Border color
    // },
  },
  typography: {
    fontFamily: 'Poppins, sans-serif',
    primary: '#343148FF',
  },
  components: {
    // MuiPaper: {
    //   styleOverrides: {
    //     root: {
    //       color: '#D7C49EFF', // Example: Dark gray text color for paper
    //     },
    //   },
    // },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: '#D7C49EFF', // Example: Red color for radio buttons
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#D7C49EFF', // Example: Green color for checkboxes
        },
      },
    },
    // MuiPopover: {
    //   styleOverrides: {
    //     root: {
    //       color: '#ffff00', // Example: Yellow
    //     },
    //   },
    // },
    MuiMenu: {
      styleOverrides: {
        paper: {
          backgroundColor: '#343148FF', // Customize menu background color
          color:'#D7C49EFF'
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: '#D7C49EFF', // Example: Black
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: '#D7C49EFF', // Default text color for typography
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          borderBottom: '2px solid yellow !important', // Dialog title border
          backgroundColor:'#e91e63',
          color:'#343148FF'
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          borderTop: '2px solid #ffa500',
          borderBottom: '2px solid #ffa500',
          backgroundColor:'#343148FF',
           // Example: Orange border for table cells
        },
      },
    }
  },
});

const themeReturnFunction = (selectedTheme) => {
  if (selectedTheme === 'Sailor_Blue') {
    return sailorBlue;
  } else if (selectedTheme === 'Royal_Blue') {
    return royalBlueAndPeach;
  }else if (selectedTheme === 'White') {
    return whiteTheme;
  }else if (selectedTheme === 'Soybean_Eclipse') {
    return soybeanEclipse;
  }
};

export default themeReturnFunction;
