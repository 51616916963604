import React, { useEffect } from "react";
import { material } from "../../library/material";
import ComboChart from "../charts/ComboChart";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";

const StyledTableCell = styled(material.TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#0d3569",
    color: theme.palette.common.white,
    fontWeight: "bold",
    fontSize: 12,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 10,
  },
}));

function Census({ dataAfterQuery }) {
  useEffect(() => {
    //console.log(data);
  }, []);

  return (
    <div className="row">
      {dataAfterQuery.charts?.map((ele, ci) => (
        <div key={ci} className="col-6 p-1">
          <material.Paper sx={{ p: 2 }}>
            <ComboChart
              data={ele.chartData}
              options={ele.chartOptions}
              chartType={ele.chartType}
            />
          </material.Paper>
        </div>
      ))}
      {dataAfterQuery?.tableData?.map((table, i) => (
        <div className="col-12 p-1 mt-2">
          <material.Paper sx={{ p: 2 }}>
            <material.TableContainer sx={{ maxHeight: 350 }}>
              <material.Table stickyHeader aria-label="sticky table">
                <material.TableHead
                  sx={{
                    fontWeight: "bold",
                    color: "white",
                    textTransform: "capitalize",
                    backgroundColor: "blue",
                  }}
                >
                  <material.TableRow
                    sx={{
                      textTransform: "capitalize",
                      height: 20,
                      border: 2, borderStyle: "solid", borderWidth: 1,borderColor:'black' 
                    }}
                  >
                    <StyledTableCell
                        size="small"
                        align="center"
                        colSpan={table.columnName.length}
                        sx={{border: 2, borderStyle: "solid", borderWidth: 1,borderColor:'black' }}
                      >
                        {table.tableHeader}
                      </StyledTableCell>
                  </material.TableRow>
                  <material.TableRow
                    sx={{
                      textTransform: "capitalize",
                      height: 20,
                    }}
                  >
                    {table.columnName.map((column) => (
                      <StyledTableCell
                        size="small"
                        key={column.header}
                        align="center"
                        //sx={{fontWeight:'bold',color:'white'}}
                        sx={{border: 2, borderStyle: "solid", borderWidth: 1,borderColor:'black' }}
                      >
                        {column.header}
                      </StyledTableCell>
                    ))}
                  </material.TableRow>
                </material.TableHead>
                <material.TableBody>
                  {table?.data?.map((rowData, ind) => {
                    return (
                      <material.TableRow key={ind}>
                        {table.columnName.map((column, colInd) => {
                          return (
                            <material.TableCell
                              size="small"
                              key={colInd}
                              align="center"
                              sx={{border: 2, borderStyle: "solid", borderWidth: 1,borderColor:'black' }}
                            >
                              {rowData[column.dataKey]}
                            </material.TableCell>
                          );
                        })}
                      </material.TableRow>
                    );
                  })}
                </material.TableBody>
              </material.Table>
            </material.TableContainer>
          </material.Paper>
        </div>
      ))}
    </div>
  );
}

export default Census;
