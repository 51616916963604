import React, { useState } from 'react'
import { material } from '../../../library/material'
import './SigninDialog.css'
import signInImage from '../../../assets/map-image/signinImage.png'

function SigninDialog({ open, close }) {

    const closeDialog = () => {
        close()
    }

    const [show, setShow] = useState(false)
    const getIconForPasswordHideShow = () => {
        if (show) {
            return <material.VisibilityOffIcon sx={{cursor:'pointer'}} onClick={() => setShow(!show)} />
        } else {
            return <material.VisibilityIcon sx={{cursor:'pointer'}} onClick={() => setShow(!show)} />
        }
    }

    return (
        <material.Dialog hideBackdrop open={open} sx={{ p: 4, mt: 5 }} fullWidth maxWidth="sm">

            <material.IconButton
                color='error'
                style={{ zIndex: 4, right: 4, top: 4, boxShadow: 'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px', }} className='fw-bold p-3 position-absolute' aria-label="delete" size="large"
                onClick={closeDialog}
            >
                <material.CloseIcon fontSize="inherit" />
            </material.IconButton>

            <material.DialogContent className=' p-0' sx={{ height: 600 }}>
                <div className='d-flex flex-column w-100 h-100 position-relative'>
                    <div className='signDiv1'>

                    </div>
                    <div className='formContent'>
                        <div className="logo">
                            {/* Your logo content here */}
                            <img src={signInImage} alt="Logo" />
                        </div>
                        <div className='fw-bold mt-2 fs-5 d-flex justify-content-center'>
                            <span>Sign In</span>
                        </div>
                        <material.TextField
                            id="outlined-basic"
                            label="Username"
                            fullWidth variant="outlined"
                            className='mt-3'
                            type='text'
                            InputProps={{
                                endAdornment: (
                                    <material.AccountCircleIcon /> // Adjust the color as needed
                                ),
                            }}
                        />
                        <material.TextField
                            id="outlined-basic"
                            label="Password"
                            fullWidth
                            variant="outlined"
                            type={show?'text':'password'}
                            className='mt-3'
                            InputProps={{
                                endAdornment: (
                                    getIconForPasswordHideShow() // Adjust the color as needed
                                ),
                            }}
                        />
                        <material.Button size="large" variant="contained" fullWidth color='secondary' className='mt-3'>
                            Sign In
                        </material.Button>
                    </div>
                </div>
            </material.DialogContent>
        </material.Dialog>
    )
}

export default SigninDialog