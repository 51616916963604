export const getColour = (selectedTheme) => {
    if (selectedTheme === 'Sailor_Blue') {
        let obj = { headerColor: '#00203FFF', backgroundColor: '#ADEFD1FF', textColor: '#ADEFD1FF' }
        return obj
    } else if (selectedTheme === 'Royal_Blue') {
        let obj = { headerColor: '#EEA47FFF', backgroundColor: '#00539CFF', textColor: '#00539CFF' }
        return obj;
    } else if (selectedTheme === 'White') {
        return { headerColor: '#2196f3', backgroundColor: 'white', textColor: 'white' };
    } else if (selectedTheme === 'Soybean_Eclipse') {
        let obj = { headerColor: '#D7C49EFF', backgroundColor: '#343148FF', textColor: '#343148FF' }
        return obj
    }
}