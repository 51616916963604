let motoringPointName = [
  { pollutantName: "pm10", limitValue: 100.0 },
  { pollutantName: "pm25", limitValue: 60.0 },
  { pollutantName: "so2", limitValue: 80.0 },
  { pollutantName: "no2", limitValue: 80.0 },
  { pollutantName: "co", limitValue: 2.0 },
  { pollutantName: "o3", limitValue: 100.0 },
  { pollutantName: "nh3", limitValue: 400.0 },
];
export const getAverages = (data) => {
  return data.map((location) => {
    const avgValues = {};
    avgValues.location = location.code;

    for (const pollutant in location) {
      if (location[pollutant]?.avg !== undefined) {
        avgValues[pollutant] = location[pollutant].avg;
      }
    }

    return avgValues;
  });
};

const generateDataStructure = (
  getAvgValuesOfPollutant,
  pollutantName,
  limitValue
) => {
  const chartData = [
    [
      "Location",
      pollutantName === "pm25"
        ? "pm2.5".toUpperCase()
        : pollutantName.toUpperCase(),
      "Limit Value",
    ],
  ];
  const options = {
    title: `Concentration of ${
      pollutantName === "pm25"
        ? "pm2.5".toUpperCase()
        : pollutantName.toUpperCase()
    } in Ambient Air Quality Location`,
    vAxis: { title: "Concentration(µg/m³)" },
    hAxis: { title: "Monitoring Location" },
    seriesType: "bars",
    series: { 1: { type: "line" } },
    colors: ["#1b9e77", "#d95f02"],
    legend: { position: "top" },
  };
  getAvgValuesOfPollutant.forEach((location, index) => {
    const avgValue = location[pollutantName];
    const maxValue = limitValue; // Constant max value for the line chart
    chartData.push([location.location, avgValue, maxValue]);
  });

  return [chartData, options];
};

export const generateDataForChart = (
  getAvgValuesOfPollutant
) => {
  let chartData = [];
  motoringPointName.forEach((ele) => {
    let structureedData = generateDataStructure(
      getAvgValuesOfPollutant,
      ele.pollutantName,
      ele.limitValue
    );
    chartData.push(structureedData);
  });
  return chartData;
};

export const generateAirTableData = (airData) => {
 return airData.map((item) => {
  console.log(item)
    return {
      code:item.strg_name,
      location: item.name,
      coordinates: "", // You need to provide the coordinates for each location
      pm10: {
        min: item["pm10 (min)"] || 0,
        avg: item["pm10 (avg.)"] || 0,
        max: item["pm10 (max)"] || 0,
        percentile_98: item["pm10 (98p)"] || 0,
      },
      pm25: {
        min: item["pm2.5 (min)"] || 0,
        avg: item["pm2.5 (avg.)"] || 0,
        max: item["pm2.5 (max)"] || 0,
        percentile_98: item["pm2.5 (98p)"] || 0,
      },
      so2: {
        min: item["so2 (min)"] || 0,
        avg: item["so2 (avg.)"] || 0,
        max: item["so2 (max)"] || 0,
        percentile_98: item["so2 (98p)"] || 0,
      },
      no2: {
        min: item["no2 (min)"] || 0,
        avg: item["no2 (avg.)"] || 0,
        max: item["no2 (max)"] || 0,
        percentile_98: item["no2 (98p)"] || 0,
      },
      co: {
        min: item["co (min)"] || 0,
        avg: item["co (avg.)"] || 0,
        max: item["co (max)"] || 0,
        percentile_98: item["co (98p)"] || 0,
      },
      o3: {
        min: item["o3 (min)"] || 0,
        avg: item["o3 (avg.)"] || 0,
        max: item["o3 (max)"] || 0,
        percentile_98: item["o3 (98p)"] || 0,
      },
      c6h6: {
        min: item["c6h6 (min)"] || 0,
        avg: item["c6h6 (avg.)"] || 0,
        max: item["c6h6 (max)"] || 0,
        percentile_98: item["c6h6 (98p)"] || 0,
      },
      bap: {
        min: item["bap (min)"] || 0,
        avg: item["bap (avg.)"] || 0,
        max: item["bap (max)"] || 0,
        percentile_98: item["bap (98p)"] || 0,
      },
      nh3: {
        min: item["nh3 (min)"] || 0,
        avg: item["nh3 (avg.)"] || 0,
        max: item["nh3 (max)"] || 0,
        percentile_98: item["nh3 (98p)"] || 0,
      },
      as: {
        min: item["as (min)"] || 0,
        avg: item["as (avg.)"] || 0,
        max: item["as (max)"] || 0,
        percentile_98: item["as (98p)"] || 0,
      },
      pb: {
        min: item["pb (min)"] || 0,
        avg: item["pb (avg.)"] || 0,
        max: item["pb (max)"] || 0,
        percentile_98: item["pb (98p)"] || 0,
      },
      ni: {
        min: item["ni (min)"] || 0,
        avg: item["ni (avg.)"] || 0,
        max: item["ni (max)"] || 0,
        percentile_98: item["ni (98p)"] || 0,
      },
      distance:item.distance
    };
  });
};

export const generateReportChartDataStructure=(rawData)=>{
  return motoringPointName.map((pram, paramInd) => {
    let option= {
      responsive: true,
      plugins: {
        legend: {
          position: 'bottom',
        },
        title: {
          display: true,
          text: `Concentration of ${
            pram.pollutantName === "pm25"
              ? "pm2.5".toUpperCase()
              : pram.pollutantName.toUpperCase()
          } in Ambient Air Quality Location`,
        },
      },
      scales: {
        x: {
          title: {
            display: true,
            text: 'Monitoring Location',
          },
        },
        y: {
          title: {
            display: true,
            text: "Concentration(µg/m³)",
          },
        },
      },
    };
    const data = {
      labels: rawData.map((ele, ind) => {
        console.log(ele)
        return ele['location']
      }),
      datasets: [
        {
          type:'bar',
          label:pram.pollutantName === "pm25"
        ? "pm2.5".toUpperCase()
        : pram.pollutantName.toUpperCase(),
          backgroundColor: ['rgba(255, 99, 132, 4)'],
          borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)'],
          borderWidth: 1,
          hoverBackgroundColor: ['rgba(255, 99, 132, 0.4)'],
          hoverBorderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)'],
          data: rawData.map((ele, ind) => {
            return Number(ele[pram.pollutantName])
          })
        },
        {
          type: 'line',
          label:`Max Limit of ${pram.pollutantName === "pm25"
          ? "pm2.5".toUpperCase()
          : pram.pollutantName.toUpperCase()}`,
          fill: false,
          lineTension: 0.5,
          backgroundColor: 'rgba(75,192,192,1)',
          borderColor: 'rgba(0,0,0,1)',
          borderWidth: 2,
          data: rawData.map((ele, ind) => {
            return Number(pram.limitValue)
          }),
        },
      ]
    }
    return {option,data}
  })
}