import { React, useEffect, useState } from 'react';
import { material } from '../../../library/material';
import { useForm } from 'react-hook-form';

function AssetDetailsAddDialog({ openAddDialog, close }) {

    const [openInfo, setOpenInfo] = useState({ ...openAddDialog });
    const {
        register,
        getValues,
        handleSubmit, reset,
        formState: { errors, isValid }
      } = useForm();

      const saveAssetDetails = (formData) => {
        console.log(formData)
        close()
      }
    
      const closeDialog = () => {
        close();
      }

    return (
        <div>
            <material.Dialog hideBackdrop open={openInfo.open} sx={{ p: 4, mt: 12 }}>
        <material.DialogTitle className='fw-bold'>
          Add Asset Details
        </material.DialogTitle>
        <material.DialogContent>
          <div className="row">
            <div className="col-12">
              <material.TextField
                name='area'
                {...register("area", { required: true })}
                label="Enter a area"
                id="outlined-size-small"
                size="small"
                type="number"
                fullWidth
                variant="standard"
                className='mt-3 w-100'
                required
              />
            </div>
            <div className="col-12">
              <material.TextField
                name='lengthValue'
                {...register("landType", { required: true })}
                label="Enter a land type"
                id="outlined-size-small"
                size="small"
                type="text"
                fullWidth
                variant="standard"
                className='mt-3 w-100'
                required
              />
            </div>
            <div className="col-12">
              <material.TextField
                name='lengthValue'
                {...register("numbersOfPeople", { required: true })}
                label="Numbers Of People"
                id="outlined-size-small"
                size="small"
                type="text"
                fullWidth
                variant="standard"
                className='mt-3 w-100'
                required
              />
            </div>
            <div className="col-12">
              <material.TextField
                name='lengthValue'
                {...register("govEmployeeNumber", { required: true })}
                label="Enter a value"
                id="outlined-size-small"
                size="small"
                type="text"
                fullWidth
                variant="standard"
                className='mt-3 w-100'
                required
              />
            </div>
            <div className="col-12">
              <material.TextField
                name='lengthValue'
                {...register("location", { required: true })}
                label="Location"
                id="outlined-size-small"
                size="small"
                type="text"
                fullWidth
                variant="standard"
                className='mt-3 w-100'
                required
              />
            </div>
          </div>

        </material.DialogContent>
        <material.DialogActions>
          <material.Button variant="contained" color="error" startIcon={<material.CloseIcon />} className='me-2' onClick={closeDialog}>Close</material.Button>
          <material.Button variant="contained" color="primary" startIcon={<material.SaveIcon />} onClick={handleSubmit(saveAssetDetails)} className='me-2'>Save</material.Button>
        </material.DialogActions>
      </material.Dialog>
        </div>
    )
}

export default AssetDetailsAddDialog