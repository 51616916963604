import { React, useEffect, useState } from 'react';
import { material } from '../../../library/material';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { themeNameChangingAction } from '../../../store/slice/ThemeChangeSlice';

function ThemeChangeDialog({ openThemeDialog }) {
    const dispatch = useDispatch();
    const SelectedThemeName = useSelector((state) => state.ThemeChangeSlice.themeName);
    const [themeName, setThemeName] = useState(SelectedThemeName ? SelectedThemeName : "");
    const [openDialog, setOpenDialog] = useState({ ...openThemeDialog });
    const themeList = [
        { name: 'Sailor Blue', value: 'Sailor_Blue' },
        { name: 'Royal Blue', value: 'Royal_Blue' },
        { name: 'White', value: 'White' },
        { name: 'Soybean and Eclipse', value: 'Soybean_Eclipse' }
    ]

    useEffect(() => {
        setOpenDialog(openThemeDialog);
    }, [openThemeDialog])

    const handleChange = (event) => {
        setThemeName(event.target.value);
        dispatch(themeNameChangingAction(event.target.value));
        closeDialog();
    };

    const closeDialog = () => {
        setOpenDialog({ open: false })
    }

    return (
        <material.Dialog hideBackdrop open={openDialog?.open} fullWidth maxWidth="xs" sx={{ p: 4, mt: 12 }}>
            <material.DialogTitle className='fw-bold d-flex justify-content-between '>
                <span>Change Theme</span>
                <material.IconButton aria-label="delete" color='error' size="small" onClick={closeDialog}>
                    <material.CancelIcon fontSize="small" />
                </material.IconButton>

            </material.DialogTitle>
            <material.DialogContent>
                <material.Box sx={{ minWidth: 120, mt: 2 }}>
                    <material.FormControl fullWidth>
                        <material.InputLabel id="demo-simple-select-label">Selete Theme</material.InputLabel>
                        <material.Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={themeName}
                            label="Select one theme"
                            onChange={handleChange}
                        >
                            {themeList.map((theme, ind) =>
                                <material.MenuItem value={theme.value} key={ind}>{theme.name}</material.MenuItem>
                            )}
                        </material.Select>
                    </material.FormControl>
                </material.Box>
            </material.DialogContent>
        </material.Dialog>
    )
}

export default ThemeChangeDialog