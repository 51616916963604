import TileWMS from "ol/source/TileWMS.js";
import TileLayer from "ol/layer/Tile";
import { Group as LayerGroup } from "ol/layer.js";
import WMTS from "ol/source/WMTS";
import WMTSTileGrid from "ol/tilegrid/WMTS";
import { get as getProjection } from "ol/proj";
//import { getTopLeft } from "ol/extent";
import { rooApiUrl } from "../environment/Environment";

const projection = getProjection("EPSG:4326");
const tileGrid = new WMTSTileGrid({
  origin: [-180, 90],
  resolutions: [
    0.703125, 0.3515625, 0.17578125, 0.087890625, 0.0439453125, 0.02197265625,
    0.010986328125, 0.0054931640625, 0.00274658203125, 0.001373291015625,
    0.0006866455078125,
  ],
  matrixIds: [
    "EPSG:4326:0",
    "EPSG:4326:1",
    "EPSG:4326:2",
    "EPSG:4326:3",
    "EPSG:4326:4",
    "EPSG:4326:5",
    "EPSG:4326:6",
    "EPSG:4326:7",
    "EPSG:4326:8",
    "EPSG:4326:9",
    "EPSG:4326:10",
  ],
});

// Define the WMTS source
const wmtsSource = new WMTS({
  url: "https://bhuvan-vec2.nrsc.gov.in/bhuvan/gwc/service/wmts",
  layer: "lulc:WB_LULC50K_1516",
  matrixSet: "EPSG:4326",
  format: "image/png",
  projection: projection,
  tileGrid: tileGrid,
  // style: 'default',
  crossOrigin: "Anonymous",
});
let bhubanLanduse = new TileLayer({
  source: wmtsSource,
});

let bhubanLanduseDataLayer = new TileLayer({
  visible: false,
  source: new TileWMS({
    url: "https://bhuvan-vec2.nrsc.gov.in/bhuvan/wms",
    params: {
      TILED: true,
      LAYERS: "lulc:WB_LULC50K_1516",
    },
    serverType: "geoserver",
    crossOrigin: "Anonymous",
  }),
});

let haldiaMouza = new TileLayer({
  visible: true,
  source: new TileWMS({
    url: `${rooApiUrl}/wms`,
    params: {
      TILED: true,
      LAYERS: "haldia:mouza",
    },
    serverType: "geoserver",
    crossOrigin: "Anonymous",
  }),
  zIndex: 1,
});

let airQuality = new TileLayer({
  visible: true,
  source: new TileWMS({
    url: `${rooApiUrl}/wms`,
    params: {
      TILED: true,
      LAYERS: "	haldia:aqdata",
    },
    serverType: "geoserver",
    crossOrigin: "Anonymous",
  }),
  zIndex: 1,
});

let noiseLayer = new TileLayer({
  visible: true,
  source: new TileWMS({
    url: `${rooApiUrl}/wms`,
    params: {
      TILED: true,
      LAYERS: "haldia:noise data",
    },
    serverType: "geoserver",
    crossOrigin: "Anonymous",
  }),
  zIndex: 1,
});

let surfaceWater = new TileLayer({
  visible: true,
  source: new TileWMS({
    url: `${rooApiUrl}/wms`,
    params: {
      TILED: true,
      LAYERS: "haldia:sw_latin",
    },
    serverType: "geoserver",
    crossOrigin: "Anonymous",
  }),
  zIndex: 1,
});

let haldiaRoad = new TileLayer({
  visible: true,
  source: new TileWMS({
    url: `${rooApiUrl}/wms`,
    params: {
      TILED: true,
      LAYERS: "haldia:road",
    },
    serverType: "geoserver",
    crossOrigin: "Anonymous",
  }),
  zIndex: 1,
});

let haldiaIndustry = new TileLayer({
  visible: true,
  source: new TileWMS({
    url: `${rooApiUrl}/wms`,
    params: {
      TILED: true,
      LAYERS: "haldia:industry",
    },
    serverType: "geoserver",
    crossOrigin: "Anonymous",
  }),
  zIndex: 1,
});
let haldiaStorage = new TileLayer({
  visible: true,
  source: new TileWMS({
    url: `${rooApiUrl}/wms`,
    params: {
      TILED: true,
      LAYERS: "haldia:storage",
    },
    serverType: "geoserver",
    crossOrigin: "Anonymous",
  }),
  zIndex: 2,
});

let layer1503HaKhasra = new TileLayer({
  visible: true,
  source: new TileWMS({
    url: `${rooApiUrl}/wms`,
    params: {
      TILED: true,
      LAYERS: "haldia:layer_1503_ha_khasra-polygon",
    },
    serverType: "geoserver",
    crossOrigin: "Anonymous",
  }),
});

let indiaState = new TileLayer({
  visible: true,
  source: new TileWMS({
    url: `${rooApiUrl}/wms`,
    params: {
      TILED: true,
      LAYERS: "haldia:india_state_boundary",
    },
    serverType: "geoserver",
    crossOrigin: "Anonymous",
  }),
});

let indiaVillageWestbengal = new TileLayer({
  visible: true,
  source: new TileWMS({
    //projection: 'EPSG:32644', // here is the source projection
    url: `${rooApiUrl}/wms`,
    params: {
      TILED: true,
      LAYERS: "haldia:wb_village",
    },
    serverType: "geoserver",
    crossOrigin: "Anonymous",
  }),
});

let indiaDistrictCensus = new TileLayer({
  visible: true,
  source: new TileWMS({
    url: `${rooApiUrl}/wms`,
    params: {
      TILED: true,
      LAYERS: "haldia:india_district_census",
    },
    serverType: "geoserver",
    crossOrigin: "Anonymous",
  }),
  zIndex: 1,
});

let indiaHydrolicConductivity = new TileLayer({
  visible: true,
  source: new TileWMS({
    url: `${rooApiUrl}/wms`,
    params: {
      TILED: true,
      LAYERS: "haldia:kindia_pcs",
    },
    serverType: "geoserver",
    crossOrigin: "Anonymous",
  }),
  zIndex: 1,
});
let indiaTotalAvailableWater = new TileLayer({
  visible: true,
  source: new TileWMS({
    url: `${rooApiUrl}/wms`,
    params: {
      TILED: true,
      LAYERS: "haldia:indiataw",
    },
    serverType: "geoserver",
    crossOrigin: "Anonymous",
  }),
  zIndex: 1,
});
let indiaTotalOrganicCarbon = new TileLayer({
  visible: true,
  source: new TileWMS({
    url: `${rooApiUrl}/wms`,
    params: {
      TILED: true,
      LAYERS: "haldia:toc_india_pcs",
    },
    serverType: "geoserver",
    crossOrigin: "Anonymous",
  }),
  zIndex: 1,
});

let bioGeographiczone = new TileLayer({
  visible: true,
  source: new TileWMS({
    url: `${rooApiUrl}/wms`,
    params: {
      TILED: true,
      LAYERS: "haldia:biogeographiczone_wb",
    },
    serverType: "geoserver",
    crossOrigin: "Anonymous",
  }),
  zIndex: 1,
});

let eastMedinipurCensus = new TileLayer({
  visible: true,
  source: new TileWMS({
    url: `${rooApiUrl}/wms`,
    params: {
      TILED: true,
      LAYERS: "haldia:east_medinipur_Census_2011",
    },
    serverType: "geoserver",
    crossOrigin: "Anonymous",
  }),
  zIndex: 1,
});

let south24Pgs = new TileLayer({
  visible: true,
  source: new TileWMS({
    url: `${rooApiUrl}/wms`,
    params: {
      TILED: true,
      LAYERS: "haldia:south_24_pgs",
    },
    serverType: "geoserver",
    crossOrigin: "Anonymous",
  }),
  zIndex: 1,
});

let north24Pgs = new TileLayer({
  visible: true,
  source: new TileWMS({
    url: `${rooApiUrl}/wms`,
    params: {
      TILED: true,
      LAYERS: "haldia:north_24_pgs",
    },
    serverType: "geoserver",
    crossOrigin: "Anonymous",
  }),
  zIndex: 1,
});
let globalForest = new TileLayer({
  source: new TileWMS({
    url: "https://forest-observatory.ec.europa.eu/wms/GFC2020",
    params: {
      LAYERS: "forest", // Updated to the 'forest' layer
      TILED: true,
    },
    serverType: "geoserver", // Optional, specify server type if needed
    transition: 0,
  }),
});
let globalForestPoint = new TileLayer({
  source: new TileWMS({
    url: `${rooApiUrl}/wms`,
    params: {
      LAYERS: "haldia:global_forest", // Updated to the 'forest' layer
      TILED: true,
    },
    serverType: "geoserver",
    crossOrigin: "Anonymous",
  }),
  zIndex: 4,
});

let ghanaDistrict = new TileLayer({
  visible: true,
  source: new TileWMS({
    url: `${rooApiUrl}/wms`,
    params: {
      TILED: true,
      LAYERS: "haldia:ghana_district",
    },
    serverType: "geoserver",
    crossOrigin: "Anonymous",
  }),
  zIndex: 1,
});
let ghanaPopulation = new TileLayer({
  visible: true,
  source: new TileWMS({
    url: `${rooApiUrl}/wms`,
    params: {
      TILED: true,
      LAYERS: "haldia:ghana_population",
    },
    serverType: "geoserver",
    crossOrigin: "Anonymous",
  }),
  zIndex: 2,
});
let ghanaRivers = new TileLayer({
  visible: true,
  source: new TileWMS({
    url: `${rooApiUrl}/wms`,
    params: {
      TILED: true,
      LAYERS: "haldia:ghana_river",
    },
    serverType: "geoserver",
    crossOrigin: "Anonymous",
  }),
  zIndex: 3,
});
let ghanaLakes = new TileLayer({
  visible: true,
  source: new TileWMS({
    url: `${rooApiUrl}/wms`,
    params: {
      TILED: true,
      LAYERS: "haldia:ghana_lakes",
    },
    serverType: "geoserver",
    crossOrigin: "Anonymous",
  }),
  zIndex: 4,
});

let ghanaElectricityNetwork = new TileLayer({
  visible: true,
  source: new TileWMS({
    url: `${rooApiUrl}/wms`,
    params: {
      TILED: true,
      LAYERS: "haldia:ghana_electricity_transmission_network",
    },
    serverType: "geoserver",
    crossOrigin: "Anonymous",
  }),
  zIndex: 4,
});

let ghanaRoadNetwork = new TileLayer({
  visible: true,
  source: new TileWMS({
    url: `${rooApiUrl}/wms`,
    params: {
      TILED: true,
      LAYERS: "haldia:ghana_roads",
    },
    serverType: "geoserver",
    crossOrigin: "Anonymous",
  }),
  zIndex: 4,
});

let nationalParkLayer = new TileLayer({
  visible: true,
  source: new TileWMS({
    url: `${rooApiUrl}/wms`,
    params: {
      TILED: true,
      LAYERS: "haldia:np",
    },
    serverType: "geoserver",
    crossOrigin: "Anonymous",
  }),
  zIndex: 1,
});

let wildlifeSanctuaryLayer = new TileLayer({
  visible: true,
  source: new TileWMS({
    url: `${rooApiUrl}/wms`,
    params: {
      TILED: true,
      LAYERS: "haldia:wls",
    },
    serverType: "geoserver",
    crossOrigin: "Anonymous",
  }),
  zIndex: 1,
});

let tigerReserveLayer = new TileLayer({
  visible: true,
  source: new TileWMS({
    url: `${rooApiUrl}/wms`,
    params: {
      TILED: true,
      LAYERS: "haldia:tr",
    },
    serverType: "geoserver",
    crossOrigin: "Anonymous",
  }),
  zIndex: 1,
});

let biosphereReserveLayer = new TileLayer({
  visible: true,
  source: new TileWMS({
    url: `${rooApiUrl}/wms`,
    params: {
      TILED: true,
      LAYERS: "haldia:biospherer",
    },
    serverType: "geoserver",
    crossOrigin: "Anonymous",
  }),
  zIndex: 1,
});

let ecoSensitiveZoneLayer = new TileLayer({
  visible: true,
  source: new TileWMS({
    url: `${rooApiUrl}/wms`,
    params: {
      TILED: true,
      LAYERS: "haldia:esz",
    },
    serverType: "geoserver",
    crossOrigin: "Anonymous",
  }),
  zIndex: 1,
});

export const addLayerToMap = (map, layerDetails) => {
  let currentLayer = null;
  if (layerDetails && layerDetails?.layerList.length > 0) {
    // if (currentLayer != null) {
    //     map.removeLayer(currentLayer);
    // }
    if (layerDetails.layerList.filter((layers) => layers.isActive).length > 0) {
      let activeLayer = layerDetails.layerList.filter(
        (layers) => layers.isActive
      )[0].layerName;
      if (activeLayer === "HALDIA_MOUZA") {
        map.addLayer(haldiaMouza);
        currentLayer = haldiaMouza;
        let extent = [
          9802598.190540502, 2513064.701468866, 9807382.456989272,
          2534994.7038455834,
        ];
        map.getView().fit(extent, {
          duration: 1600, // Set the duration for the animation (in milliseconds)
          //padding: [10, 10, 10, 10], // Optional: Add padding around the extent
          maxZoom: 20, // Optional: Specify the maximum zoom level
        });
      } else if (activeLayer === "EAST_MEDINIPUR_CENSUS") {
        map.addLayer(eastMedinipurCensus);
        let extent = [9732216, 2464915.75, 9817326, 2574000];
        // if (layerExtent && !isNaN(layerExtent[0])) {
        map.getView().fit(extent, {
          size: map.getSize(),
          duration: 1600,
          maxZoom: 20,
        });
        currentLayer = eastMedinipurCensus;
        // let extent = [
        //   9802474.067558413, 2513759.4512988343, 9811313.398093091,
        //   2533745.2018277766,
        // ];
        // map.getView().fit(extent, {
        //   duration: 1600, // Set the duration for the animation (in milliseconds)
        //   //padding: [10, 10, 10, 10], // Optional: Add padding around the extent
        //   maxZoom: 12, // Optional: Specify the maximum zoom level
        // });
      } else if (activeLayer === "NORTH_24_PGS") {
        map.addLayer(north24Pgs);
        let extent = [9833050, 2525957.25, 9918423, 2663017.75];
        // if (layerExtent && !isNaN(layerExtent[0])) {
        map.getView().fit(extent, {
          size: map.getSize(),
          duration: 1600,
          maxZoom: 20,
        });
        currentLayer = north24Pgs;
      } else if (activeLayer === "SOUTH_24_PGS") {
        map.addLayer(south24Pgs);
        let extent = [9797178, 2454839.5, 9924270, 2586739.25];
        // if (layerExtent && !isNaN(layerExtent[0])) {
        map.getView().fit(extent, {
          size: map.getSize(),
          duration: 1600,
          maxZoom: 20,
        });
        currentLayer = south24Pgs;
        // let extent = [
        //   9802474.067558413, 2513759.4512988343, 9811313.398093091,
        //   2533745.2018277766,
        // ];
        // map.getView().fit(extent, {
        //   duration: 1600, // Set the duration for the animation (in milliseconds)
        //   //padding: [10, 10, 10, 10], // Optional: Add padding around the extent
        //   maxZoom: 12, // Optional: Specify the maximum zoom level
        // });
      } else if (activeLayer === "GLOBAL_FOREST") {
        map.addLayer(globalForest);
        map.addLayer(globalForestPoint);
        let extent = [9797178, 2454839.5, 9924270, 2586739.25];
        // if (layerExtent && !isNaN(layerExtent[0])) {
        map.getView().fit(extent, {
          size: map.getSize(),
          duration: 1600,
          maxZoom: 20,
        });
        currentLayer = globalForestPoint;
      } else if (activeLayer === "AIR_QUALITY") {
        map.addLayer(airQuality);
        let extent = [
          9802598.190540502, 2513064.701468866, 9807382.456989272,
          2534994.7038455834,
        ];
        // if (layerExtent && !isNaN(layerExtent[0])) {
        map.getView().fit(extent, {
          size: map.getSize(),
          duration: 1600,
          maxZoom: 20,
        });
        currentLayer = airQuality;
        // let extent = [
        //   9802474.067558413, 2513759.4512988343, 9811313.398093091,
        //   2533745.2018277766,
        // ];
        // map.getView().fit(extent, {
        //   duration: 1600, // Set the duration for the animation (in milliseconds)
        //   //padding: [10, 10, 10, 10], // Optional: Add padding around the extent
        //   maxZoom: 12, // Optional: Specify the maximum zoom level
        // });
      } else if (activeLayer === "NOISE_DATA") {
        map.addLayer(noiseLayer);
        currentLayer = noiseLayer;
        let extent = [
          9802474.067558413, 2513759.4512988343, 9811313.398093091,
          2533745.2018277766,
        ];
        map.getView().fit(extent, {
          duration: 1600, // Set the duration for the animation (in milliseconds)
          //padding: [10, 10, 10, 10], // Optional: Add padding around the extent
          maxZoom: 12, // Optional: Specify the maximum zoom level
        });
      } else if (activeLayer === "SURFACE_WATER") {
        map.addLayer(surfaceWater);
        currentLayer = surfaceWater;
        let extent = [
          9802474.067558413, 2513759.4512988343, 9811313.398093091,
          2533745.2018277766,
        ];
        map.getView().fit(extent, {
          duration: 1600, // Set the duration for the animation (in milliseconds)
          //padding: [10, 10, 10, 10], // Optional: Add padding around the extent
          maxZoom: 12, // Optional: Specify the maximum zoom level
        });
      } else if (activeLayer === "HALDIA_ROAD") {
        map.addLayer(haldiaRoad);
        currentLayer = haldiaRoad;
        let extent = [
          9802474.067558413, 2513759.4512988343, 9811313.398093091,
          2533745.2018277766,
        ];
        map.getView().fit(extent, {
          duration: 1600, // Set the duration for the animation (in milliseconds)
          //padding: [10, 10, 10, 10], // Optional: Add padding around the extent
          maxZoom: 12, // Optional: Specify the maximum zoom level
        });
      } else if (activeLayer === "HALDIA_STORAGE") {
        map.addLayer(haldiaStorage);
        currentLayer = haldiaStorage;
        let extent = [
          9807723.098211138, 2516817.011149502, 9808648.194763629,
          2520484.287241721,
        ];
        map.getView().fit(extent, {
          duration: 1600, // Set the duration for the animation (in milliseconds)
          //padding: [10, 10, 10, 10], // Optional: Add padding around the extent
          maxZoom: 25, // Optional: Specify the maximum zoom level
        });
      } else if (activeLayer === "HALDIA_INDUSTRY") {
        map.addLayer(haldiaIndustry);
        currentLayer = haldiaIndustry;
        let extent = [
          9806964.512858635, 2515325.583324111, 9809616.886151792,
          2520624.5853277976,
        ];
        map.getView().fit(extent, {
          duration: 1600, // Set the duration for the animation (in milliseconds)
          //padding: [10, 10, 10, 10], // Optional: Add padding around the extent
          maxZoom: 18, // Optional: Specify the maximum zoom level
        });
      } else if (activeLayer === "INDIA_STATE") {
        map.addLayer(indiaState);
        currentLayer = indiaState;
        let extent = [
          8548452.33775359, 944645.5104109896, 8931932.38070152,
          4284952.439542378,
        ];
        map.getView().fit(extent, {
          duration: 1600, // Set the duration for the animation (in milliseconds)
          //padding: [10, 10, 10, 10], // Optional: Add padding around the extent
          maxZoom: 25, // Optional: Specify the maximum zoom level
        });
      } else if (activeLayer === "AVAILABLE_WATER") {
        map.addLayer(indiaTotalAvailableWater);
        currentLayer = indiaTotalAvailableWater;
        let extent = [
          8548452.33775359, 944645.5104109896, 8931932.38070152,
          4284952.439542378,
        ];
        map.getView().fit(extent, {
          duration: 1600, // Set the duration for the animation (in milliseconds)
          //padding: [10, 10, 10, 10], // Optional: Add padding around the extent
          maxZoom: 25, // Optional: Specify the maximum zoom level
        });
      } else if (activeLayer === "HYDROLIC_CONDUCTIVITY") {
        map.addLayer(indiaHydrolicConductivity);
        currentLayer = indiaHydrolicConductivity;
        let extent = [
          8548452.33775359, 944645.5104109896, 8931932.38070152,
          4284952.439542378,
        ];
        map.getView().fit(extent, {
          duration: 1600, // Set the duration for the animation (in milliseconds)
          //padding: [10, 10, 10, 10], // Optional: Add padding around the extent
          maxZoom: 25, // Optional: Specify the maximum zoom level
        });
      } else if (activeLayer === "ORGANIC_CARBON") {
        map.addLayer(indiaTotalOrganicCarbon);
        currentLayer = indiaTotalOrganicCarbon;
        let extent = [
          8548452.33775359, 944645.5104109896, 8931932.38070152,
          4284952.439542378,
        ];
        map.getView().fit(extent, {
          duration: 1600, // Set the duration for the animation (in milliseconds)
          //padding: [10, 10, 10, 10], // Optional: Add padding around the extent
          maxZoom: 25, // Optional: Specify the maximum zoom level
        });
      } else if (activeLayer === "INDIA_DISTRICT") {
        map.addLayer(indiaDistrictCensus);
        currentLayer = indiaDistrictCensus;
        let extent = [
          8548452.33775359, 944645.5104109896, 8931932.38070152,
          4284952.439542378,
        ];
        map.getView().fit(extent, {
          duration: 1600, // Set the duration for the animation (in milliseconds)
          //padding: [10, 10, 10, 10], // Optional: Add padding around the extent
          maxZoom: 25, // Optional: Specify the maximum zoom level
        });
      } else if (activeLayer === "INDIA_VILLAGE_WB") {
        map.addLayer(indiaVillageWestbengal);
        currentLayer = indiaVillageWestbengal;
        let extent = [
          9740957.746955309, 2471653.9277088917, 9981610.874710262,
          3097985.7872968162,
        ];
        map.getView().fit(extent, {
          duration: 1600, // Set the duration for the animation (in milliseconds)
          //padding: [10, 10, 10, 10], // Optional: Add padding around the extent
          maxZoom: 6.9, // Optional: Specify the maximum zoom level
        });
      } else if (activeLayer === "LAND_USE_LAND_COVER") {
        map.addLayer(bhubanLanduse);
        currentLayer = bhubanLanduseDataLayer;
        let extent = [
          9740957.746955309, 2471653.9277088917, 9981610.874710262,
          3097985.7872968162,
        ];
        map.getView().fit(extent, {
          duration: 1600, // Set the duration for the animation (in milliseconds)
          //padding: [10, 10, 10, 10], // Optional: Add padding around the extent
          maxZoom: 6.9, // Optional: Specify the maximum zoom level
        });
      } else if (activeLayer === "BIOGEOGRAPHIC_ZONE") {
        map.addLayer(bioGeographiczone);
        currentLayer = bioGeographiczone;
        let extent = [
          9740957.746955309, 2471653.9277088917, 9981610.874710262,
          3097985.7872968162,
        ];
        map.getView().fit(extent, {
          duration: 1600, // Set the duration for the animation (in milliseconds)
          //padding: [10, 10, 10, 10], // Optional: Add padding around the extent
          maxZoom: 6.9, // Optional: Specify the maximum zoom level
        });
      }
      if (activeLayer === "NATIONAL_PARK") {
        map.addLayer(nationalParkLayer);
        currentLayer = nationalParkLayer;
        let extent = [
          9740957.746955309, 2471653.9277088917, 9981610.874710262,
          3097985.7872968162,
        ];
        map.getView().fit(extent, {
          duration: 1600,
          maxZoom: 25,
        });
      } else if (activeLayer === "WILDLIFE_SANCTUARY") {
        map.addLayer(wildlifeSanctuaryLayer);
        currentLayer = wildlifeSanctuaryLayer;
        let extent = [
          9740957.746955309, 2471653.9277088917, 9981610.874710262,
          3097985.7872968162,
        ];
        map.getView().fit(extent, {
          duration: 1600,
          maxZoom: 25,
        });
      } else if (activeLayer === "TIGER_RESERVE") {
        map.addLayer(tigerReserveLayer);
        currentLayer = tigerReserveLayer;
        let extent = [
          9740957.746955309, 2471653.9277088917, 9981610.874710262,
          3097985.7872968162,
        ];
        map.getView().fit(extent, {
          duration: 1600,
          maxZoom: 25,
        });
      } else if (activeLayer === "BIOSPHERE_RESERVE") {
        map.addLayer(biosphereReserveLayer);
        currentLayer = biosphereReserveLayer;
        let extent = [
          9740957.746955309, 2471653.9277088917, 9981610.874710262,
          3097985.7872968162,
        ];
        map.getView().fit(extent, {
          duration: 1600,
          maxZoom: 25,
        });
      } else if (activeLayer === "ECO_SENSITIVE_ZONE") {
        map.addLayer(ecoSensitiveZoneLayer);
        currentLayer = ecoSensitiveZoneLayer;
        let extent = [
          9740957.746955309, 2471653.9277088917, 9981610.874710262,
          3097985.7872968162,
        ];
        map.getView().fit(extent, {
          duration: 1600,
          maxZoom: 25,
        });
      } else if (activeLayer === "LAYER_KHASRA_POLYGON") {
        map.addLayer(layer1503HaKhasra);
        //setCurrentLayer(haldiaIndustry);
        currentLayer = layer1503HaKhasra;
        map.getView().animate({
          zoom: 12,
          duration: 1590,
        });
      } else if (activeLayer === "GHANA_DISTRICT") {
        map.addLayer(ghanaDistrict);
        //setCurrentLayer(haldiaIndustry);
        currentLayer = ghanaDistrict;
        let extent = [-363130.71875, 528120.3125, 133607.28125, 1251940.375];
        map.getView().fit(extent, {
          duration: 1600, // Set the duration for the animation (in milliseconds)
          //padding: [10, 10, 10, 10], // Optional: Add padding around the extent
          maxZoom: 6.9, // Optional: Specify the maximum zoom level
        });
      } else if (activeLayer === "GHANA_RIVER") {
        map.addLayer(ghanaRivers);
        //setCurrentLayer(haldiaIndustry);
        currentLayer = ghanaRivers;
        let extent = [-363130.71875, 528120.3125, 133607.28125, 1251940.375];
        map.getView().fit(extent, {
          duration: 1600, // Set the duration for the animation (in milliseconds)
          //padding: [10, 10, 10, 10], // Optional: Add padding around the extent
          maxZoom: 6.9, // Optional: Specify the maximum zoom level
        });
      } else if (activeLayer === "GHANA_POPULATION") {
        map.addLayer(ghanaPopulation);
        //setCurrentLayer(haldiaIndustry);
        currentLayer = ghanaPopulation;
        let extent = [-363130.71875, 528120.3125, 133607.28125, 1251940.375];
        map.getView().fit(extent, {
          duration: 1600, // Set the duration for the animation (in milliseconds)
          //padding: [10, 10, 10, 10], // Optional: Add padding around the extent
          maxZoom: 6.9, // Optional: Specify the maximum zoom level
        });
      } else if (activeLayer === "GHANA_ELECTRICITY_TRANSMISSION_NETWORK") {
        map.addLayer(ghanaElectricityNetwork);
        //setCurrentLayer(haldiaIndustry);
        currentLayer = ghanaElectricityNetwork;
        let extent = [-363130.71875, 528120.3125, 133607.28125, 1251940.375];
        map.getView().fit(extent, {
          duration: 1600, // Set the duration for the animation (in milliseconds)
          //padding: [10, 10, 10, 10], // Optional: Add padding around the extent
          maxZoom: 6.9, // Optional: Specify the maximum zoom level
        });
      } else if (activeLayer === "GHANA_LAKES") {
        map.addLayer(ghanaLakes);
        //setCurrentLayer(haldiaIndustry);
        currentLayer = ghanaLakes;
        let extent = [-363130.71875, 528120.3125, 133607.28125, 1251940.375];
        map.getView().fit(extent, {
          duration: 1600, // Set the duration for the animation (in milliseconds)
          //padding: [10, 10, 10, 10], // Optional: Add padding around the extent
          maxZoom: 6.9, // Optional: Specify the maximum zoom level
        });
      } else if (activeLayer === "GHANA_ROAD") {
        map.addLayer(ghanaRoadNetwork);
        //setCurrentLayer(haldiaIndustry);
        currentLayer = ghanaRoadNetwork;
        let extent = [-363130.71875, 528120.3125, 133607.28125, 1251940.375];
        map.getView().fit(extent, {
          duration: 1600, // Set the duration for the animation (in milliseconds)
          //padding: [10, 10, 10, 10], // Optional: Add padding around the extent
          maxZoom: 6.9, // Optional: Specify the maximum zoom level
        });
      }
    } else {
      let groupOfLayers = [];
      if (
        layerDetails?.layerList.find((ele) => ele.layerName === "HALDIA_MOUZA")
      ) {
        groupOfLayers.push(haldiaMouza);
        currentLayer = null;
      }
      if (
        layerDetails?.layerList.find((ele) => ele.layerName === "AIR_QUALITY")
      ) {
        groupOfLayers.push(airQuality);
        currentLayer = null;
      }
      if (
        layerDetails?.layerList.find((ele) => ele.layerName === "NOISE_DATA")
      ) {
        groupOfLayers.push(noiseLayer);
        currentLayer = null;
      }
      if (
        layerDetails?.layerList.find((ele) => ele.layerName === "SURFACE_WATER")
      ) {
        groupOfLayers.push(surfaceWater);
        currentLayer = null;
      }
      if (
        layerDetails?.layerList.find((ele) => ele.layerName === "HALDIA_ROAD")
      ) {
        groupOfLayers.push(haldiaRoad);
        currentLayer = null;
      }
      if (
        layerDetails?.layerList.find(
          (ele) => ele.layerName === "HALDIA_STORAGE"
        )
      ) {
        groupOfLayers.push(haldiaStorage);
        currentLayer = null;
      }
      if (
        layerDetails?.layerList.find(
          (ele) => ele.layerName === "HALDIA_INDUSTRY"
        )
      ) {
        groupOfLayers.push(haldiaIndustry);
        currentLayer = null;
      }
      if (
        layerDetails?.layerList.find(
          (ele) => ele.layerName === "LAYER_KHASRA_POLYGON"
        )
      ) {
        groupOfLayers.push(layer1503HaKhasra);
        currentLayer = null;
      }
      if (
        layerDetails?.layerList.find(
          (ele) => ele.layerName === "BIOGEOGRAPHIC_ZONE"
        )
      ) {
        groupOfLayers.push(bioGeographiczone);
        currentLayer = null;
      }
      if (
        layerDetails?.layerList.find((ele) => ele.layerName === "NATIONAL_PARK")
      ) {
        groupOfLayers.push(nationalParkLayer);
        currentLayer = null;
      }

      if (
        layerDetails?.layerList.find(
          (ele) => ele.layerName === "WILDLIFE_SANCTUARY"
        )
      ) {
        groupOfLayers.push(wildlifeSanctuaryLayer);
        currentLayer = null;
      }

      if (
        layerDetails?.layerList.find((ele) => ele.layerName === "TIGER_RESERVE")
      ) {
        groupOfLayers.push(tigerReserveLayer);
        currentLayer = null;
      }

      if (
        layerDetails?.layerList.find(
          (ele) => ele.layerName === "BIOSPHERE_RESERVE"
        )
      ) {
        groupOfLayers.push(biosphereReserveLayer);
        currentLayer = null;
      }

      if (
        layerDetails?.layerList.find(
          (ele) => ele.layerName === "ECO_SENSITIVE_ZONE"
        )
      ) {
        groupOfLayers.push(ecoSensitiveZoneLayer);
        currentLayer = null;
      }
      if (
        layerDetails?.layerList.find((ele) => ele.layerName === "INDIA_STATE")
      ) {
        groupOfLayers.push(indiaState);
        currentLayer = null;
      }
      if (
        layerDetails?.layerList.find(
          (ele) => ele.layerName === "AVAILABLE_WATER"
        )
      ) {
        groupOfLayers.push(indiaTotalAvailableWater);
        currentLayer = null;
      }
      if (
        layerDetails?.layerList.find(
          (ele) => ele.layerName === "ORGANIC_CARBON"
        )
      ) {
        groupOfLayers.push(indiaTotalOrganicCarbon);
        currentLayer = null;
      }
      if (
        layerDetails?.layerList.find(
          (ele) => ele.layerName === "HYDROLIC_CONDUCTIVITY"
        )
      ) {
        groupOfLayers.push(indiaHydrolicConductivity);
        currentLayer = null;
      }
      if (
        layerDetails?.layerList.find((ele) => ele.layerName === "GLOBAL_FOREST")
      ) {
        groupOfLayers.push(globalForestPoint);
        groupOfLayers.push(globalForest);
        currentLayer = null;
      }
      if (
        layerDetails?.layerList.find(
          (ele) => ele.layerName === "INDIA_DISTRICT"
        )
      ) {
        groupOfLayers.push(indiaDistrictCensus);
        currentLayer = null;
      }
      if (
        layerDetails?.layerList.find(
          (ele) => ele.layerName === "INDIA_VILLAGE_WB"
        )
      ) {
        groupOfLayers.push(indiaVillageWestbengal);
        currentLayer = null;
      }
      if (
        layerDetails?.layerList.find(
          (ele) => ele.layerName === "LAND_USE_LAND_COVER"
        )
      ) {
        groupOfLayers.push(bhubanLanduse);
        currentLayer = null;
      }
      if (
        layerDetails?.layerList.find(
          (ele) => ele.layerName === "EAST_MEDINIPUR_CENSUS"
        )
      ) {
        groupOfLayers.push(eastMedinipurCensus);
        currentLayer = null;
      }
      if (
        layerDetails?.layerList.find((ele) => ele.layerName === "SOUTH_24_PGS")
      ) {
        groupOfLayers.push(south24Pgs);
        currentLayer = null;
      }
      if (
        layerDetails?.layerList.find((ele) => ele.layerName === "NORTH_24_PGS")
      ) {
        groupOfLayers.push(north24Pgs);
        currentLayer = null;
      }
      if (
        layerDetails?.layerList.find(
          (ele) => ele.layerName === "GHANA_DISTRICT"
        )
      ) {
        groupOfLayers.push(ghanaDistrict);
        currentLayer = null;
      }
      if (
        layerDetails?.layerList.find((ele) => ele.layerName === "GHANA_RIVER")
      ) {
        groupOfLayers.push(ghanaRivers);
        currentLayer = null;
      }
      if (
        layerDetails?.layerList.find(
          (ele) => ele.layerName === "GHANA_POPULATION"
        )
      ) {
        groupOfLayers.push(ghanaPopulation);
        currentLayer = null;
      }
      if (
        layerDetails?.layerList.find((ele) => ele.layerName === "GHANA_LAKES")
      ) {
        groupOfLayers.push(ghanaLakes);
        currentLayer = null;
      }
      if (
        layerDetails?.layerList.find(
          (ele) => ele.layerName === "GHANA_ELECTRICITY_TRANSMISSION_NETWORK"
        )
      ) {
        groupOfLayers.push(ghanaElectricityNetwork);
        currentLayer = null;
      }
      if (
        layerDetails?.layerList.find((ele) => ele.layerName === "GHANA_ROAD")
      ) {
        groupOfLayers.push(ghanaRoadNetwork);
        currentLayer = null;
      }
      // else if (layerDetails === 'ALL') {
      //     map.addLayer(layerGroup);
      //     //setCurrentLayer(null);
      //     currentLayer = null;
      // }
      let layerGroup = new LayerGroup({
        layers: groupOfLayers,
      });

      map.addLayer(layerGroup);
      // let extent = [8548452.33775359, 944645.5104109896, 8931932.38070152, 4284952.439542378]
      // map.getView().fit(extent, {
      //     duration: 1600, // Set the duration for the animation (in milliseconds)
      //     maxZoom: 25 // Optional: Specify the maximum zoom level
      // });
    }
  }

  return currentLayer;
};
