// export const projectableLayersList = [
//     // {
//     //     name: 'Add All Layer To Map',
//     //     value: 'ALL'
//     // },
//     // {
//     //     name: 'Remove All Layer From Map',
//     //     value: 'REMOVE_ALL'
//     // },
//     {
//       name: "Haldia Mouza",
//       value: "HALDIA_MOUZA",
//       isDisable: true,
//     },
//     {
//       name: "Noise",
//       value: "NOISE_DATA",
//       isDisable: true,
//     },
//     {
//       name: "Air Quality",
//       value: "AIR_QUALITY",
//       isDisable: true,
//     },
//     {
//       name: "Surface Water",
//       value: "SURFACE_WATER",
//       isDisable: true,
//     },
//     {
//       name: "Haldia Industry",
//       value: "HALDIA_INDUSTRY",
//       isDisable: true,
//     },
//     {
//       name: "Haldia Road",
//       value: "HALDIA_ROAD",
//       isDisable: true,
//     },
//     {
//       name: "Haldia Storage",
//       value: "HALDIA_STORAGE",
//       isDisable: true,
//     },
//     {
//       name: "India District",
//       value: "INDIA_DISTRICT",
//       isDisable: true,
//     },
//     {
//       name: "West Bengal Biogeographic Zone",
//       value: "BIOGEOGRAPHIC_ZONE",
//       isDisable: true,
//     },
//     {
//       name: "East Medinipur Census ",
//       value: "EAST_MEDINIPUR_CENSUS",
//       isDisable: true,
//     },
//     // {
//     //   name: "South 24 PGS",
//     //   value: "SOUTH_24_PGS",
//     //   isDisable: true,
//     // },
//     // {
//     //   name: "North 24 PGS",
//     //   value: "NORTH_24_PGS",
//     //   isDisable: true,
//     // },
//     {
//       name: "Land Use Land Cover",
//       value: "LAND_USE_LAND_COVER",
//       isDisable: true,
//     },
//     // {
//     //     name: '	Layer 1503 Ha Khasra Polygon',
//     //     value: 'LAYER_KHASRA_POLYGON'
//     // },
//     // {
//     //     name: 'India State',
//     //     value: 'INDIA_STATE',
//     //     isDisable: true
//     // },
//     // {
//     //     name: 'West Bengal Village',
//     //     value: 'INDIA_VILLAGE_WB',
//     //     isDisable: true
//     // }
//   ];

export const projectableLayersList = [
  {
    name: "Haldia Mouza",
    value: "HALDIA_MOUZA",
    originalName: "mouza", // Added originalName key
    isDisable: true,
  },
  {
    name: "Noise",
    value: "NOISE_DATA",
    originalName: "noise data", // Added originalName key
    isDisable: true,
  },
  {
    name: "Air Quality",
    value: "AIR_QUALITY",
    originalName: "aqdata", // Added originalName key
    isDisable: true,
  },
  {
    name: "Surface Water",
    value: "SURFACE_WATER",
    originalName: "sw_latin", // Added originalName key
    isDisable: true,
  },
  {
    name: "Haldia Industry",
    value: "HALDIA_INDUSTRY",
    originalName: "industry", // Added originalName key
    isDisable: true,
  },
  {
    name: "Haldia Road",
    value: "HALDIA_ROAD",
    originalName: "road", // Added originalName key
    isDisable: true,
  },
  {
    name: "Haldia Storage",
    value: "HALDIA_STORAGE",
    originalName: "storage", // Added originalName key
    isDisable: true,
  },
  {
    name: "India District",
    value: "INDIA_DISTRICT",
    originalName: "india_district_census", // Added originalName key
    isDisable: true,
  },
  {
    name: "West Bengal Biogeographic Zone",
    value: "BIOGEOGRAPHIC_ZONE",
    originalName: "biogeographiczone_wb", // Added originalName key
    isDisable: true,
  },
  {
    name: "East Medinipur Census",
    value: "EAST_MEDINIPUR_CENSUS",
    originalName: "east_medinipur_Census_2011", // Added originalName key
    isDisable: true,
  },
  {
    name: "Land Use Land Cover",
    value: "LAND_USE_LAND_COVER",
    originalName: "LAND_USE_LAND_COVER", // Added originalName key
    isDisable: true,
  },
  {
    name: "Global Forest",
    value: "GLOBAL_FOREST",
    originalName: "global_forest", // Added originalName key
    isDisable: true,
  },
  {
    name: "India Hydrolic Conductivity",
    value: "HYDROLIC_CONDUCTIVITY",
    originalName: "kindia_pcs", // Added originalName key
    isDisable: true,
  },
  {
    name: "India Total Available Water",
    value: "AVAILABLE_WATER",
    originalName: "indiataw", // Added originalName key
    isDisable: true,
  },
  {
    name: "India Total Organic Carbon",
    value: "ORGANIC_CARBON",
    originalName: "toc_india_pcs", // Added originalName key
    isDisable: true,
  },
  {
    name: "Ghana District",
    value: "GHANA_DISTRICT",
    originalName: "ghana_district", // Added originalName key
    isDisable: true,
  },
  {
    name: "Ghana River",
    value: "GHANA_RIVER",
    originalName: "ghana_river", // Added originalName key
    isDisable: true,
  },
  {
    name: "Ghana Populatio",
    value: "GHANA_POPULATION",
    originalName: "ghana_population", // Added originalName key
    isDisable: true,
  },
  {
    name: "Ghana Lakes",
    value: "GHANA_LAKES",
    originalName: "ghana_lakes", // Added originalName key
    isDisable: true,
  },
  {
    name: "Ghana Electricity Transmission Network	",
    value: "GHANA_ELECTRICITY_TRANSMISSION_NETWORK",
    originalName: "ghana_electricity_transmission_network", // Added originalName key
    isDisable: true,
  },
  // {
  //   name: "Ghana Roads Network	",
  //   value: "GHANA_ROAD",
  //   originalName: "ghana_roads", // Added originalName key
  //   isDisable: true,
  // },
  {
    name: "National Park",
    value: "NATIONAL_PARK",
    originalName: "np",
    isDisable: true
  },
  {
    name: "Wildlife Sanctuary",
    value: "WILDLIFE_SANCTUARY",
    originalName: "wls",
    isDisable: true
  },
  {
    name: "Tiger Reserve",
    value: "TIGER_RESERVE",
    originalName: "tr",
    isDisable: true
  },
  {
    name: "Biosphere Reserve",
    value: "BIOSPHERE_RESERVE",
    originalName: "biospherer",
    isDisable: true
  },
  {
    name: "Eco Sensitive Zone",
    value: "ECO_SENSITIVE_ZONE",
    originalName: "esz",
    isDisable: true
  }
];
export const getLayerOriginalName = (name) => {
  let originallayerName = projectableLayersList.filter(
    (layer) => layer.value == name
  )[0].originalName;
  return originallayerName;
};
