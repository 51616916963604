import React, { useEffect, useState } from "react";
import { material } from "../../../library/material";
import { useForm } from "react-hook-form";
import GeoJSON from "ol/format/GeoJSON";
import { createFeature } from "../../../services/LayerModificationService";
import { wfsTransactionXml } from "../../../utils/modificationFunctionlity";
import GML from "ol/format/GML";
import Alert from "../../alert-message/Alert";

function AddNewFeature({
  featureModificationDialogOpen,
  setFeatureModificationDialogOpen,
  layerPropertyDetails,
  map,
  onClose,
  currentLayer,
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [messageInfo, setMessageInfo] = useState({});
  useEffect(() => {
    console.log(featureModificationDialogOpen);
  }, [featureModificationDialogOpen]);
  const saveFeatureToGeoServer = (formData) => {
    console.log(formData);
    layerPropertyDetails.map(({ name, dataType }) => {
      if (dataType == "number") {
        formData[name] = Number(formData[name]);
      }
    });
    let feature = featureModificationDialogOpen.feature;
    let layerType = featureModificationDialogOpen.layerType;
    let layerName = featureModificationDialogOpen.activeLayerName;
    console.log(feature, layerName);
    //const geojsonFormat = new GeoJSON();
    //const geojsonFeature = geojsonFormat.writeFeatureObject(feature);
    //const geometryType = feature.getGeometry().getType();
    const coords = feature.getGeometry();
    const format = new GML();
    const geojsonFeature = format.writeGeometry(coords, {
      dataProjection: "EPSG:3857", // or the correct CRS
      featureProjection: "EPSG:3857", // or the correct CRS
    });
    console.log(coords);
    //console.log(gml3)
    const wfsTransaction = wfsTransactionXml(
      geojsonFeature,
      formData,
      layerName,
      layerType
    );
    createFeature(wfsTransaction, layerName)
      .then((res) => {
        currentLayer.getSource().refresh();
        map.addLayer(currentLayer);
        setMessageInfo({
          message: "Feature created succesfully",
          type: "success",
          isVisiable: true,
        });
        setFeatureModificationDialogOpen({
          open: false,
          feature: null,
          activeLayerName: null,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const formatText = (text) => {
    return (
      text
        // Replace any delimiter (underscore, hyphen) with a space
        .replace(/[_\-]+/g, " ")
        // Split the text into words based on spaces
        .split(/\s+/)
        // Capitalize the first letter of each word and make the rest lowercase
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        // Join the words back together with a single space
        .join(" ")
        // Trim any leading/trailing spaces
        .trim()
    );
  };

  return (
    <>
      <material.Dialog
        open={featureModificationDialogOpen.open}
        fullWidth
        maxWidth="sm"
      >
        <material.DialogTitle>
          Add new feature with existing layer
        </material.DialogTitle>
        <material.DialogContent>
          <div className="row">
            {layerPropertyDetails
              ? layerPropertyDetails.map((property, i) => (
                  <div className="col-6" key={i}>
                    <material.TextField
                      {...register(`${property.name}`, {
                        required: `${property.name} is required`,
                      })}
                      autoFocus
                      required
                      margin="dense"
                      id={property.name}
                      name={property.name}
                      label={formatText(property.name)}
                      type={
                        property.dataType == "string"
                          ? "text"
                          : property.dataType
                      }
                      fullWidth
                      variant="outlined"
                    />
                  </div>
                ))
              : null}
          </div>
        </material.DialogContent>
        <material.DialogActions>
          <material.Button
            color="error"
            onClick={onClose}
            sx={{ textTransform: "none" }}
          >
            Cancel
          </material.Button>
          <material.Button
            color="success"
            type="submit"
            sx={{ textTransform: "none" }}
            onClick={handleSubmit(saveFeatureToGeoServer)}
          >
            Save
          </material.Button>
        </material.DialogActions>
      </material.Dialog>
      <Alert messageInfo={messageInfo} />
    </>
  );
}

export default AddNewFeature;
