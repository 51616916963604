import React from 'react'
import { Navigate, Route, Routes} from 'react-router-dom'
import Home from '../components/home/Home'
import Login from '../components/login/Login'
// import { axiosInstance, setNavigateCallback } from '../components/interceptor/Iterceptor';
// import { useSelector } from 'react-redux';




export default function Routing() {
  // const navigate = useNavigate()
  // const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
  // const userDetails = useSelector(state => state.auth.user);
  // useEffect(() => {
  //   if (!isLoggedIn) {
  //     navigate("/login");
  //   } else {
  //     axiosInstance.defaults.headers.common[
  //       "Authorization"
  //     ] = `Bearer ${userDetails.accessToken}`;
  //     setNavigateCallback(navigate);
  //   }
  // }, []);

  return (
    <div>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/home" element={<Home />} />
      </Routes>
    </div>
  )
}
