import React, { useState, useEffect } from "react";
import ComboChart from "../charts/ComboChart";
// import HtmlParser from 'html-react-parser'; // For parsing HTML
// import parse from 'html-parser';
import { material } from "../../library/material";
import { applyStyles, createLuLcChartData, extractStyles, extractTableData, updateHtmlWithPercentages } from "../../utils/createChartDataForLuLc";


const DynamicHtmlRendered = ({ htmlContent, selectedArea }) => {
  const [parsedHtml, setParsedHtml] = useState("");
  const [dataForChart, setDataForChart] = useState({
    chartData: null,
    options: null,
  });
  
  useEffect(() => {
    const { styleTags, cleanHtml } = extractStyles(htmlContent);
    console.log(cleanHtml)
    let lulcData = extractTableData(htmlContent);
    let chartData = createLuLcChartData(lulcData, selectedArea);
    setDataForChart(chartData);
    const htmlWithPercentages = updateHtmlWithPercentages(cleanHtml, lulcData,selectedArea);
    styleTags.push(`
        // body {
        //   font-family: 'Arial', sans-serif;
        //   background-color: #f4f4f9;
        //   color: #333;
        //   margin: 0;
        //   padding: 20px;
        // }
        #analysis_div {
          background-color: #fff;
          padding: 20px;
          border-radius: 8px;
          margin-bottom:5px;
          height:500px;
          overflow-x:hidden;
          overflow-y:scroll;
          box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
        }
        table {
          width: 100%;
          border-collapse: collapse;
          font-family: Arial, sans-serif;
          font-size: 9pt;
          margin: 20px 0;
        }
        th, td {
          border: 1px solid #ccc;
          padding: 12px;
          text-align: left;
        }
        th {
          background-color: #D6DBE7;
          font-weight: bold;
        }
        tr:nth-child(even) {
          background-color: #f9f9f9;
        }
        tr:hover {
          background-color: #f1f1f1;
        }
        td:first-child {
          width: 5%;
          border-left: 5px solid;
        }
        p {
          font-family: Arial, sans-serif;
          font-size: 10pt;
          margin: 10px 0;
          font-weight:bold;
        }
        b {
          font-size: 10.5pt;
        }
        center {
          margin-bottom: 20px;
        }
      `);
    applyStyles(styleTags);
    setParsedHtml(htmlWithPercentages);
  }, [htmlContent]);

  

  return (
    <div className="row">
      <div dangerouslySetInnerHTML={{ __html: parsedHtml }} />
      <div className="col-6">
        <material.Paper
          sx={{
            p: 2,
            boxShadow:
              "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
          }}
        >
          <ComboChart
            data={dataForChart.chartData}
            options={dataForChart.options}
            chartType={"PieChart"}
          />
        </material.Paper>
      </div>
    </div>
  );
};
export default DynamicHtmlRendered;
