import React from "react";
import { Controller, useFieldArray } from "react-hook-form";
import { material } from "../../library/material";

function StructureForm({ control, watch, register, setValue, callFrom }) {
  const {
    fields: residentialField,
    append: appendResidential,
    remove: removeResidential,
  } = useFieldArray({
    control: control,
    name: "residentialDetails",
  });
  const {
    fields: commercialField,
    append: appendCommercial,
    remove: removeCommercial,
  } = useFieldArray({
    control: control,
    name: "commercialDetails",
  });
  const structureTypeList = [
    { name: "Kutcha", value: "Kutcha" },
    { name: "Pucca", value: "Pucca" },
  ];

  return (
    <>
      {callFrom === "Residential" ? (
        <>
          <div className="col-12 mt-3">
            <span className="float-end">
              <material.Button
                type="button"
                variant="contained"
                onClick={() => appendResidential()}
              >
                Add Residential Details
              </material.Button>
            </span>
          </div>
          <div className="col-12 mt-2">
            {residentialField.map((field, index) => (
              <div
                key={field.id}
                className="row py-2 px-2 border border-3 border-primary rounded mt-2"
              >
                <div className="col-12">
                  <material.Button
                    type="button"
                    variant="contained"
                    // hidden={watch("numberOfLandUserInGovLand") == index - 1}
                    onClick={() => removeResidential(index)}
                    color="error"
                  >
                    Remove
                  </material.Button>
                </div>
                <div className="col-4 col-sm-12 col-md-6 col-lg-4">
                  <material.FormControl
                    size="small"
                    variant="standard"
                    className="w-100"
                  >
                    <material.InputLabel id="demo-select-small-label">
                      Type of Structure (Kutcha/Pucca)
                    </material.InputLabel>
                    <material.Select
                      name="typeOfStructure"
                      {...register(
                        `residentialDetails[${index}].typeOfStructure`,
                        {
                          required: true,
                        }
                      )}
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      // value={selectedRelation}
                      label="Select an option"
                      // onChange={onChangeRelationship}
                      required
                    >
                      {structureTypeList.map((ele, strind) => (
                        <material.MenuItem key={strind} value={ele.value}>
                          {ele.name}
                        </material.MenuItem>
                      ))}
                    </material.Select>
                  </material.FormControl>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <material.TextField
                    name="areaAquireForTheStructure"
                    {...register(
                      `residentialDetails[${index}].areaAquireForTheStructure`,
                      {
                        required: true,
                      }
                    )}
                    label="Approximate 
                    Built-up Area of the structure (sq mtr)
                    "
                    id="outlined-size-small"
                    size="small"
                    type="text"
                    fullWidth
                    variant="standard"
                    className="w-100"
                    required
                  />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <material.TextField
                    name="noOfStoreys"
                    {...register(`residentialDetails[${index}].noOfStoreys`, {
                      required: true,
                    })}
                    label="No. of Storey"
                    id="outlined-size-small"
                    size="small"
                    type="text"
                    fullWidth
                    variant="standard"
                    className="w-100"
                    required
                  />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <material.TextField
                    name="yearOfConstruction"
                    {...register(
                      `residentialDetails[${index}].yearOfConstruction`,
                      {
                        required: true,
                      }
                    )}
                    label="Year Of Construction"
                    id="outlined-size-small"
                    size="small"
                    type="text"
                    fullWidth
                    variant="standard"
                    className="w-100"
                    required
                  />
                </div>
                <div className="col-4 col-sm-12 col-md-6 col-lg-4">
                  <material.FormControl
                    size="small"
                    variant="standard"
                    className="w-100"
                  >
                    <material.InputLabel id="demo-select-small-label">
                      Current condition (code)
                    </material.InputLabel>
                    <material.Select
                      name="currentCondition"
                      {...register(
                        `residentialDetails[${index}].currentCondition`,
                        {
                          required: true,
                        }
                      )}
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      // value={selectedRelation}
                      label="Select an option"
                      // onChange={onChangeRelationship}
                      required
                    >
                      {structureTypeList.map((ele, strind) => (
                        <material.MenuItem key={strind} value={ele.value}>
                          {ele.name}
                        </material.MenuItem>
                      ))}
                    </material.Select>
                  </material.FormControl>
                </div>
                <div className="col-4 col-md-6 col-sm-12 d-flex flex-row align-items-center mt-3">
                  <label
                    id="demo-row-radio-buttons-group-label"
                    className="me-2"
                  >
                    Is the structure user different from owner?
                  </label>
                  <Controller
                    name={`residentialDetails[${index}].isStructureUserDifferentFromOwner`}
                    control={control}
                    defaultValue=""
                    rules={{ required: "Please select an option" }}
                    render={({ field }) => (
                      <material.RadioGroup
                        row
                        aria-label="selectedOption"
                        {...field}
                      >
                        <material.FormControlLabel
                          value="true"
                          control={<material.Radio />}
                          label="Yes"
                        />
                        <material.FormControlLabel
                          value="false"
                          control={<material.Radio />}
                          label="No"
                        />
                      </material.RadioGroup>
                    )}
                  />
                </div>
                {watch(
                  `residentialDetails[${index}].isStructureUserDifferentFromOwner`
                ) === "true" && (
                  <>
                    <div className="col-sm-12 col-md-6 col-lg-4">
                      <material.TextField
                        name="nameOfUser"
                        {...register(
                          `residentialDetails[${index}].nameOfUser`,
                          {
                            required: true,
                          }
                        )}
                        label="Year Of Construction"
                        id="outlined-size-small"
                        size="small"
                        type="text"
                        fullWidth
                        variant="standard"
                        className="w-100"
                        required
                      />
                    </div>
                  </>
                )}
                {/* <div className="col-sm-12 col-md-6 col-lg-4">
                  <material.TextField
                    name="totalWorkersEmployed"
                    {...register(
                      `residentialDetails[${index}].totalWorkersEmployed`,
                      {
                        required: true,
                      }
                    )}
                    label="Total Workers Employed, if any
                        (If commercial Structure)
                        "
                    id="outlined-size-small"
                    size="small"
                    type="text"
                    fullWidth
                    variant="standard"
                    className="w-100"
                    required
                  />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <material.TextField
                    name="averageWageRateOfWorkers"
                    {...register(
                      `residentialDetails[${index}].averageWageRateOfWorkers`,
                      {
                        required: true,
                      }
                    )}
                    label="Average wage rate of workers"
                    id="outlined-size-small"
                    size="small"
                    type="text"
                    fullWidth
                    variant="standard"
                    className="w-100"
                    required
                  />
                </div> 
                
                */}
                <div className="col-4 col-md-6 col-sm-12 d-flex flex-row align-items-center mt-3">
                  <label
                    id="demo-row-radio-buttons-group-label"
                    className="me-2"
                  >
                    If Religious Structure, Consent given?
                  </label>
                  <Controller
                    name={`residentialDetails[${index}].religiousStructureConsentGiven`}
                    control={control}
                    defaultValue=""
                    rules={{ required: "Please select an option" }}
                    render={({ field }) => (
                      <material.RadioGroup
                        row
                        aria-label="selectedOption"
                        {...field}
                      >
                        <material.FormControlLabel
                          value="true"
                          control={<material.Radio />}
                          label="Yes"
                        />
                        <material.FormControlLabel
                          value="false"
                          control={<material.Radio />}
                          label="No"
                        />
                      </material.RadioGroup>
                    )}
                  />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <material.TextField
                    name="minimumRequirementOfRelocation"
                    {...register(
                      `residentialDetails[${index}].minimumRequirementOfRelocation`,
                      {
                        required: true,
                      }
                    )}
                    label="Minimum Requirement of Relocation"
                    id="outlined-size-small"
                    size="small"
                    type="text"
                    fullWidth
                    variant="standard"
                    className="w-100"
                    required
                  />
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <>
          <div className="col-12 mt-3">
            <span className="float-end">
              <material.Button
                type="button"
                variant="contained"
                onClick={() => appendCommercial()}
              >
                Add Commercial Details
              </material.Button>
            </span>
          </div>
          <div className="col-12 mt-2">
            {commercialField.map((field, index) => (
              <div
                key={field.id}
                className="row py-2 px-2 border border-3 border-primary rounded mt-2"
              >
                <div className="col-12">
                  <material.Button
                    type="button"
                    variant="contained"
                    // hidden={watch("numberOfLandUserInGovLand") == index - 1}
                    onClick={() => removeCommercial(index)}
                    color="error"
                  >
                    Remove
                  </material.Button>
                </div>
                <div className="col-4 col-sm-12 col-md-6 col-lg-4">
                  <material.FormControl
                    size="small"
                    variant="standard"
                    className="w-100"
                  >
                    <material.InputLabel id="demo-select-small-label">
                      Type of Structure (Kutcha/Pucca)
                    </material.InputLabel>
                    <material.Select
                      name="typeOfStructure"
                      {...register(
                        `commercialDetails[${index}].typeOfStructure`,
                        {
                          required: true,
                        }
                      )}
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      // value={selectedRelation}
                      label="Select an option"
                      // onChange={onChangeRelationship}
                      required
                    >
                      {structureTypeList.map((ele, strind) => (
                        <material.MenuItem key={strind} value={ele.value}>
                          {ele.name}
                        </material.MenuItem>
                      ))}
                    </material.Select>
                  </material.FormControl>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <material.TextField
                    name="areaAquireForTheStructure"
                    {...register(
                      `commercialDetails[${index}].areaAquireForTheStructure`,
                      {
                        required: true,
                      }
                    )}
                    label="Approximate 
                    Built-up Area of the structure (sq mtr)
                    "
                    id="outlined-size-small"
                    size="small"
                    type="text"
                    fullWidth
                    variant="standard"
                    className="w-100"
                    required
                  />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <material.TextField
                    name="noOfStoreys"
                    {...register(`commercialDetails[${index}].noOfStoreys`, {
                      required: true,
                    })}
                    label="No. of Storey"
                    id="outlined-size-small"
                    size="small"
                    type="text"
                    fullWidth
                    variant="standard"
                    className="w-100"
                    required
                  />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <material.TextField
                    name="yearOfConstruction"
                    {...register(
                      `commercialDetails[${index}].yearOfConstruction`,
                      {
                        required: true,
                      }
                    )}
                    label="Year Of Construction"
                    id="outlined-size-small"
                    size="small"
                    type="text"
                    fullWidth
                    variant="standard"
                    className="w-100"
                    required
                  />
                </div>
                <div className="col-4 col-sm-12 col-md-6 col-lg-4">
                  <material.FormControl
                    size="small"
                    variant="standard"
                    className="w-100"
                  >
                    <material.InputLabel id="demo-select-small-label">
                      Current condition (code)
                    </material.InputLabel>
                    <material.Select
                      name="currentCondition"
                      {...register(
                        `commercialDetails[${index}].currentCondition`,
                        {
                          required: true,
                        }
                      )}
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      // value={selectedRelation}
                      label="Select an option"
                      // onChange={onChangeRelationship}
                      required
                    >
                      {structureTypeList.map((ele, strind) => (
                        <material.MenuItem key={strind} value={ele.value}>
                          {ele.name}
                        </material.MenuItem>
                      ))}
                    </material.Select>
                  </material.FormControl>
                </div>
                <div className="col-4 col-md-6 col-sm-12 d-flex flex-row align-items-center mt-3">
                  <label
                    id="demo-row-radio-buttons-group-label"
                    className="me-2"
                  >
                    Is the structure user different from owner?
                  </label>
                  <Controller
                    name={`commercialDetails[${index}].isStructureUserDifferentFromOwner`}
                    control={control}
                    defaultValue=""
                    rules={{ required: "Please select an option" }}
                    render={({ field }) => (
                      <material.RadioGroup
                        row
                        aria-label="selectedOption"
                        {...field}
                      >
                        <material.FormControlLabel
                          value="true"
                          control={<material.Radio />}
                          label="Yes"
                        />
                        <material.FormControlLabel
                          value="false"
                          control={<material.Radio />}
                          label="No"
                        />
                      </material.RadioGroup>
                    )}
                  />
                </div>
                {watch(
                  `commercialDetails[${index}].isStructureUserDifferentFromOwner`
                ) === "true" && (
                  <>
                    <div className="col-sm-12 col-md-6 col-lg-4">
                      <material.TextField
                        name="nameOfUser"
                        {...register(
                          `commercialDetails[${index}].nameOfUser`,
                          {
                            required: true,
                          }
                        )}
                        label="Year Of Construction"
                        id="outlined-size-small"
                        size="small"
                        type="text"
                        fullWidth
                        variant="standard"
                        className="w-100"
                        required
                      />
                    </div>
                  </>
                )}
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <material.TextField
                    name="totalWorkersEmployed"
                    {...register(
                      `commercialDetails[${index}].totalWorkersEmployed`,
                      {
                        required: true,
                      }
                    )}
                    label="Total Workers Employed, if any
                        (If commercial Structure)
                        "
                    id="outlined-size-small"
                    size="small"
                    type="text"
                    fullWidth
                    variant="standard"
                    className="w-100"
                    required
                  />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <material.TextField
                    name="averageWageRateOfWorkers"
                    {...register(
                      `commercialDetails[${index}].averageWageRateOfWorkers`,
                      {
                        required: true,
                      }
                    )}
                    label="Average wage rate of workers"
                    id="outlined-size-small"
                    size="small"
                    type="text"
                    fullWidth
                    variant="standard"
                    className="w-100"
                    required
                  />
                </div> 
                <div className="col-4 col-md-6 col-sm-12 d-flex flex-row align-items-center mt-3">
                  <label
                    id="demo-row-radio-buttons-group-label"
                    className="me-2"
                  >
                    If Religious Structure, Consent given?
                  </label>
                  <Controller
                    name={`commercialDetails[${index}].religiousStructureConsentGiven`}
                    control={control}
                    defaultValue=""
                    rules={{ required: "Please select an option" }}
                    render={({ field }) => (
                      <material.RadioGroup
                        row
                        aria-label="selectedOption"
                        {...field}
                      >
                        <material.FormControlLabel
                          value="true"
                          control={<material.Radio />}
                          label="Yes"
                        />
                        <material.FormControlLabel
                          value="false"
                          control={<material.Radio />}
                          label="No"
                        />
                      </material.RadioGroup>
                    )}
                  />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <material.TextField
                    name="minimumRequirementOfRelocation"
                    {...register(
                      `residentialDetails[${index}].minimumRequirementOfRelocation`,
                      {
                        required: true,
                      }
                    )}
                    label="Minimum Requirement of Relocation"
                    id="outlined-size-small"
                    size="small"
                    type="text"
                    fullWidth
                    variant="standard"
                    className="w-100"
                    required
                  />
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
}

export default StructureForm;
