// import React, { useRef, useState } from "react";
// import jsPDF from "jspdf";
// import "jspdf-autotable";
// import { Chart } from "react-google-charts";
// import pdfIcon from "../../assets/map-image/pdfIcon.png";

// const Report = ({ reportData, SetRunLoader }) => {
//   const chartRefs = useRef([]);
//   const [isEnableButton, setIsEnableButton] = useState(true);
//   console.log(reportData);
//   const createPdfHeader = (layerName) => {
//     let docHeader = "";
//     if (layerName === "sw_latin") {
//       docHeader = "Surface Water Quality in AOI";
//     } else if (layerName === "noise Data") {
//       docHeader = "Ambient Noise Quality in AOI";
//     } else if (layerName === "aqdata") {
//       docHeader = "Ambient Air Quality in AOI";
//     } else {
//       layerName.toUpperCase();
//     }
//     return docHeader;
//   };

//   const handleDownload = async () => {
//     SetRunLoader(true);
//     const doc = new jsPDF("landscape", "mm", "a4");

//     // Add charts
//     for (let i = 0; i < chartRefs.current.length; i++) {
//       const chartCanvas = chartRefs.current[i];
//       const chartImage = chartCanvas.toDataURL("image/png", 1.0); // Set higher quality
//       const pageWidth = doc.internal.pageSize.getWidth();
//       const pageHeight = doc.internal.pageSize.getHeight();
//       const imgProps = doc.getImageProperties(chartImage);
//       const imgWidth = pageWidth;
//       const imgHeight = (imgProps.height * pageWidth) / imgProps.width;

//       // Center the image on the page
//       const x = 0;
//       const y = (pageHeight - imgHeight) / 2;
//       doc.addImage(chartImage, "PNG", x, y, imgWidth, imgHeight);
//       // if (i < chartRefs.current.length - 1) {
//       doc.addPage();
//       // }
//     }
//     // Generate the table
//     generateComplexTablePdf(doc, reportData);
//     SetRunLoader(false);
//     doc.save("Report.pdf");
//   };

//   const generateComplexTablePdf = (doc, jsonData) => {
//     const options = {
//       theme: "grid", // 'striped', 'grid' or 'plain'
//       headStyles: {
//         fillColor: [44, 62, 80],
//         textColor: [255, 255, 255],
//         fontStyle: "bold",
//         halign: "center",
//         lineWidth: 0.1, // Ensure the border width is set
//         lineColor: [255, 255, 255], // Black border color
//       },
//       bodyStyles: {
//         fillColor: [245, 245, 245],
//         textColor: [0, 0, 0],
//         fontStyle: "normal",
//         halign: "center",
//       },
//       alternateRowStyles: {
//         fillColor: [240, 240, 240],
//       },
//       tableLineColor: [0, 0, 0],
//       tableLineWidth: 0.1,
//     };
//     let fileName = "Spatial Data of";
//     jsonData.forEach((layer, i) => {
//       const { layerName, tableData, columnName } = layer;
//       if (i === 0) {
//         fileName = fileName + " " + layerName.toUpperCase();
//       } else {
//         fileName = fileName + "&" + layerName.toUpperCase();
//       }
//       const pageWidth = doc.internal.pageSize.getWidth();
//       const text = layerName.toUpperCase();
//       const textWidth = doc.getTextWidth(text);
//       const textX = (pageWidth - textWidth) / 2;
//       console.log(fileName);
//       doc.setFontSize(18);
//       doc.setTextColor("blue");
//       doc.text(createPdfHeader(layerName), textX, 10);

//       //const tableHeaders = tableData.length > 0 ? (Object.keys(tableData[0]).length >10?Object.keys(tableData[0]).filter((headerName,headerInd)=>headerInd<=9):Object.keys(tableData[0])): ["Message"];
//       let tableHeaders = [];
//       if (tableData.length > 0) {
//         if (layerName == "aqdata") {
//           tableHeaders = columnName.map((col) => col.header);
//         } else {
//           tableHeaders =
//             Object.keys(tableData[0]).length > 10
//               ? Object.keys(tableData[0]).filter(
//                   (headerName, headerInd) => headerInd <= 9
//                 )
//               : Object.keys(tableData[0]);
//         }
//       } else {
//         tableHeaders = ["Message"];
//       }

//       let tableValues = [];
//       if (tableData.length > 0) {
//         if (layerName == "aqdata") {
//           tableValues = tableData.map((row) =>
//             columnName.map((col) => row[col.dataKey])
//           );
//         } else {
//           tableValues = tableData.map((item) =>
//             Object.values(item).length > 10
//               ? Object.values(item).filter((val, valInd) => valInd <= 9)
//               : Object.values(item)
//           );
//         }
//       } else {
//         [{ Message: "No Data Found" }].map((item) => Object.values(item));
//       }

//       doc.autoTable({
//         head: [tableHeaders],
//         body: tableValues,
//         startY: 15,
//         theme: "grid",
//         // headStyles: {
//         //   fillColor: [44, 62, 80],
//         //   textColor: [255, 255, 255],
//         //   fontStyle: 'bold',
//         //   halign: 'center',
//         //   valign: 'middle'
//         // },
//         // bodyStyles: {
//         //   halign: 'center',
//         //   valign: 'middle'
//         // },
//         margin: { top: 2, left: 2, right: 2, bottom: 2 },
//         ...options,
//       });
//     });
//     return doc;
//   };

//   return (
//     <div>
//       <material.Button
//         variant="contained"
//         color="success"
//         sx={{ textTransform: "none", mb: 2, mt: 2 }}
//         startIcon={
//           <img src={pdfIcon} alt="" style={{ width: 25, height: 25 }} />
//         }
//         //   onClick={() => downloadPdfDoc(openInfo.spatilQueryInfo)}
//         onClick={handleDownload}
//         disabled={isEnableButton}
//       >
//         Download Pdf
//       </material.Button>
//       <div style={{ display: "none" }}>
//         {reportData?.map((data, index, allData) =>
//           data.charts.map((chart, chartIndex, allCharts) => (
//             <div key={`${index}-${chartIndex}`}>
//               <Chart
//                 width={"300px"}
//                 height={"200px"}
//                 chartType={chart.chartType}
//                 data={chart.chartData}
//                 options={chart.chartOptions}
//                 chartEvents={[
//                   {
//                     eventName: "ready",
//                     callback: ({ chartWrapper }) => {
//                       const chartElement = chartWrapper
//                         .getContainer()
//                         .querySelector("svg");
//                       const canvas = document.createElement("canvas");
//                       canvas.width = 1600;
//                       canvas.height = 1200;
//                       const ctx = canvas.getContext("2d");
//                       const svgString = new XMLSerializer().serializeToString(
//                         chartElement
//                       );
//                       const DOMURL = window.URL || window.webkitURL || window;
//                       const img = new Image();
//                       const svg = new Blob([svgString], {
//                         type: "image/svg+xml;charset=utf-8",
//                       });
//                       const url = DOMURL.createObjectURL(svg);
//                       img.onload = () => {
//                         ctx.drawImage(img, 0, 0, 1600, 1200);
//                         DOMURL.revokeObjectURL(url);
//                         chartRefs.current.push(canvas);
//                         if (allData[allData.length - 1].charts.length > 0) {
//                           if (
//                             allData.length - 1 === index &&
//                             chartIndex === allCharts.length - 1
//                           ) {
//                             setIsEnableButton(false);
//                           }
//                         } else {
//                           setIsEnableButton(false);
//                         }
//                       };
//                       img.src = url;
//                     },
//                   },
//                 ]}
//               />
//             </div>
//           ))
//         )}
//       </div>
//     </div>
//   );
// };

// ........................Button
// import React, { useRef, useState } from "react";
// import jsPDF from "jspdf";
// import "jspdf-autotable";
// import { Chart } from "react-google-charts";

// const Report = ({ reportData, SetRunLoader }) => {
//   console.log(reportData)
//   const chartRefs = useRef([]);
//   const [isEnableButton, setIsEnableButton] = useState(true);
//   const createPdfHeader = (layerName) => {
//     let docHeader = "";
//     switch (layerName.toLowerCase()) {
//       case "sw_latin":
//         docHeader = "Surface Water Quality in AOI";
//         break;
//       case "noise data":
//         docHeader = "Ambient Noise Quality in AOI";
//         break;
//       case "aqdata":
//         docHeader = "Ambient Air Quality in AOI";
//         break;
//       default:
//         docHeader = layerName.toUpperCase();
//         break;
//     }
//     return docHeader;
//   };

//   const handleDownload = async () => {
//     const doc = new jsPDF("landscape", "mm", "a4");

//     for (let i = 0; i < reportData.length; i++) {
//       const data = reportData[i];
//       const header = createPdfHeader(data.layerName);
//       doc.setFontSize(18);
//       doc.setTextColor("blue");
//       doc.setFont('', '','bold')

//       if (data.charts && data.charts.length > 0) {
//         for (let j = 0; j < data.charts.length; j++) {
//           const chartCanvas = chartRefs.current[i * data.charts.length + j];
//           if (chartCanvas) {
//             const chartImage = chartCanvas.toDataURL("image/png", 1.0);
//             doc.addPage();
//             doc.text(header, doc.internal.pageSize.getWidth() / 2, 10, {
//               align: "center",
//             });
//             doc.addImage(chartImage, "PNG", 10, 20, 270, 150); // Increased size and added margin
//           }
//         }
//         doc.addPage();
//         doc.text(header, doc.internal.pageSize.getWidth() / 2, 10, {
//           align: "center",
//         });
//         addTableToPdf(doc, data, 20);
//       } else {
//         doc.addPage();
//         doc.text(header, doc.internal.pageSize.getWidth() / 2, 10, {
//           align: "center",
//         });
//         addTableToPdf(doc, data, 20);
//       }
//     }

//     doc.save("Complex_Table_with_Charts.pdf");
//   };

//   const addTableToPdf = (doc, data, yPos) => {
//     const options = {
//       theme: "grid",
//       headStyles: {
//         fillColor: [44, 62, 80],
//         textColor: [255, 255, 255],
//         fontStyle: "bold",
//         halign: "center",
//         lineWidth: 0.1,
//         lineColor: [255, 255, 255],
//       },
//       bodyStyles: {
//         fillColor: [245, 245, 245],
//         textColor: [0, 0, 0],
//         fontStyle: "normal",
//         halign: "center",
//       },
//       alternateRowStyles: {
//         fillColor: [240, 240, 240],
//       },
//       tableLineColor: [0, 0, 0],
//       tableLineWidth: 0.1,
//     };

//     const { layerName, tableData, columnName } = data;

//     let tableHeaders = [];
//     if (tableData.length > 0) {
//       tableHeaders = columnName.map((col) => col.header);
//     } else {
//       tableHeaders = ["Message"];
//     }

//     let tableValues = [];
//     if (tableData.length > 0) {
//       tableValues = tableData.map((row) =>
//         columnName.map((col) => row[col.dataKey])
//       );
//     } else {
//       tableValues = [["No Data Found"]];
//     }

//     doc.autoTable({
//       head: [tableHeaders],
//       body: tableValues,
//       startY: yPos + 15,
//       theme: "grid",
//       margin: { top: 2, left: 2, right: 2, bottom: 2 },
//       ...options,
//     });
//   };

//   const enableButton = (arr, i) => {
//     if (arr.length - 1 === i) {
//       setIsEnableButton(false);
//     }
//     return null;
//   };

//   return (
//     <div>
//       <material.LoadingButton
//         variant="contained"
//         color="success"
//         sx={{ textTransform: "none", mb: 2, mt: 2 }}
//         startIcon={
//           // <img src={pdfIcon} alt="" style={{ width: 25, height: 25 }} />
//           <material.FileDownloadTwoToneIcon/>
//         }
//         onClick={handleDownload}
//         // disabled={isEnableButton}
//         loadingPosition="end"
//         loading={isEnableButton}
//         // loadingIndicator="Loading…"
//       >
//        <span>Download</span>
//       </material.LoadingButton>
//       <div style={{ display: "none" }}>
//         {reportData?.map((data, index, arr) =>
//           data.charts && data.charts.length > 0 ? (
//             data.charts.map((chart, chartIndex, carr) => (
//               <div key={`${index}-${chartIndex}`}>
//                 <Chart
//                   width={"600px"} // Increased width
//                   height={"400px"} // Increased height
//                   chartType={chart.chartType}
//                   loader={<div>Loading Chart</div>}
//                   data={chart.chartData}
//                   options={chart.chartOptions}
//                   chartEvents={[
//                     {
//                       eventName: "ready",
//                       callback: ({ chartWrapper }) => {
//                         const chartElement = chartWrapper
//                           .getContainer()
//                           .querySelector("svg");
//                         const canvas = document.createElement("canvas");
//                         canvas.width = 1600;
//                         canvas.height = 800;
//                         const ctx = canvas.getContext("2d");
//                         const svgString = new XMLSerializer().serializeToString(
//                           chartElement
//                         );
//                         console.log(svgString)
//                         const DOMURL = window.URL || window.webkitURL || window;
//                         const img = new Image();
//                         const svg = new Blob([svgString], {
//                           type: "image/svg+xml;charset=utf-8",
//                         });
//                         const url = DOMURL.createObjectURL(svg);
//                         img.onload = () => {
//                           ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
//                           DOMURL.revokeObjectURL(url);
//                           chartRefs.current.push(canvas);
//                           if (arr.length - 1 === index) {
//                             if (carr.length - 1 === chartIndex) {
//                               setIsEnableButton(false);
//                             }
//                           }
//                         };
//                         img.src = url;
//                       },
//                     },
//                   ]}
//                 />
//               </div>
//             ))
//           ) : (
//             <>{enableButton(arr, index)}</>
//           )
//         )}
//       </div>
//     </div>
//   );
// };

// export default Report;

//................................................
import { material } from "../../library/material";
import React, { useRef } from "react";
// import React, { useRef, useState, useEffect } from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Chart } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  Title,
  PieController,
} from "chart.js";
import { mergeColumnAndChangeDataStructure } from "../../utils/generateReportDataFormat";

ChartJS.register(
  ArcElement,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  Title,
  PieController
);

const Report = ({ reportData, mapImage }) => {
  const chartRefs = useRef([]);

  // useEffect(()=>{

  // },[reportData])
  const createPdfHeader = (layerName) => {
    let docHeader = "";
    switch (layerName.toLowerCase()) {
      case "sw_latin":
        docHeader = "Surface Water Quality in AOI";
        break;
      case "noise data":
        docHeader = "Ambient Noise Quality in AOI";
        break;
      case "aqdata":
        docHeader = "Ambient Air Quality in AOI";
        break;
      default:
        docHeader = layerName.toUpperCase();
        break;
    }
    return docHeader;
  };

  const addPageBorders = (doc, margin, headerHeight) => {
    // Add page border
    doc.setLineWidth(0.5);
    doc.rect(
      margin,
      margin,
      doc.internal.pageSize.getWidth() - 2 * margin,
      doc.internal.pageSize.getHeight() - 2 * margin
    );

    // Add header border
    doc.setLineWidth(0.2);
    doc.rect(
      margin,
      margin,
      doc.internal.pageSize.getWidth() - 2 * margin,
      headerHeight
    );
  };

  const addHeader = (doc, header, margin, headerHeight) => {
    doc.setFillColor("black"); // Set header background color
    doc.rect(
      margin,
      margin,
      doc.internal.pageSize.getWidth() - 2 * margin,
      headerHeight,
      "F"
    ); // Draw header background
    doc.setFontSize(18);
    doc.setTextColor("#09dede");
    doc.setFont("Poppins", "bold");
    doc.text(
      header,
      doc.internal.pageSize.getWidth() / 2,
      margin + headerHeight / 2,
      { align: "center" }
    );
  };

  const handleDownload = async () => {
    // Register the font
    const doc = new jsPDF("landscape", "mm", "a4");
    const margin = 10;
    const headerHeight = 20;
    const chartHeight = 80; // Adjust height to fit 4 charts per page
    const chartWidth =
      (doc.internal.pageSize.getWidth() - 2 * margin - 10) / 2 - 5; // Two charts per row

    // Define transparency for watermark
    const transparency = {
      opacity: 0.1,
    };

    for (let i = 0; i < reportData.length; i++) {
      let data = reportData[i];
      console.log(data);
      data = mergeColumnAndChangeDataStructure(data);
      // FOR WATERMARK
      doc.setFontSize(40);
      doc.setTextColor(150);
      doc.setGState(new doc.GState(transparency)); // Set transparency
      doc.text(
        "REPORT",
        doc.internal.pageSize.getWidth() / 2,
        doc.internal.pageSize.getHeight() / 2,
        { align: "center", angle: 45 }
      );
      doc.setGState(new doc.GState({ opacity: 1 })); // Reset transparency
      // Create header
      const header = createPdfHeader(data.layerName);

      if (i === 0) {
        const mapImageData = mapImage.toDataURL("image/png", 1.0);
        // const imageWidth = doc.internal.pageSize.getWidth() - 2 * margin;
        // const imageHeight = doc.internal.pageSize.getHeight() - 2 * margin;
        // const xPos = margin + (doc.internal.pageSize.getWidth() - imageWidth) / 2;
        // const yPos = margin + (doc.internal.pageSize.getHeight() - imageHeight) / 2;
        doc.addImage(mapImageData, "PNG", 0, 0);
      }

      if (data.reportCharts && data.reportCharts.length > 0) {
        let chartIndex = 0;
        while (chartIndex < data.reportCharts.length) {
          doc.addPage();
          addPageBorders(doc, margin, headerHeight);
          addHeader(doc, header, margin, headerHeight);

          for (let row = 0; row < 2; row++) {
            for (let col = 0; col < 2; col++) {
              if (chartIndex >= data.reportCharts.length) break;
              const chartRef = chartRefs.current[i][chartIndex];
              if (chartRef && chartRef.canvas) {
                const chartCanvas = chartRef.canvas;
                const chartImage = chartCanvas.toDataURL("image/png", 1.0);
                doc.addImage(
                  chartImage,
                  "PNG",
                  margin + 5 + col * (chartWidth + 5),
                  margin + headerHeight + 5 + row * (chartHeight + 5),
                  chartWidth,
                  chartHeight
                );
              }
              chartIndex++;
            }
          }
        }
        doc.addPage();
        addHeader(doc, header, margin, headerHeight);
        addTableToPdf(doc, data, margin + headerHeight + 5);
      } else {
        doc.addPage();
        addHeader(doc, header, margin, headerHeight);
        addTableToPdf(doc, data, margin + headerHeight + 5);
      }
    }

    doc.save("Report.pdf");
  };

  const addTableToPdf = (doc, data, yPos) => {
    const options = {
      theme: "grid",
      headStyles: {
        fillColor: [44, 62, 80],
        textColor: [255, 255, 255],
        fontStyle: "bold",
        halign: "center",
        lineWidth: 0.1,
        lineColor: [255, 255, 255],
      },
      bodyStyles: {
        fillColor: [245, 245, 245],
        textColor: [0, 0, 0],
        fontStyle: "normal",
        halign: "center",
      },
      alternateRowStyles: {
        fillColor: [240, 240, 240],
      },
      tableLineColor: [0, 0, 0],
      tableLineWidth: 0.4,
    };

    const { layerName, tableData, columnName } = data;
    if (layerName !== "east_medinipur_Census_2011") {
      let tableHeaders = [];
      if (tableData.length > 0) {
        tableHeaders = columnName.map((col) => col.header);
      } else {
        tableHeaders = ["Message"];
      }

      let tableValues = [];
      if (tableData.length > 0) {
        tableValues = tableData.map((row) =>
          columnName.map((col) => row[col.dataKey])
        );
      } else {
        tableValues = [["No Data Found"]];
      }

      doc.autoTable({
        head: [tableHeaders],
        body: tableValues,
        startY: yPos,
        theme: "grid",
        margin: { top: 2.5, left: 10, right: 10, bottom: 2.5 }, // Reduced padding
        ...options,
      });
    } else {
      tableData.map((table) => {
        let tableHeaders = [];
        if (table?.data?.length > 0) {
          tableHeaders = table?.columnName?.map((col) => col.header);
        } else {
          tableHeaders = ["Message"];
        }

        let tableValues = [];
        if (table?.data?.length > 0) {
          tableValues = table?.data?.map((row) =>
            table?.columnName?.map((col) => row[col.dataKey])
          );
        } else {
          tableValues = [["No Data Found"]];
        }

        const columnHeadersRow = tableHeaders.map((header) => ({
          content: header,
          styles: {
            halign: "center",
            fillColor: [44, 62, 80],
            textColor: [255, 255, 255],
            fontStyle: "bold",
          },
        }));
        const mainHeaderRow = [
          {
            content: table.tableHeader,
            colSpan: tableHeaders.length,
            styles: {
              halign: "center",
              fillColor: [44, 62, 80],
              textColor: [255, 255, 255],
              fontStyle: "bold",
            },
          },
        ];
        // Combine main header with column headers and data rows
        const combinedHead = [mainHeaderRow, columnHeadersRow];
        const combinedBody = [...tableValues];
        doc.autoTable({
          head: combinedHead,
          body: combinedBody,
          startY: yPos,
          theme: "grid",
          margin: { top: 2.5, left: 10, right: 10, bottom: 2.5 }, // Reduced padding
          ...options,
        });
        yPos = doc.lastAutoTable.finalY + 5;
      });
    }
  };

  return (
    <div>
      <material.Button
        variant="contained"
        color="success"
        sx={{ textTransform: "none", mb: 0.5 }}
        startIcon={
          // <img src={pdfIcon} alt="" style={{ width: 25, height: 25 }} />
          <material.FileDownloadTwoToneIcon />
        }
        onClick={handleDownload}
      >
        Download Report
      </material.Button>
      <div style={{ position: "absolute", top: "-9999px", left: "-9999px" }}>
        {reportData?.map((data, index) =>
          data.reportCharts && data.reportCharts.length > 0
            ? data.reportCharts.map((chart, chartIndex) => (
                <div key={`${index}-${chartIndex}`}>
                  <Chart
                    width={600}
                    height={400}
                    type={"bar"}
                    data={chart.data}
                    options={chart.option}
                    ref={(reference) => {
                      if (!chartRefs.current[index]) {
                        chartRefs.current[index] = [];
                      }
                      chartRefs.current[index][chartIndex] = reference;
                    }}
                  />
                </div>
              ))
            : null
        )}
      </div>
    </div>
  );
};

export default Report;

// REPORT DOWNLOAD CODE FOR EXCEL AND PDF ALL IN THE BELOW
///////////\\\\\\\\\\\
//////////\\\\\\
////\\\\

// import React, { useRef } from "react";
// // import { material } from "../../library/material";
// import jsPDF from "jspdf";
// import "jspdf-autotable";
// import { Chart } from "react-chartjs-2";
// import {
//   Chart as ChartJS,
//   LinearScale,
//   CategoryScale,
//   BarElement,
//   PointElement,
//   LineElement,
//   Legend,
//   Tooltip,
//   LineController,
//   BarController,
//   Title,
// } from "chart.js";
// import { saveAs } from "file-saver";
// import html2canvas from "html2canvas";
// import ExcelJS from "exceljs";

// ChartJS.register(
//   LinearScale,
//   CategoryScale,
//   BarElement,
//   PointElement,
//   LineElement,
//   Legend,
//   Tooltip,
//   LineController,
//   BarController,
//   Title
// );

// const Report = ({ reportData }) => {
//   const chartRefs = useRef([]);

//   const createPdfHeader = (layerName) => {
//     let docHeader = "";
//     switch (layerName.toLowerCase()) {
//       case "sw_latin":
//         docHeader = "Surface Water Quality in AOI";
//         break;
//       case "noise data":
//         docHeader = "Ambient Noise Quality in AOI";
//         break;
//       case "aqdata":
//         docHeader = "Ambient Air Quality in AOI";
//         break;
//       default:
//         docHeader = layerName.toUpperCase();
//         break;
//     }
//     return docHeader;
//   };

//   const addPageBorders = (doc, margin, headerHeight) => {
//     doc.setLineWidth(0.5);
//     doc.rect(margin, margin, doc.internal.pageSize.getWidth() - 2 * margin, doc.internal.pageSize.getHeight() - 2 * margin);
//     doc.setLineWidth(0.2);
//     doc.rect(margin, margin, doc.internal.pageSize.getWidth() - 2 * margin, headerHeight);
//   };

//   const addHeader = (doc, header, margin, headerHeight) => {
//     doc.setFontSize(18);
//     doc.setTextColor("#0fa4af");
//     doc.setFont("popins", "bold");
//     doc.text(header, doc.internal.pageSize.getWidth() / 2, margin + headerHeight / 2, { align: "center" });
//   };

//   const handleDownloadPdf = async () => {
//     const doc = new jsPDF("landscape", "mm", "a4");
//     const margin = 5;
//     const headerHeight = 20;

//     for (let i = 0; i < reportData.length; i++) {
//       const data = reportData[i];

//       doc.setFontSize(40);
//       doc.setTextColor(150);
//       doc.text("REPORT", doc.internal.pageSize.getWidth() / 2, doc.internal.pageSize.getHeight() / 2, { align: "center", angle: 45 });

//       const header = createPdfHeader(data.layerName);

//       if (data.reportCharts && data.reportCharts.length > 0) {
//         for (let j = 0; j < data.reportCharts.length; j++) {
//           const chartRef = chartRefs.current[i][j];
//           if (chartRef && chartRef.canvas) {
//             const chartCanvas = chartRef.canvas;
//             const chartImage = chartCanvas.toDataURL("image/png", 1.0);
//             doc.addPage();
//             addPageBorders(doc, margin, headerHeight);
//             addHeader(doc, header, margin, headerHeight);
//             const chartHeight = 150;
//             doc.addImage(chartImage, "PNG", margin + 5, margin + headerHeight + 5, doc.internal.pageSize.getWidth() - 2 * margin - 10, chartHeight);
//           }
//         }
//         doc.addPage();
//         addPageBorders(doc, margin, headerHeight);
//         addHeader(doc, header, margin, headerHeight);
//         addTableToPdf(doc, data, margin + headerHeight + 5);
//       } else {
//         doc.addPage();
//         addPageBorders(doc, margin, headerHeight);
//         addHeader(doc, header, margin, headerHeight);
//         addTableToPdf(doc, data, margin + headerHeight + 5);
//       }
//     }

//     doc.save("Report.pdf");
//   };

//   const addTableToPdf = (doc, data, yPos) => {
//     const options = {
//       theme: "grid",
//       headStyles: {
//         fillColor: [44, 62, 80],
//         textColor: [255, 255, 255],
//         fontStyle: "bold",
//         halign: "center",
//         lineWidth: 0.1,
//         lineColor: [255, 255, 255],
//       },
//       bodyStyles: {
//         fillColor: [245, 245, 245],
//         textColor: [0, 0, 0],
//         fontStyle: "normal",
//         halign: "center",
//       },
//       alternateRowStyles: {
//         fillColor: [240, 240, 240],
//       },
//       tableLineColor: [0, 0, 0],
//       tableLineWidth: 0.4,
//     };

//     const { layerName, tableData, columnName } = data;

//     let tableHeaders = [];
//     if (tableData.length > 0) {
//       tableHeaders = columnName.map((col) => col.header);
//     } else {
//       tableHeaders = ["Message"];
//     }

//     let tableValues = [];
//     if (tableData.length > 0) {
//       tableValues = tableData.map((row) =>
//         columnName.map((col) => row[col.dataKey])
//       );
//     } else {
//       tableValues = [["No Data Found"]];
//     }

//     const pageWidth = doc.internal.pageSize.getWidth() - 2 * 3; // Reduced padding
//     const maxTableWidth = pageWidth - 6; // Account for left and right margins

//     let fontSize = 12; // Initial font size
//     doc.setFontSize(fontSize);

//     const calculateColumnWidths = () => {
//       return tableHeaders.map((header, index) => {
//         const headerWidth = header ? doc.getStringUnitWidth(header) * fontSize / doc.internal.scaleFactor : 0;
//         const maxColumnWidth = Math.max(
//           headerWidth,
//           ...tableValues.map((row) => {
//             const cellContent = row[index];
//             const cellWidth = cellContent ? doc.getStringUnitWidth(cellContent) * fontSize / doc.internal.scaleFactor : 0;
//             return cellWidth;
//           })
//         );
//         return maxColumnWidth;
//       });
//     };

//     let columnWidths = calculateColumnWidths();
//     let totalWidth = columnWidths.reduce((acc, width) => acc + width, 0);

//     while (totalWidth > maxTableWidth) {
//       if (fontSize > 8) {
//         fontSize -= 1;
//         doc.setFontSize(fontSize);
//         columnWidths = calculateColumnWidths();
//         totalWidth = columnWidths.reduce((acc, width) => acc + width, 0);
//       } else {
//         const scaleFactor = maxTableWidth / totalWidth;
//         columnWidths = columnWidths.map(width => width * scaleFactor);
//         totalWidth = columnWidths.reduce((acc, width) => acc + width, 0);
//         break;
//       }
//     }

//     doc.autoTable({
//       head: [tableHeaders],
//       body: tableValues,
//       startY: yPos + 15,
//       theme: "grid",
//       margin: { top: 2.5, left: 2.5, right: 2.5, bottom: 2.5 },
//       columnStyles: tableHeaders.reduce((acc, header, index) => {
//         acc[index] = { cellWidth: columnWidths[index] };
//         return acc;
//       }, {}),
//       ...options,
//     });
//   };

//   const handleDownloadExcel = async () => {
//     const workbook = new ExcelJS.Workbook();

//     for (const [index, data] of reportData.entries()) {
//       const { layerName, tableData, columnName } = data;
//       const worksheet = workbook.addWorksheet(createPdfHeader(layerName));

//       const worksheetData = tableData.length > 0
//         ? [
//             columnName.map(col => col.header),
//             ...tableData.map(row => columnName.map(col => row[col.dataKey])),
//           ]
//         : [["No Data Found"]];
//       worksheet.addRows(worksheetData);

//       if (data.reportCharts && data.reportCharts.length > 0) {
//         for (let chartIndex = 0; chartIndex < data.reportCharts.length; chartIndex++) {
//           const chartRef = chartRefs.current[index][chartIndex];
//           if (chartRef && chartRef.canvas) {
//             const chartCanvas = chartRef.canvas;
//             const chartImage = await html2canvas(chartCanvas).then(canvas => canvas.toDataURL("image/png"));
//             const imageId = workbook.addImage({
//               base64: chartImage,
//               extension: 'png',
//             });

//             worksheet.addImage(imageId, {
//               tl: { col: 0, row: worksheetData.length + chartIndex * 20 },
//               ext: { width: 500, height: 300 },
//             });
//           }
//         }
//       }
//     }

//     const buffer = await workbook.xlsx.writeBuffer();
//     saveAs(new Blob([buffer], { type: "application/octet-stream" }), "Report.xlsx");
//   };

//   return (
//     <div>
//       <material.Button
//         variant="contained"
//         color="success"
//         sx={{ textTransform: "none", mb: 2, mt: 2 }}
//         startIcon={<material.FileDownloadTwoToneIcon />}
//         onClick={handleDownloadPdf}
//       >
//         Download PDF Report
//       </material.Button>
//       <material.Button
//         variant="contained"
//         color="primary"
//         sx={{ textTransform: "none", mb: 2, mt: 2, ml: 2 }}
//         startIcon={<material.FileDownloadTwoToneIcon />}
//         onClick={handleDownloadExcel}
//       >
//         Download Excel Report
//       </material.Button>
//       <div style={{ position: "absolute", top: "-9999px", left: "-9999px" }}>
//         {reportData?.map((data, index) =>
//           data.reportCharts && data.reportCharts.length > 0
//             ? data.reportCharts.map((chart, chartIndex) => (
//                 <div key={`${index}-${chartIndex}`}>
//                   <Chart
//                     width={600}
//                     height={400}
//                     type={"bar"}
//                     data={chart.data}
//                     options={chart.option}
//                     ref={(reference) => {
//                       if (!chartRefs.current[index]) {
//                         chartRefs.current[index] = [];
//                       }
//                       chartRefs.current[index][chartIndex] = reference;
//                     }}
//                   />
//                 </div>
//               ))
//             : null
//         )}
//       </div>
//     </div>
//   );
// };

// export default Report;
