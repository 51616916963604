const chartParameterList = [
  {
    name: "ph @ 25 ??",
    xAxisTitle: "Monitoring Location",
    yAxisTitle: "pH",
    title: "Concentration of pH in surface water monitoring location",
  },
  {
    name: "dissolved",
    xAxisTitle: "Monitoring Location",
    yAxisTitle: "DO(mg/L)",
    title: "Concentration of DO in surface water monitoring location",
  },
  {
    name: "biochemica",
    xAxisTitle: "Monitoring Location",
    yAxisTitle: "BOD(mg/L)",
    title: "Biochemical Oxygen Demand ",
  },
  {
    name: "chemical o",
    xAxisTitle: "Monitoring Location",
    yAxisTitle: "COD(mg/L)",
    title: "Chemical Oxygen Demand ",
  },
  {
    name: "total diss",
    xAxisTitle: "Monitoring Location",
    yAxisTitle: "TDS(mg/L)",
    title: "Total Dissolved Solids(TDS)",
  },
  {
    name: "total coli",
    xAxisTitle: "Monitoring Location",
    yAxisTitle: "Total Coliforn (CFU/100ml)",
    title:
      "Concentration of Total Coliform in surface water monitoring location",
  },
];

// const data = [
//   ["Monitoring Station", "pH Value"],
//   ["s1", 1],
//   ["s2", 2],
//   ["s3", 3],
// ];
export const createChartDataForSurfaceWater = (rawData) => {
  return chartParameterList.map((pram, paramInd) => {
    let option = {
      title: pram.title,
      chartArea: { width: "80%" },
      hAxis: {
        title: pram.xAxisTitle,
        minValue: 0,
      },
      vAxis: {
        title: pram.yAxisTitle,
      },
      legend: { position: "top" },
    };
    let data = rawData.map((ele, ind) => {
      return [ele["sw stn no."],Number(ele[pram.name])];
    });
    let chartData = [[pram.xAxisTitle, pram.yAxisTitle], ...data];
    return {
      chartData: chartData,
      chartOptions: option,
      chartType: "ColumnChart",
    };
  });
};

export const generateReportChartDataForSurfaceWater=(rawData)=>{
  return chartParameterList.map((pram, paramInd) => {
    let option= {
        responsive: true,
        plugins: {
          legend: {
            position: 'bottom',
          },
          title: {
            display: true,
            text: pram.title,
          },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: pram.xAxisTitle,
            },
          },
          y: {
            title: {
              display: true,
              text: pram.yAxisTitle,
            },
          },
        },
      };
    
    const data = {
      labels: rawData.map((ele, ind) => {
        return ele["sw stn no."]
      }),
      datasets: [
        {
          type:'bar',
          label:pram.yAxisTitle,
          backgroundColor: ['rgba(255, 99, 132, 4)'],
          borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)'],
          borderWidth: 1,
          hoverBackgroundColor: ['rgba(255, 99, 132, 0.4)'],
          hoverBorderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)'],
          data: rawData.map((ele, ind) => {
            return Number(ele[pram.name])
          })
        },
      ]
    }
    return {option,data}
  })
}
