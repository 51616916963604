import { Geolocation } from 'ol';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Icon, Style } from 'ol/style';

let geolocation = null; // Keep a reference to the geolocation object
let vectorLayerForCurrentLocation = null; // Keep a reference to the vector layer

export const placeCurrentLocation = (map) => {
    // Cleanup previous geolocation and vector layer if they exist
    if (geolocation) {
        geolocation.setTracking(false);
    }
    if (vectorLayerForCurrentLocation) {
        map.removeLayer(vectorLayerForCurrentLocation);
    }

    // Create new geolocation instance
    geolocation = new Geolocation({
        trackingOptions: {
            enableHighAccuracy: true,
        },
        projection: map.getView().getProjection(),
    });
    geolocation.setTracking(true);

    // Icon for current location
    const iconFeature = new Feature();
    const iconStyle = new Style({
        image: new Icon({
            anchor: [0.5, 46],
            anchorXUnits: 'fraction',
            anchorYUnits: 'pixels',
            src: 'https://openlayers.org/en/latest/examples/data/icon.png',
        }),
    });
    iconFeature.setStyle(iconStyle);

    // Vector layer for current location
    const vectorSource = new VectorSource({
        features: [iconFeature],
    });
    vectorLayerForCurrentLocation = new VectorLayer({
        name: 'CurrentLocation',
        source: vectorSource,
    });

    map.addLayer(vectorLayerForCurrentLocation);

    geolocation.on('change', () => {
        const coordinates = geolocation.getPosition();
        iconFeature.setGeometry(coordinates ? new Point(coordinates) : null);
        map.getView().animate({ center: coordinates, zoom: 15 });
    });

    // Return cleanup function
    return () => {
        if (geolocation) {
            geolocation.setTracking(false);
        }
        if (vectorLayerForCurrentLocation) {
            map.removeLayer(vectorLayerForCurrentLocation);
        }
    };
}


