import { rooApiUrl } from "../environment/Environment";
import { transform } from 'ol/proj';
import {
  deleteFeature,
  getGeometryType,
} from "../services/LayerModificationService";

export const fetchGeometryType = async (layerName) => {
  try {
    const res = await getGeometryType(layerName);
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(res.data, "text/xml");

    // Retrieve all elements that describe properties
    const elements = xmlDoc.querySelectorAll("element");

    // XSD to JavaScript type mapping
    const typeMapping = {
      "xsd:string": "string",
      "xsd:int": "number",
      "xsd:integer": "number",
      "xsd:decimal": "number",
      "xsd:double": "number",
      "xsd:float": "number",
      "xsd:boolean": "boolean",
      "xsd:date": "date",
      "xsd:dateTime": "string",
      // Add more mappings as needed
    };

    // Array to store property names and types
    const properties = [];
    let geometryType = null; // Variable to store the geometry type

    // Iterate over each element and extract property name and type
    elements.forEach((element) => {
      const name = element.getAttribute("name");
      const xsdType = element.getAttribute("type");

      if (name && xsdType) {
        const jsType = typeMapping[xsdType] || "unknown"; // Fallback to 'unknown' if not mapped
        properties.push({ name: name, dataType: jsType });

        // Check if the current element is the geometry field
        if (name === "geom") {
          if (xsdType.includes("Point")) {
            geometryType = "Point";
          } else if (xsdType.includes("Polygon")) {
            geometryType = "Polygon";
          } else if (xsdType.includes("LineString")) {
            geometryType = "LineString";
          }
        }
      }
    });

    // Log or return the result

    const result = {
      properties: properties.slice(0, -2),
      geometryType,
    };

    console.log(result);
    return result;
  } catch (error) {
    console.error("Error fetching geometry type:", error);
    return {
      properties: [],
      geometryType: null,
    };
  }
};

export const wfsTransactionXml = (
  geojsonFeature,
  properties,
  layerName,
  layerType
) => {
  layerName = replaceSpacesWithEncodedValue(layerName);
  let gmlGeometry = geojsonFeature;
  console.log(geojsonFeature);
  console.log(layerType);
  //console.log(properties);
  const propertiesXML = Object.entries(properties)
    .map(([key, value]) => {
      return `<haldia:${replaceSpacesWithEncodedValue(key)}>${value}</haldia:${key}>`;
    })
    .join("");

  let wfsTransaction;
  if (layerType == "Point") {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(geojsonFeature, "application/xml");
    // Example: Extracting coordinates from a Point element in the XML
    const pointElement = xmlDoc.getElementsByTagName("Point")[0];
    const coordinates = pointElement
      .getElementsByTagName("pos")[0]
      .textContent.split(" ")
      .map((ele) => Number(ele));

    // Transform the coordinates to EPSG:4326
    const transformedCoords = transform(coordinates, "EPSG:3857", "EPSG:4326");
    const [lon, lat] = transformedCoords
    wfsTransaction = `
    <wfs:Transaction  xmlns:wfs="http://www.opengis.net/wfs"
    xmlns:haldia="www.haldia.com"
    xmlns:ogc="http://www.opengis.net/ogc"
    xmlns:gml="http://www.opengis.net/gml"
    xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
    xsi:schemaLocation="http://www.opengis.net/wfs http://schemas.opengis.net/wfs/1.1.0/wfs.xsd
                        http://www.opengis.net/gml http://schemas.opengis.net/gml/3.1.1/gml.xsd">
      <wfs:Insert>
        <haldia:${layerName}
          >
          <haldia:geom>
             <gml:Point xmlns:gml="http://www.opengis.net/gml" srsName="EPSG:3857">
             <gml:pos>${coordinates[0]} ${coordinates[1]}</gml:pos>
             </gml:Point>
          </haldia:geom>
          ${propertiesXML}
        </haldia:${layerName}>
      </wfs:Insert>
    </wfs:Transaction>
  `;
  } else if (layerType == "Polygon") {
    wfsTransaction = `
    <wfs:Transaction  xmlns:wfs="http://www.opengis.net/wfs"
    xmlns:haldia="www.haldia.com"
    xmlns:ogc="http://www.opengis.net/ogc"
    xmlns:gml="http://www.opengis.net/gml"
    xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
    xsi:schemaLocation="http://www.opengis.net/wfs http://schemas.opengis.net/wfs/1.1.0/wfs.xsd
                        http://www.opengis.net/gml http://schemas.opengis.net/gml/3.1.1/gml.xsd">
      <wfs:Insert>
        <haldia:${layerName}
            xmlns:gml="http://www.opengis.net/gml"
            gml:gid="">
          <haldia:geom>
            ${gmlGeometry}
          </haldia:geom>
          ${propertiesXML}
        </haldia:${layerName}>
      </wfs:Insert>
    </wfs:Transaction>`;
  }

  return wfsTransaction;
};

export const deleteSelectedFeature = (featureId, layerName, map) => {
  if (featureId !== null) {
    layerName = replaceSpacesWithEncodedValue(layerName);
    let wfsTransaction =
      `<wfs:Transaction service="WFS" version="1.0.0"\n` +
      `xmlns:cdf="http://www.opengis.net/cite/data"\n` +
      `xmlns:ogc="http://www.opengis.net/ogc"\n` +
      `xmlns:wfs="http://www.opengis.net/wfs"\n` +
      `xmlns:haldia="www.haldia.com">\n` +
      `<wfs:Delete typeName="haldia:${layerName}">\n` +
      `<ogc:Filter>\n` +
      `<ogc:FeatureId fid="${featureId}"/>\n` +
      `</ogc:Filter>\n` +
      `</wfs:Delete>\n` +
      `</wfs:Transaction>`;
    return deleteFeature(wfsTransaction)
      .then((res) => {
        console.log("success");
        map.renderSync();
        return true;
      })
      .catch((error) => {
        console.log("error");
        return false;
      });
  }
};

const replaceSpacesWithEncodedValue = (str) => {
  // Check if the string contains spaces
  if (str.includes(" ")) {
    // Replace all spaces with '%20'
    return str.replace(/ /g, "%20");
  }
  // Return the original string if no spaces are found
  return str;
};
