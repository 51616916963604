import { axiosInstance } from "../components/interceptor/Iterceptor";

export const searchPlace = async (placeName) => {
  return await axiosInstance.get(`https://nominatim.openstreetmap.org/search`, {
    params: {
      q: placeName,
      format: "json",
      addressdetails: 1,
      limit: 10,
      countrycodes: 'IN',
    },
  });
};
