import { React, useEffect, useState } from "react";
import { material } from "../../../library/material";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import FarmActivityDetailsForm from "../../farm-activity-details-form/FarmActivityDetailsForm";
import { getColour } from "../../../utils/getBackGroundColour";
import { useSelector } from "react-redux";
import "./PhysicalDetailsAddDialog.css";
import ParcelOwnershipDetailsForm from "../../parcel-ownership-details-form/ParcelOwnershipDetailsForm";
import SocioEconomicDetailsForm from "../../socio-economic-details-form/SocioEconomicDetailsForm";
import ParcelLandUseForm from "../../parcel-land-use/ParcelLandUseForm";

function PhysicalDetailsAddDialog({ openAddDialog, close }) {
  const themeName = useSelector((state) => state.ThemeChangeSlice.themeName);
  const [openInfo, setOpenInfo] = useState({ ...openAddDialog });
  const [landOwnerType, setLandOwnerType] = useState("");
  const [isLandUser, setIsLandUser] = useState("yes");
  const [selectedLandType, setSelectedLandType] = useState("");
  const [currentUseLand, setCurrentUseLand] = useState("");
  const [farmActivities, setFarmActivities] = useState("");
  const [nonFarmActivities, setNonFarmActivities] = useState("yes");
  const [farmActivityName, setNonFarmActivityName] = useState("");
  // const [isParcelOwnerSameAsRor, setIsParcelOwnerSameAsRor] = useState(watch2('isParcelOwnerSameAsRor'));
  const [isParcelOwnerSameAsRor, setIsParcelOwnerSameAsRor] = useState(true);

  const {
    register: register1,
    handleSubmit: handleSubmit1,
    reset: reset1,
    formState: { errors: errors1, isValid: isValid1 },
    watch: watch1,
    getValues: getValues1,
    setValue: setValue1,
    control: control1,
  } = useForm();

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    reset: reset2,
    formState: { errors: errors2, isValid: isValid2 },
    watch: watch2,
    getValues: getValues2,
    setValue: setValue2,
    control: control2,
  } = useForm();

  const {
    register: register3,
    handleSubmit: handleSubmit3,
    reset: reset3,
    formState: { errors: errors3, isValid: isValid3 },
    watch: watch3,
    getValues: getValues3,
    setValue: setValue3,
    control: control3,
  } = useForm({defaultValues: { selectedFarmActivities: [], selectedStructureType:[]}});

  // const { fields, append, remove } = useFieldArray({
  //   control,
  //   name: "farmActivityDetails", // This is the name of the field array
  // });

  const landOwnerTypeList = [
    { type: " Encroacher on govt land for agriculture/other use", value: "01" },
    { type: "Squatter on govt land for agriculture/ other use", value: "02" },
    { type: "Squatter on govt land for residential purposes", value: "03" },
    { type: "Private land owner", value: "04" },
    { type: "Patta holder on government record", value: "05" },
    { type: "Others", value: "06" },
  ];

  const typeOfLandList = [
    { type: "Agricultural Land", value: "01" },
    { type: "Fallow Land", value: "02" },
    { type: "Forested Land", value: "03" },
    { type: "Homestead and Non agricultural land", value: "04" },
    { type: "Wetland/Pond", value: "05" },
    { type: "River", value: "06" },
    { type: "Canal", value: "07" },
    { type: "Roadways", value: "08" },
    { type: "Commercial", value: "09" },
  ];

  const currentUseLandTypeList = [
    { type: "Agriculture", value: "01" },
    { type: "Residential", value: "02" },
    { type: "Mixed Type 1 (Agri + Resi)", value: "03" },
    { type: "Religious structure (such as temple)", value: "04" },
    { type: "Other", value: "05" },
  ];

  const farmActivityList = [
    { type: "Agriculture", value: "01" },
    { type: "Horticulture", value: "02" },
    { type: "Plantation", value: "03" },
    { type: "Pisciculture", value: "04" },
    { type: "Poultry", value: "05" },
    { type: "Others", value: "06" },
  ];

  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // useEffect(() => {
  //   // append({})
  //   let farmActivityValue = getValues("farmActivities");
  //   onChangeFarmActivities(farmActivityValue);
  // }, [watch("farmActivities")]);

  useEffect(() => {
    // append({})
  }, []);

  useEffect(() => {
    setIsParcelOwnerSameAsRor(watch2("isParcelOwnerSameAsRor"));
  }, [watch2("isParcelOwnerSameAsRor")]);

  const savePhysicalDetails = (formData) => {
    console.log(formData);
    close();
  };

  const ParcelOwnershipDetails = (formdata) => {
    console.log("far")
    console.log(formdata);
    console.log("next")
  };

  const ParcelLandUserDetails =(formdata)=>{
    console.log(formdata);
  }
  const closeDialog = () => {
    close();
  };

  const changeParcelOwnerSameAsRor = (event) => {
    console.log(event);
    // setIsParcelOwnerSameAsRor(e.target.value);
  };

  // const onChangelandOwnerType = (event) => {
  //   setLandOwnerType(event.target.value);
  // }

  // const handleChangeLandUser = (event) => {
  //   setIsLandUser(event.target.value);
  // }

  // const onchangeLandType = (event) => {
  //   setSelectedLandType(event.target.value)
  // }

  // const onchangeCurrentUseLandType = (event) => {
  //   setCurrentUseLand(event.target.value)
  // }

  // const onChangeFarmActivities = (value) => {
  //   setFarmActivities(value);
  // }

  // const onChangeNonFarmActivities = (value) => {
  //   setNonFarmActivities(value);
  // }

  // const onchangeFarmActivityName = (event) => {
  //   setNonFarmActivityName(event.target.value);
  // }

  // useEffect(() => {
  //   let farmActivityValue = getValues('farmActivities')
  //   onchangeFarmActivityName(farmActivityValue)
  // }, [watch('farmActivities')])

  return (
    <div>
      <material.Dialog
        hideBackdrop
        open={openInfo.open}
        sx={{ p: 4 }}
        fullWidth
        fullScreen
        maxWidth="lg"
      >
        {/* <material.DialogTitle className="fw-bold border border-bottom-2">
          Add Parcel Details
        </material.DialogTitle> */}
        <material.DialogContent className="position-relative">
          <material.IconButton
            aria-label="delete"
            size="large"
            color="error"
            onClick={closeDialog}
            className="position-absolute close-dialog-button"
          >
            <material.CloseIcon />
          </material.IconButton>
          <material.Box sx={{ width: "100%", typography: "body1" }}>
            <material.TabContext value={value} className="tab">
              <material.Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <material.TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <material.Tab
                    label="Add Parcel Details"
                    icon={<material.StickyNote2TwoToneIcon />}
                    iconPosition="start"
                    value="1"
                  />
                  <material.Tab
                    label="Parcel Ownership Details"
                    icon={<material.PeopleAltTwoToneIcon />}
                    iconPosition="start"
                    value="2"
                  />
                  <material.Tab
                    label="Parcel Land Use Details"
                    icon={<material.RecyclingTwoToneIcon />}
                    iconPosition="start"
                    value="3"
                  />
                </material.TabList>
              </material.Box>
              <material.TabPanel value="1">
                <form action="">
                  <div className="row mb-2">
                    <div className="col-4 col-md-6 col-sm-12">
                      <material.TextField
                        name="districtName"
                        {...register1("districtName", { required: true })}
                        label="District Name"
                        id="outlined-size-small"
                        size="small"
                        type="text"
                        fullWidth
                        variant="standard"
                        className="mt-3 w-100"
                        required
                      />
                    </div>
                    <div className="col-4 col-md-6 col-sm-12">
                      <material.TextField
                        name="districtCode"
                        {...register1("districtCode", { required: true })}
                        label="District Code"
                        id="outlined-size-small"
                        size="small"
                        type="text"
                        fullWidth
                        variant="standard"
                        className="mt-3 w-100"
                        required
                      />
                    </div>
                    <div className="col-4 col-md-6 col-sm-12">
                      <material.TextField
                        name="subDistrict"
                        {...register1("subDistrict", { required: true })}
                        label="Tehsil/Sub-district"
                        id="outlined-size-small"
                        size="small"
                        type="text"
                        fullWidth
                        variant="standard"
                        className="mt-3 w-100"
                        required
                      />
                    </div>
                    <div className="col-4 col-md-6 col-sm-12">
                      <material.TextField
                        name="subDistrictCode"
                        {...register1("subDistrictCode", { required: true })}
                        label="Sub-district Code"
                        id="outlined-size-small"
                        size="small"
                        type="text"
                        fullWidth
                        variant="standard"
                        className="mt-3 w-100"
                        required
                      />
                    </div>
                    <div className="col-4 col-md-6 col-sm-12">
                      <material.TextField
                        name="villageName"
                        {...register1("villageName", { required: true })}
                        label="Village Name"
                        id="outlined-size-small"
                        size="small"
                        type="text"
                        fullWidth
                        variant="standard"
                        className="mt-3 w-100"
                        required
                      />
                    </div>
                    <div className="col-4 col-md-6 col-sm-12">
                      <material.TextField
                        name="villageCode"
                        {...register1("villageCode", { required: true })}
                        required
                        label="Village Code"
                        id="outlined-size-small"
                        size="small"
                        type="text"
                        fullWidth
                        variant="standard"
                        className="mt-3 w-100"
                      />
                    </div>
                    <div className="col-4 col-md-6 col-sm-12">
                      <material.TextField
                        name="mouzaName"
                        {...register1("mouzaName", { required: true })}
                        label="Mouza Name"
                        id="outlined-size-small"
                        size="small"
                        type="text"
                        fullWidth
                        variant="standard"
                        className="mt-3 w-100"
                        required
                      />
                    </div>
                    <div className="col-4 col-md-6 col-sm-12">
                      <material.TextField
                        name="mouzaNo"
                        {...register1("mouzaNo", { required: true })}
                        label="Mouza/JL No."
                        id="outlined-size-small"
                        size="small"
                        type="text"
                        fullWidth
                        variant="standard"
                        className="mt-3 w-100"
                        required
                      />
                    </div>
                    <div className="col-4 col-md-6 col-sm-12">
                      <material.TextField
                        name="poltNo"
                        {...register1("poltNo", { required: true })}
                        label="Plot/DAG/Khasra No."
                        id="outlined-size-small"
                        size="small"
                        type="text"
                        fullWidth
                        variant="standard"
                        className="mt-3 w-100"
                        required
                      />
                    </div>
                    <div className="col-4 col-md-6 col-sm-12">
                      <material.TextField
                        name="khatianNo"
                        {...register1("khatianNo", { required: true })}
                        label="Parcel/Khatian No (RoR)."
                        id="outlined-size-small"
                        size="small"
                        type="text"
                        fullWidth
                        variant="standard"
                        className="mt-3 w-100"
                        required
                      />
                    </div>
                    <div className="col-4 col-md-6 col-sm-12">
                      <material.TextField
                        name="parcelOwnerName"
                        {...register1("parcelOwnerName", { required: true })}
                        label="Parcel Owner’s Name"
                        id="outlined-size-small"
                        size="small"
                        type="text"
                        fullWidth
                        variant="standard"
                        className="mt-3 w-100"
                        required
                      />
                    </div>
                    <div className="col-4 col-md-6 col-sm-12">
                      <material.TextField
                        name="totalParcelArea"
                        {...register1("totalParcelArea", { required: true })}
                        label="Total Parcel Area (bigha/katha/decimals)"
                        id="outlined-size-small"
                        size="small"
                        type="text"
                        fullWidth
                        variant="standard"
                        className="mt-3 w-100"
                        required
                      />
                    </div>
                    <div className="col-4 col-md-6 col-sm-12">
                      <material.TextField
                        name="parcelAreaAcquiredForProject"
                        {...register1("parcelAreaAcquiredForProject", {required: true,})}
                        label="Parcel Area acquired for the project (bigha/katha/decimals)"
                        id="outlined-size-small"
                        size="small"
                        type="text"
                        fullWidth
                        variant="standard"
                        className="mt-3 w-100"
                        required
                      />
                    </div>
                  </div>
                  {/* <SocioEconomicDetailsForm
                    control={control1}
                    watch={watch1}
                    register={register1}
                    setValue={setValue1}
                  /> */}
                  <span className="float-end mt-2">
                    <material.Button
                      variant="contained"
                      color="success"
                      startIcon={<material.SaveIcon />}
                      onClick={handleSubmit1(savePhysicalDetails)}
                      className="me-2"
                    >
                      Save And Next
                    </material.Button>
                  </span>
                </form>
              </material.TabPanel>

              {/* 2nd form for percial owners details */}

              <material.TabPanel value="2">
                <ParcelOwnershipDetailsForm
                  control={control2}
                  register={register2}
                  watch={watch2}
                  Controller={Controller}
                  fields={""}
                  remove={""}
                  setValue={setValue2}
                />
                <span className="float-end mt-2">
                  <material.Button
                    variant="contained"
                    color="success"
                    startIcon={<material.SaveIcon />}
                    onClick={handleSubmit2(ParcelOwnershipDetails)}
                    className="my-2"
                  >
                    Save And Next
                  </material.Button>
                </span>
              </material.TabPanel>
              <material.TabPanel value="3">
                <form action="">
                  <div className="mb-2">
                    <ParcelLandUseForm control={control3} watch={watch3} register={register3} setValue={setValue3}/>
                  </div>
                </form>
                <span className="float-end mt-2">
                  <material.Button
                    variant="contained"
                    color="success"
                    startIcon={<material.SaveIcon />}
                    onClick={handleSubmit3(ParcelLandUserDetails)}
                    className="my-2"
                  >
                    Save And Next
                  </material.Button>
                </span>
              </material.TabPanel>
            </material.TabContext>
          </material.Box>
        </material.DialogContent>
        {/* <material.DialogActions className="fw-bold">
          <material.Button
            variant="contained"
            color="error"
            startIcon={<material.CloseIcon />}
            className="me-2"
            onClick={closeDialog}
          >
            Close
          </material.Button>
          <material.Button
            variant="contained"
            color="success"
            startIcon={<material.SaveIcon />}
            onClick={handleSubmit(savePhysicalDetails)}
            className="me-2"
          >
            Save
          </material.Button>
        </material.DialogActions> */}
      </material.Dialog>
    </div>
  );
}

export default PhysicalDetailsAddDialog;
