//import logo from './logo.svg';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import Routing from './routing/Routing';
import { ThemeProvider } from '@mui/material/styles';
import themeReturnFunction from './library/theme';
//import { themeNameChangingAction } from './store/slice/ThemeChangeSlice';
import { useSelector } from 'react-redux';
// import { useDispatch} from 'react-redux'
function App() {
  // const dispatch = useDispatch();
  // dispatch(themeNameChangingAction('Royal_Blue'))
  const themeName = useSelector((state) => state.ThemeChangeSlice.themeName);

  return (
    <div>

      <BrowserRouter>
        <ThemeProvider theme={themeReturnFunction(themeName)}>
          <Routing />
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
