export class SocioIconomicSDropdown {
  religionList = [
    { name: "a. Hindu", value: "Hindu" },
    { name: "b. Muslim", value: "Muslim" },
    { name: "c. Christian", value: "Christian" },
    { name: "d. Sikh", value: "Sikh" },
    { name: "e. Others", value: "Others" },
  ];
  castList = [
    { name: "a. General", value: "General" },
    { name: "b. OBC", value: "OBC" },
    { name: "c. SC", value: "SC" },
    { name: "d. ST", value: "ST" },
    { name: "e. Others", value: "Others" },
  ];
  relationshipWithHeadOfHouseholdList = [
    { name: "1. Head of Household", value: "Head of Household" },
    { name: "2. Spouse", value: "Spouse" },
    { name: "3. Son / Daughter", value: "Son / Daughter" },
    {
      name: "4. Son-in-law / Daughter-in-law",
      value: "Son-in-law / Daughter-in-law",
    },
    {
      name: "5. Grandson / Granddaughter",
      value: "Grandson / Granddaughter",
    },
    { name: "6. Father / Mother", value: "Father / Mother" },
    { name: "7. Parent In law", value: "Parent In law" },
    { name: "8. Brothers / Sister", value: "Brothers / Sister" },
    { name: "9. Nephew / Niece", value: "Nephew / Niece" },
    { name: "10. Grandparent", value: "Grandparent" },
    {
      name: "11. Brother-in-law / Sister-in-law",
      value: "Brother-in-law / Sister-in-law",
    },
    { name: "12. Other relative", value: "Other relative" },
    { name: "13. Cousins", value: "Cousins" },
  ];
  maritalStatusList = [
    { name: "1. Married", value: "Married" },
    { name: "2. Single", value: "Single" },
    { name: "3. Separated/ abandoned", value: "Separated/ abandoned" },
    { name: "4. Divorced", value: "Divorced" },
    { name: "5. Widowed", value: "Widowed" },
  ];
  literacyList = [
    { name: "1. Can read", value: "Can read" },
    { name: "2. Can write", value: "Can write" },
    { name: "3. Can read and write", value: "Can read and write" },
    { name: "4. None", value: "None" },
  ];

  discontinuingReasonsList = [
    { name: "1. No reason", value: "No reason" },
    { name: "2. Age", value: "Age" },
    { name: "3. Death in the family", value: "Death in the family" },
    {
      name: "4. Lack of economic resources",
      value: "Lack of economic resources",
    },
    { name: "5. Failed", value: "Failed" },
    { name: "6. Work", value: "Work" },
    { name: "7. Illness or disability", value: "Illness or disability" },
    { name: "8. Household chores", value: "Household chores" },
    {
      name: "9. Family does not permit studying",
      value: "Family does not permit studying",
    },
    {
      name: "10. No educational establishments",
      value: "No educational establishments",
    },
    {
      name: "11. Not interested in studying",
      value: "Not interested in studying",
    },
    { name: "12. Distance", value: "Distance" },
    { name: "13. Marriage/Child Marriage", value: "Marriage/Child Marriage" },
    { name: "14. Other", value: "Other" },
    { name: "15. Not Applicable", value: "Not Applicable" },
  ];
  educationLevelList = [
    { name: "1. Primary", value: "Primary" },
    { name: "2. Secondary level", value: "Secondary level" },
    { name: "3. Higher Secondary level", value: "Higher Secondary level" },
    { name: "4. Graduate", value: "Graduate" },
    { name: "5. Post Graduate", value: "Post Graduate" },
    { name: "6. Vocational Training", value: "Vocational Training" },
    { name: "7. Not school going age", value: "Not school going age" },
    {
      name: "8. Never attended formal education",
      value: "Never attended formal education",
    },
  ];
  disabilityList = [
    { name: "1. No", value: "No" },
    { name: "2. Eye Sight", value: "Eye Sight" },
    { name: "3. Mobility", value: "Mobility" },
    { name: "4. Speech", value: "Speech" },
    { name: "5. Mental Disability", value: "Mental Disability" },
    { name: "6. Hearing", value: "Hearing" },
    { name: "7. Physical", value: "Physical" },
    { name: "8. Other", value: "Other" },
  ];
  livelihoodList = [
    { name: "1. Agriculture", value: "Agriculture" },
    { name: "2. Livestock", value: "Livestock" },
    { name: "3. Agricultural Labour", value: "Agricultural Labour" },
    { name: "4. Construction worker", value: "Construction worker" },
    {
      name: "5. Working in stone crushers",
      value: "Working in stone crushers",
    },
    { name: "6. Business/shop", value: "Business/shop" },
    { name: "7. Electrician", value: "Electrician" },
    { name: "8. Mechanic", value: "Mechanic" },
    { name: "9. Driver", value: "Driver" },
    { name: "10. Private job", value: "Private job" },
    { name: "11. Government job", value: "Government job" },
    { name: "12. Housewife", value: "Housewife" },
    { name: "13. Teacher", value: "Teacher" },
    { name: "14. Student", value: "Student" },
    { name: "15. Retired", value: "Retired" },
    { name: "16. Other", value: "Other" },
  ];

  skillSetList = [
    { name: "1. Agro / Horticulture", value: "Agro / Horticulture" },
    { name: "2. Cattle Rearing", value: "Cattle Rearing" },
    { name: "3. Carpenter", value: "Carpenter" },
    { name: "4. Driver", value: "Driver" },
    { name: "5. Plumber", value: "Plumber" },
    { name: "6. Electrician", value: "Electrician" },
    {
      name: "7. Health Worker / Medical practitioner",
      value: "Health Worker / Medical practitioner",
    },
    { name: "8. Welder", value: "Welder" },
    { name: "9. Mason (raj mistri)", value: "Mason (raj mistri)" },
    { name: "10. Mechanic", value: "Mechanic" },
    { name: "11. Cook", value: "Cook" },
    { name: "12. Tailor", value: "Tailor" },
    { name: "13. Beehive", value: "Beehive" },
  ];
  sexList = [
    { name: "1. Male", value: "Male" },
    { name: "2. Female", value: "Female" },
    { name: "3. Other", value: "Other" },
  ];
  vulnerabilityOptions = [
    { name: "BPL (BPL Ration Card/Antyodaya card)", value: "BPL" },
    { name: "Women Headed Household", value: "Women Headed Household" },
    { name: "Households with only elderly (60+)", value: "Elderly Household" },
    { name: "Landless", value: "Landless" },
    { name: "Disability", value: "Disability" },
    { name: "None", value: "None" },
  ];
  expenditureItems = [
    {
      itemName: "Fire wood/ Cooking Fuel (including LPG)",
      frequencyOfExpenditure: 0,
      expenditureInRupee: 0,
      totalExpense: 0,
    },
    {
      itemName: "Education fees",
      frequencyOfExpenditure: 0,
      expenditureInRupee: 0,
      totalExpense: 0,
    },
    {
      itemName:
        "Diesel/ petrol/ Transport (including rental payment for tractors)",
      frequencyOfExpenditure: 0,
      expenditureInRupee: 0,
      totalExpense: 0,
    },
    {
      itemName: "Telephone/mobile phone/internet recharge",
      frequencyOfExpenditure: 0,
      expenditureInRupee: 0,
      totalExpense: 0,
    },
    {
      itemName: "Entertainment (movies, music, sightseeing, etc.)",
      frequencyOfExpenditure: 0,
      expenditureInRupee: 0,
      totalExpense: 0,
    },
    {
      itemName: "Healthcare/ Medical Expenditure",
      frequencyOfExpenditure: 0,
      expenditureInRupee: 0,
      totalExpense: 0,
    },
    {
      itemName: "Agriculture Input Investment (Seeds, Fertiliser, etc.)",
      frequencyOfExpenditure: 0,
      expenditureInRupee: 0,
      totalExpense: 0,
    },
    {
      itemName: "Fodder",
      frequencyOfExpenditure: 0,
      expenditureInRupee: 0,
      totalExpense: 0,
    },
    {
      itemName: "Annual maintenance of residence",
      frequencyOfExpenditure: 0,
      expenditureInRupee: 0,
      totalExpense: 0,
    },
    {
      itemName: "Loan repayment",
      frequencyOfExpenditure: 0,
      expenditureInRupee: 0,
      totalExpense: 0,
    },
    {
      itemName: "Cultural/religious expenses (festivals/marriages)",
      frequencyOfExpenditure: 0,
      expenditureInRupee: 0,
      totalExpense: 0,
    },
    {
      itemName: "Ration/Food",
      frequencyOfExpenditure: 0,
      expenditureInRupee: 0,
      totalExpense: 0,
    },
    {
      itemName: "Clothing",
      frequencyOfExpenditure: 0,
      expenditureInRupee: 0,
      totalExpense: 0,
    },
    {
      itemName: "Household appliances",
      frequencyOfExpenditure: 0,
      expenditureInRupee: 0,
      totalExpense: 0,
    },
    {
      itemName: "Electricity",
      frequencyOfExpenditure: 0,
      expenditureInRupee: 0,
      totalExpense: 0,
    },
    {
      itemName: "Habits (Tobacco, Betel leaves, Liquor, etc.)",
      frequencyOfExpenditure: 0,
      expenditureInRupee: 0,
      totalExpense: 0,
    },
    {
      itemName:
        "Rent paid for Agricultural Land Used (share cropper or encroacher and squatter)",
      frequencyOfExpenditure: 0,
      expenditureInRupee: 0,
      totalExpense: 0,
    },
    {
      itemName: "Samuha/Samiti/ SHG Payment",
      frequencyOfExpenditure: 0,
      expenditureInRupee: 0,
      totalExpense: 0,
    },
    {
      itemName: "One time expenditure (unforeseen, etc)",
      frequencyOfExpenditure: 0,
      expenditureInRupee: 0,
      totalExpense: 0,
    },
    {
      itemName: "Other (specify)",
      frequencyOfExpenditure: 0,
      expenditureInRupee: 0,
      totalExpense: 0,
    },
    {
      itemName: "Total (System Generated)",
      frequencyOfExpenditure: 0,
      expenditureInRupee: 0,
      totalExpense: 0,
    },
  ];
  incomeItems = [
    {
      itemName: "Agricultural/Horticultural income",
      frequencyOfUnit: 0,
      amountForOneUnit: 0,
      totalIncome: 0,
    },
    {
      itemName: "Livestock and livestock product",
      frequencyOfUnit: 0,
      amountForOneUnit: 0,
      totalIncome: 0,
    },
    {
      itemName: "Wage labour from stone quarry",
      frequencyOfUnit: 0,
      amountForOneUnit: 0,
      totalIncome: 0,
    },
    {
      itemName: "Wage labour (local and migrant work) agricultural labour",
      frequencyOfUnit: 0,
      amountForOneUnit: 0,
      totalIncome: 0,
    },
    {
      itemName:
        "Wage labour (local and migrant work) non-agricultural, non-quarry",
      frequencyOfUnit: 0,
      amountForOneUnit: 0,
      totalIncome: 0,
    },
    {
      itemName: "Govt./ Private Employment",
      frequencyOfUnit: 0,
      amountForOneUnit: 0,
      totalIncome: 0,
    },
    {
      itemName: "Business/ Small Scale Enterprises",
      frequencyOfUnit: 0,
      amountForOneUnit: 0,
      totalIncome: 0,
    },
    {
      itemName: "Returns on Savings/ investments",
      frequencyOfUnit: 0,
      amountForOneUnit: 0,
      totalIncome: 0,
    },
    {
      itemName: "Remittances",
      frequencyOfUnit: 0,
      amountForOneUnit: 0,
      totalIncome: 0,
    },
    {
      itemName: "Artisanal skills/ Trade",
      frequencyOfUnit: 0,
      amountForOneUnit: 0,
      totalIncome: 0,
    },
    {
      itemName: "Pension (Widow/ Soldier/service etc.)",
      frequencyOfUnit: 0,
      amountForOneUnit: 0,
      totalIncome: 0,
    },
    {
      itemName: "Rent from Property/ Asset",
      frequencyOfUnit: 0,
      amountForOneUnit: 0,
      totalIncome: 0,
    },
    {
      itemName: "Government assistance (like Ladli Behna Scheme)",
      frequencyOfUnit: 0,
      amountForOneUnit: 0,
      totalIncome: 0,
    },
    {
      itemName: "Any other (Specify): …………………………………",
      frequencyOfUnit: 0,
      amountForOneUnit: 0,
      totalIncome: 0,
    },
    {
      itemName: "Total Annual Income (system generated)",
      frequencyOfUnit: 0,
      amountForOneUnit: 0,
      totalIncome: 0,
    },
  ];

  cropCodeList = [
    { name: "Jawar", value: "Jawar" },
    { name: "Bajra", value: "Bajra" },
    { name: "Rice", value: "Rice" },
    { name: "Tuar", value: "Tuar" },
    { name: "Moong", value: "Moong" },
    { name: "Wheat", value: "Wheat" },
    { name: "Gram", value: "Gram" },
    { name: "Mustard", value: "Mustard" },
    { name: "Maize", value: "Maize" },
    { name: "Soyabean", value: "Soyabean" },
    { name: "Vegetables", value: "Vegetables" },
    { name: "Groundnut", value: "Groundnut" },
    { name: "Other", value: "Other" },
  ];

  cropStatusList = [
    { name: "Standing Crop", value: "Standing Crop" },
    { name: "Previous Season", value: "Previous Season" },
    { name: "Other", value: "Other" },
  ];
  cropVarietyList = [
    { name: "Local", value: "Local" },
    { name: "Hybrid", value: "Hybrid" },
    { name: "Other", value: "Other" },
  ];
  irrigationSourceList = [
    { name: "Rain only", value: "Rain only" },
    { name: "Borewell", value: "Borewell" },
    { name: "Ponds", value: "Ponds" },
    { name: "Check dams", value: "Check dams" },
    { name: "Open well", value: "Open well" },
    { name: "Tube well", value: "Tube well" },
    { name: "Canal water", value: "Canal water" },
    { name: "Other", value: "Other" }
  ];
}
