// import { Style, Fill, Circle, Text } from "ol/style";
// import Stroke from "ol/style/Stroke.js";
// import { generateRandomColors } from "./randomColourGenerator";

// export const createStyle = (feature, e) => {
//   console.log(feature, e);
//   const geometryType = feature.getGeometry().getType();
//   const label = feature.get("mouza_name" || "monitoring" || 'category' || "strg_name" || 'industry_n' || "name" || 'sw stn no.'); // Get the 'name' property of the feature
// console.log(label)
//   const textStyle = new Text({
//     font: 'bold 14px Calibri,sans-serif', // Bold and larger font size
//     fill: new Fill({
//       color: '#000' // Dark color
//     }),
//     stroke: new Stroke({
//       color: '#fff',
//       width: 2
//     }),
//     text: label,
//     textAlign: 'center', // Center the text horizontally
//     placement: 'point' // Place the text in the middle of the feature
//   });

//   if (geometryType === "Polygon" || geometryType === "MultiPolygon") {
//     return new Style({
//       fill: new Fill({
//         color: generateRandomColors(1)[0],
//       }),
//       stroke: new Stroke({
//         color: generateRandomColors(1, 3)[0],
//         width: 2,
//       }),
//       text: textStyle
//     });
//   } else if (
//     geometryType === "LineString" ||
//     geometryType === "MultiLineString"
//   ) {
//     return new Style({
//       stroke: new Stroke({
//         color: generateRandomColors(1, 3)[0],
//         width: 4,
//       }),
//       text: textStyle
//     });
//   } else if (geometryType === "Point") {
//     return new Style({
//       image: new Circle({
//         radius: 6,
//         fill: new Fill({
//           color: generateRandomColors(1, 3)[0],
//         }),
//         stroke: new Stroke({
//           color: generateRandomColors(1, 3)[0],
//           width: 2,
//         }),
//       }),
//       text: textStyle
//     });
//   }

//   // Return a default style if geometry type is not recognized
//   return new Style({
//     // Define your default style here
//   });
// };

import { Style, Fill, Circle, Text } from "ol/style";
import Stroke from "ol/style/Stroke.js";
import { generateRandomColors } from "./randomColourGenerator";

export const createStyle = (feature) => {
  console.log(feature);
  const geometryType = feature.getGeometry().getType();

  // Function to find the first existing property
  const findLabel = (feature, properties) => {
    for (let prop of properties) {
      const value = feature.get(prop);
      if (value) return value;
    }
    return ""; // Return empty string if no properties are found
  };

  const label = findLabel(feature, [
    "mouza_name",
    "monitoring",
    "category",
    "strg_name",
    "industry_n",
    "name",
    "sw stn no.",
    "district n"
  ]);

  const textStyle = new Text({
    font: "bold 10px Arial, sans-serif", // Bold and larger font size
    fill: new Fill({
      color: "#000000", // Black color for text
    }),
    stroke: new Stroke({
      color: "#FFFFFF", // White color for text outline
      width: 7, // Outline width to make text stand out
    }),
    text: label,
    textAlign: "center", // Center the text horizontally
    placement: "point", // Place the text in the middle of the feature
    offsetY: -15, // Adjust the vertical offset to position the text above the marker
  });

  if (geometryType === "Polygon" || geometryType === "MultiPolygon") {
    return new Style({
      fill: new Fill({
        color: "rgba(255, 0, 0, 0.2)", // Semi-transparent red fill
      }),
      stroke: new Stroke({
        color: "red", // Red border
        width: 2,
      }),
      text: textStyle, // Add text to polygon
    });
  } else if (
    geometryType === "LineString" ||
    geometryType === "MultiLineString"
  ) {
    return new Style({
      stroke: new Stroke({
        color: generateRandomColors(1, 3)[0], // Random color for line
        width: 4,
      }),
      text: textStyle, // Add text to line
    });
  } else if (geometryType === "Point") {
    // Define two styles for blinking effect
    const visibleStyle = new Style({
      image: new Circle({
        radius: 8, // Size of the marker
        fill: new Fill({
          color: generateRandomColors(1, 3)[0], // Color of the marker
        }),
        stroke: new Stroke({
          color: generateRandomColors(1, 3)[0], // Outline color of the marker
          width: 2, // Outline width
        }),
      }),
      text: textStyle
    });

    const invisibleStyle = new Style({
      image: new Circle({
        radius: 8,
        fill: new Fill({
          color: generateRandomColors(1)[0], // Fully transparent
        }),
        stroke: new Stroke({
          color: generateRandomColors(1)[0], // Fully transparent
          width: 2,
        }),
      }),
      text: textStyle
    });
    // Create blinking effect for the point
    let blink = true;
    setInterval(() => {
      blink = !blink;
      feature.setStyle(blink ? visibleStyle : invisibleStyle);
    }, 500); // Change style every 500ms

    return visibleStyle;
  }

  // Return a default style if geometry type is not recognized
  return new Style({
    text: textStyle, // Apply text style to any other geometry type
  });
};
