import { axiosInstance } from "../components/interceptor/Iterceptor";
import { homeServerUrl, rooApiUrl } from "../environment/Environment";

export const getGeometryType = async (layerName) => {
  return await axiosInstance.get(
    `${rooApiUrl}/wfs?service=WFS&version=1.1.0&request=DescribeFeatureType&typename=haldia:${layerName}`
  );
};

export const createFeature = async (wfsTransaction) => {
  return await axiosInstance.post(`${rooApiUrl}/wfs`, wfsTransaction, {
    headers: {
      "Content-Type": "text/xml",
    },
  });
};

export const deleteFeature = async (wfsTransaction) => {
  return await axiosInstance.post(`${rooApiUrl}/wfs`, wfsTransaction, {
    headers: {
      "Content-Type": "text/xml",
    },
  });
};
