import React from "react";
import { material } from "../../../library/material";

function ConfirmationDialog({ confirmationDetails, onConfirm, onClose }) {
  return (
    <>
      <material.Dialog
        open={confirmationDetails.open}
        //</>onClose={onClose}
        maxWidth="sm"
      >
        <material.DialogTitle
          className="px-3 bg-danger"
          style={{ color: "#690404" }}
        >
         {confirmationDetails.message}
        </material.DialogTitle>
        <material.DialogContent className="px-4 mt-2">
          <div className="row">
            {/* <div className="col-12 px-0 mb-2">
              Are you sure you want to delete the feature?
            </div> */}
            <div
              className="col-12 d-flex flex-column rounded-2 "
              style={{
                backgroundColor: "#fdc9c9",
                borderLeft: "4px solid #a30808",
                borderColor: "#a30808",
              }}
            >
              <span style={{ color: "#a30808" }} className="fw-bold fs-6">
                <i className="fa-solid fa-triangle-exclamation fa-shake"></i>{" "}
                Warning
              </span>
              <span style={{ color: "#a30808" }}>
                {confirmationDetails.warnMessage}
              </span>
            </div>
          </div>
        </material.DialogContent>
        <material.DialogActions
          className="px-3"
          // style={{
          //   display: "flex",
          //   alignItems: "center",
          //   justifyContent: "center",
          //   flexDirection: "row",
          // }}
        >
          <material.Button
            onClick={onClose}
            variant="outlined"
            color="primary"
            //fullWidth
            startIcon={<i className="fa-solid fa-delete-left fa-beat"></i>}
            style={{ textTransform: "none" }}
          >
            Cancel
          </material.Button>
          <material.Button
            onClick={onConfirm}
            variant="contained"
            color="error"
            startIcon={<i className="fa-solid fa-trash fa-shake"></i>}
            //fullWidth
            style={{ textTransform: "none" }}
          >
            Delete
          </material.Button>
        </material.DialogActions>
      </material.Dialog>
    </>
  );
}

export default ConfirmationDialog;
