import { React, useEffect, useState, useRef } from "react";
import "./SpatialQueryDialog.css";
import { material } from "../../library/material";
// import Table from "../table/Table";
// import pdfIcon from "../../assets/map-image/pdfIcon.png";
// import excelIcon from "../../assets/map-image/excleIcon.png";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import { downloadPdf, downloadExcel } from "../../utils/downloadExcelAndPdf";
import AirQualityTable from "../table/air-quality-table/AirQualityTable";
import Report from "../report-generator/Report";
import { creatDataFormatToGenerateReport } from "../../utils/generateReportDataFormat";
import ComboChart from "../charts/ComboChart";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import DynamicHtmlRendered from "../render-dynamic-html/DynamicHtmlRendered";
import Census from "../census-report/Census";

const StyledTableCell = styled(material.TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#0d3569",
    color: theme.palette.common.white,
    fontWeight: "bold",
    fontSize: 12,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 10,
  },
}));

function SpatialQueryDialog({ openInfo, spatialQueryInfoDialogClose }) {
  const [reportData, setReportData] = useState([]);
  const [runLoader, SetRunLoader] = useState(false);
  const [image, SetImage] = useState("");
  const handleClose = () => {
    spatialQueryInfoDialogClose();
  };

  // console.log(openInfo);
  // const [anchorEl, setAnchorEl] = useState(null);
  // const menuOpen = Boolean(anchorEl);
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleMenuClose = () => {
  //   setAnchorEl(null);
  // };

  useEffect(() => {
    console.log(openInfo)
    let map = openInfo.mapInstance;
    let mapImage = null;

    if (map) {
      // Get the size of the map
      const size = map.getSize();
      const width = size[0];
      const height = size[1];

      map.once("rendercomplete", function () {
        const mapCanvas = document.createElement("canvas");
        mapCanvas.width = width;
        mapCanvas.height = height;
        const mapContext = mapCanvas.getContext("2d");

        Array.prototype.forEach.call(
          document.querySelectorAll(".ol-layer canvas"),
          function (canvas) {
            if (canvas.width > 0) {
              const opacity =
                canvas.style.opacity || canvas.parentNode.style.opacity || 1;
              mapContext.globalAlpha = Number(opacity);

              const transform = canvas.style.transform;
              if (transform) {
                // Get the transform parameters from the style's transform matrix
                const matrix = transform
                  .match(/^matrix\(([^\(]*)\)$/)[1]
                  .split(",")
                  .map(Number);
                // Apply the transform to the export map context
                mapContext.setTransform(...matrix);
              } else {
                // If no transform is found, use a default transform
                mapContext.setTransform(1, 0, 0, 1, 0, 0);
              }

              // Draw the image scaled
              // Set crossOrigin attribute to 'Anonymous'
              if (canvas.crossOrigin !== "Anonymous") {
                canvas.crossOrigin = "Anonymous";
              }

              mapContext.drawImage(canvas, 0, 0);
            }
          }
        );

        mapContext.globalAlpha = 1;
        mapContext.setTransform(1, 0, 0, 1, 0, 0);

        // Convert canvas to data URL
        try {
          const dataURL = mapCanvas.toDataURL("image/png");
          // console.log(dataURL);
          SetImage(mapCanvas);
        } catch (error) {
          console.error("Error converting canvas to data URL:", error);
        }
      });

      // Ensure the map renders
      map.renderSync();
    } else {
      console.error("Map instance is not initialized.");
    }

    SetRunLoader(true);
    // HERE I CALL A FUNCTION WHO GENERATE THE DATA FOR PDF AND THE TABLE
    let reportDataStructure = creatDataFormatToGenerateReport(
      openInfo.spatilQueryInfo
    );
    setReportData(reportDataStructure);
    setTimeout(() => {
      SetRunLoader(false);
    }, 4000);
  }, [openInfo]);

  //console.log(image);
  // const downloadExcelAndPdf = (fileType, featureData, ind) => {
  //     if (featureData?.tableData?.length > 0) {
  //         if (fileType === 'PDF') {
  //             downloadPdf(featureData.tableData, featureData.layerName);
  //         } else if (fileType === 'EXCEL') {
  //             downloadExcel(featureData.tableData, featureData.layerName);
  //         }
  //     }
  //     setAnchorEl(null);
  // }

  // const downloadPdfDoc = (featureData) => {
  //   downloadPdf(featureData);
  // };

  return (
    <div>
      <material.Dialog
        fullWidth={true}
        maxWidth={"xl"}
        open={openInfo.open}
        // onClose={handleClose}
      >
        <material.DialogTitle className="d-flex flex-column fw-bold">
          <div className="d-flex flex-row justify-content-between w-100 ">
            <span>Spatial Query Information</span>
            <material.IconButton
              aria-label="delete"
              size="large"
              color="error"
              onClick={handleClose}
            >
              <material.CloseIcon color="error" />
            </material.IconButton>
          </div>
          {runLoader ? null : (
            <Report
              reportData={reportData}
              SetRunLoader={SetRunLoader}
              mapImage={image}
            />
          )}
        </material.DialogTitle>

        {runLoader ? (
          <material.DialogContent>
            <SkeletonTheme
              baseColor="#bbdefb"
              highlightColor="#c6ff00"
              enableAnimation="true"
              inline="true"
              width="100% "
              height="20px"
            >
              <Skeleton count={15} />
            </SkeletonTheme>
          </material.DialogContent>
        ) : (
          <material.DialogContent>
            {reportData?.map((ele, i) => (
              <material.Accordion
                className="mb-2 border border-3 border-primary"
                key={i}
              >
                <material.AccordionSummary
                  expandIcon={<material.ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <material.Typography className="fw-bold text-primary">
                    {ele.layerName.toUpperCase()}
                  </material.Typography>
                </material.AccordionSummary>
                <material.AccordionDetails className="mt-0">
                  <div className="d-flex justify-content-end mb-2">
                    {/* <material.Button variant="contained" color="success" sx={{ textTransform: 'none' ,mr:1}} startIcon={<img src={excelIcon} alt="" style={{ width: 25, height: 25 }} />} onClick={()=>downloadExcelAndPdf('EXCEL', ele,i)}>Download Excel</material.Button>
                              <material.Button variant="contained" color="success" sx={{ textTransform: 'none' }} startIcon={<img src={pdfIcon} alt="" style={{ width: 25, height: 25 }} />} onClick={()=>downloadExcelAndPdf('EXCEL', ele,i)}>Download Pdf</material.Button>   */}
                    {/* <material.Button
                                      id="basic-button"
                                      variant="contained"
                                      startIcon={<material.FileDownloadIcon />}
                                      color="success"
                                      aria-controls={menuOpen ? 'basic-menu' : undefined}
                                      aria-haspopup="true"
                                      aria-expanded={menuOpen ? 'true' : undefined}
                                      onClick={handleClick}
                                      sx={{ textTransform: 'none' }}
                                  >
                                      Download data
                                  </material.Button>
                                  <material.Menu
                                      id="basic-menu"
                                      anchorEl={anchorEl}
                                      open={menuOpen}
                                      onClose={handleMenuClose}
                                      MenuListProps={{
                                          'aria-labelledby': 'basic-button',
                                          sx: { width: anchorEl && anchorEl.offsetWidth }
                                      }}
                                  >
                                      <material.MenuItem onClick={() => downloadExcelAndPdf('EXCEL', ele,i)}>
                                          <material.ListItemIcon>
                                              <img src={excelIcon} alt="" style={{ width: 30, height: 30 }} />
                                          </material.ListItemIcon>
                                          
                                          In Excel
                                      </material.MenuItem>
                                      <material.MenuItem onClick={() => downloadExcelAndPdf('PDF', ele,i)}>
                                          <material.ListItemIcon>
                                              <img src={pdfIcon} alt="" style={{ width: 30, height: 30 }} />
                                          </material.ListItemIcon>
                                       
                                          In Pdf
                                      </material.MenuItem>
                                  </material.Menu> */}
                  </div>
                  {/* <Table columns={ele.columnName} data={ele.tableData} /> */}

                  {ele.tableData.length > 0 || ele?.htmlContent !== null ? (
                    <>
                      {ele.layerName === "aqdata" ? (
                        <AirQualityTable
                          airData={openInfo?.spatilQueryInfo[i]?.tableData}
                        />
                      ) : ele.layerName === "WB_LULC50K_1516" ? (
                        <>
                          <DynamicHtmlRendered
                            htmlContent={ele?.htmlContent}
                            selectedArea={ele.totalSelectedArea}
                          />
                        </>
                      ) :ele.layerName === "east_medinipur_Census_2011" ? (
                        <>
                          <Census dataAfterQuery={ele}/>
                        </>
                      ) : (
                        <div className="row">
                          {ele.charts?.map((ele, ci) => (
                            <div key={ci} className="col-6 p-1">
                              <material.Paper sx={{ p: 2 }}>
                                <ComboChart
                                  key={i}
                                  data={ele.chartData}
                                  options={ele.chartOptions}
                                  chartType={ele.chartType}
                                />
                              </material.Paper>
                            </div>
                          ))}
                          <div className="col-12 mt-2">
                            <material.Paper sx={{ p: 2 }}>
                              <material.TableContainer sx={{ maxHeight: 350 }}>
                                <material.Table
                                  stickyHeader
                                  aria-label="sticky table"
                                >
                                  <material.TableHead
                                    sx={{
                                      fontWeight: "bold",
                                      color: "white",
                                      textTransform: "capitalize",
                                      backgroundColor: "blue",
                                    }}
                                  >
                                    <material.TableRow
                                      sx={{
                                        textTransform: "capitalize",
                                        height: 20,
                                        border: 2, borderStyle: "solid", borderWidth: 1,borderColor:'black' 
                                      }}
                                    >
                                      {ele.columnName.map((column) => (
                                        <StyledTableCell
                                          size="small"
                                          key={column.header}
                                          align="center"
                                          sx={{border: 2, borderStyle: "solid", borderWidth: 1,borderColor:'black'  }}
                                        >
                                          {column.header}
                                        </StyledTableCell>
                                      ))}
                                    </material.TableRow>
                                  </material.TableHead>
                                  <material.TableBody>
                                    {ele.tableData?.map((rowData, ind) => {
                                      return (
                                        <material.TableRow key={ind}>
                                          {ele.columnName.map(
                                            (column, colInd) => {
                                              return (
                                                <material.TableCell
                                                  size="small"
                                                  key={colInd}
                                                  align="center"
                                                  sx={{border: 2, borderStyle: "solid", borderWidth: 1,borderColor:'black'  }}
                                                >
                                                  {rowData[column.dataKey]}
                                                </material.TableCell>
                                              );
                                            }
                                          )}
                                        </material.TableRow>
                                      );
                                    })}
                                  </material.TableBody>
                                </material.Table>
                              </material.TableContainer>
                            </material.Paper>
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <material.Paper>
                      <div className="col-12 py-1 fs-4 fw-bold d-flex justify-content-center">
                        No data found for {ele.layerName}
                      </div>
                    </material.Paper>
                  )}
                </material.AccordionDetails>
              </material.Accordion>
            ))}
          </material.DialogContent>
        )}

        <material.DialogActions>
          {/* <material.Button  variant="contained" onClick={handleClose} color="error" startIcon={ <material.CloseIcon/>} className='me-2'>Close</material.Button> */}
        </material.DialogActions>
      </material.Dialog>
    </div>
  );
}

export default SpatialQueryDialog;
