import React from "react";
import { Chart } from "react-google-charts";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

const ComboChart = ({ data, options,chartType }) => {
  // PM10 data and the constant max value
  // const data = [
  //   ['X', 'PM10', 'Max Value'],
  //   ['Point 1', 1, 10],
  //   ['Point 2', 2, 10],
  //   ['Point 3', 3, 10],
  //   ['Point 4', 4, 10],
  // ];

  // const options = {
  //   title: 'PM10 Levels and Max Value',
  //   vAxis: { title: 'Value' },
  //   hAxis: { title: 'Points' },
  //   seriesType: 'bars',
  //   series: { 1: { type: 'line' } },
  //   colors: ['#1b9e77', '#d95f02'],
  // };

  return (
    <div>
        <Chart
          chartType={chartType}
          width="100%"
          height="400px"
          data={data}
          options={options}
          loader={<SkeletonTheme
            baseColor="#bbdefb"
            highlightColor="#c6ff00"
            enableAnimation="true"
            inline="true"
            width="100% "
            height="20px"
          >
            <Skeleton count={10} />
          </SkeletonTheme>}
        />
    </div>
  );
};

export default ComboChart;
