export const createChartDataForNoise = (rawData) => {
  let chartData = [];
  const dataDay = [
    [
      "NQ",
      "Leq Day (dB(A))",
      "Industrial area-Day time noise standard",
      "Commercial area-Day time noise standard",
      "Residential area Day time Standard",
      "Silence zone Day time noise standard",
    ],
    ...rawData.map((station) => [
      station["station nu"],
      station["leq day (d"],
      75,
      65,
      55,
      50,
    ]),
  ];

  const optionsDay = {
    title: "Day time Noise Level in Aol",
    chartArea: { width: "80%" },
    hAxis: {
      title: "NQ",
      minValue: 0,
    },
    vAxis: {
      title: "Noise Level (dB)",
    },
    seriesType: "bars",
    series: {
      1: { type: "line", color: "#FF0000", lineWidth: 2 },
      2: { type: "line", color: "#FFA500", lineWidth: 2 },
      3: { type: "line", color: "#008000", lineWidth: 2 },
      4: { type: "line", color: "#0000FF", lineWidth: 2 },
    },
    legend: { position: "left" },
  };
  chartData.push({
    chartData: dataDay,
    chartOptions: optionsDay,
    chartType: "ComboChart",
  });
  const nightData = [
    [
      "NQ",
      "Leq Night (dB(A))",
      "Industrial area-Night time noise standard",
      "Commercial area-Night time noise standard",
      "Residential area Night time Standard",
      "Silence zone Night time noise standard",
    ],
    ...rawData.map((station) => [
      station["station nu"],
      station["leq night"],
      70,
      55,
      45,
      40,
    ]),
  ];

  const nightOptions = {
    title: "Night time Noise Level in Aol",
    chartArea: { width: "80%" },
    hAxis: {
      title: "NQ",
      minValue: 0,
    },
    vAxis: {
      title: "Noise Level (dB)",
    },
    // bars: "vertical",
    seriesType: "bars",
    series: {
      1: { type: "line", color: "#FF0000", lineWidth: 2 },
      2: { type: "line", color: "#FFA500", lineWidth: 2 },
      3: { type: "line", color: "#008000", lineWidth: 2 },
      4: { type: "line", color: "#0000FF", lineWidth: 2 },
    },
    legend: { position: "top" },
  };
  chartData.push({
    chartData: nightData,
    chartOptions: nightOptions,
    chartType: "ComboChart",
  });
  return chartData;
};

export const generateReportChartDataStructureForNoise = (rawData) => {
  let chartData = [];
  let dayOption = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
      },
      title: {
        display: true,
        text: 'Day time Noise Level in Aol',
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'NQ',
        },
      },
      y: {
        title: {
          display: true,
          text: "Noise Level (dB)",
        },
      },
    },
  };
  const dayData = {
    labels: rawData.map((station, ind) => station["station nu"]),
    datasets: [
      {
        type: "bar",
        label: "Noise Level",
        backgroundColor: 'rgba(255, 99, 132, 4)',
        borderColor: '#2d37bd',
        borderWidth: 1,
        hoverBackgroundColor: ["rgba(255, 99, 132, 0.4)"],
        hoverBorderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
        data: rawData.map((station) => station["leq day (d"]),
      },
      {
        type: 'line',
        label:"Industrial area-Day time noise standard",
        fill: false,
        lineTension: 0.5,
        backgroundColor: 'red',
        borderColor: 'red',
        borderWidth: 2,
        data: rawData.map((station) => 75)
      },
      {
        type: 'line',
        label:"Commercial area-Day time noise standard",
        fill: false,
        lineTension: 0.5,
        backgroundColor: 'green',
        borderColor: 'green',
        borderWidth: 2,
        data: rawData.map((station) => 65)
      },
      {
        type: 'line',
        label:"Residential area-Day time Standard",
        fill: false,
        lineTension: 0.5,
        backgroundColor: 'blue',
        borderColor: 'blue',
        borderWidth: 2,
        data: rawData.map((station) => 55)
      },
      {
        type: 'line',
        label: "Silence zone day time noise standard",
        fill: false,
        lineTension: 0.5,
        backgroundColor: '#380837',
        borderColor: '#380837',
        borderWidth: 2,
        data:rawData.map((station) => 50)
      },
    ],
  };
  chartData.push({ option: dayOption, data: dayData });
  let nightOption= {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
      },
      title: {
        display: true,
        text: 'Night time Noise Level in Aol',
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'NQ',
        },
      },
      y: {
        title: {
          display: true,
          text: "Noise Level (dB)",
        },
      },
    },
  };
  const nightData = {
    labels: rawData.map((station, ind) => station["station nu"]),
    datasets: [
      {
        type: "bar",
        label: "Noise Level",
        backgroundColor: 'rgba(255, 99, 132, 4)',
        borderColor: '#2d37bd',
        borderWidth: 1,
        hoverBackgroundColor: ["rgba(255, 99, 132, 0.4)"],
        hoverBorderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
        data: rawData.map((station) => station["leq night"]),
      },
      {
        type: 'line',
        label:"Industrial area-Night time noise standard",
        fill: false,
        lineTension: 0.5,
        backgroundColor: 'red',
        borderColor: 'red',
        borderWidth: 2,
        data: rawData.map((station) => 70)
      },
      {
        type: 'line',
        label:"Commercial area-Night time noise standard",
        fill: false,
        lineTension: 0.5,
        backgroundColor: 'green',
        borderColor: 'green',
        borderWidth: 2,
        data: rawData.map((station) => 55)
      },
      {
        type: 'line',
        label:"Residential area Night time Standard",
        fill: false,
        lineTension: 0.5,
        backgroundColor: 'blue',
        borderColor: 'blue',
        borderWidth: 2,
        data: rawData.map((station) => 45)
      },
      {
        type: 'line',
        label: "Silence zone Night time noise standard",
        fill: false,
        lineTension: 0.5,
        backgroundColor: '#380837',
        borderColor: '#380837',
        borderWidth: 2,
        data:rawData.map((station) => 40)
      },
    ],
  };
  chartData.push({ option: nightOption, data: nightData });
  return chartData
};
