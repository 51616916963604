let graph = [
  {
    headerName: "Sex Ratio",
    title: "Sex Ratio",
    dataKey: "sex_ratio",
    color: "green",
    ledgendLebel: [],
  },
  {
    headerName: "Indigenous Population (%)",
    title: "Village wise ST Population in AOI",
    dataKey: "indigenous_population",
    color: "#076ea9",
    ledgendLebel: [],
  },
  {
    headerName: "Literacy Rate (%)",
    title: "Literacy Profile of the AOI",
    dataKey: ["village_name", "lit_rate", "m_lit_rate", "f_lit_rate"],
    color: ["#3da0da", "#d328c5", "#26dd78"],
    ledgendLebel: [
      "Village",
      "Literacy Rate",
      "Male Literacy Rate",
      "Female Literacy Rate",
    ],
  },
  {
    headerName: "Workers Population Rate and Female Workers (%)",
    title: "Employment Profile of the AOI",
    dataKey: ["village_name", "worker_population_rate", "worker_population_rate_m", "worker_population_rate_f","worker_percentage_f"],
    color: ["#004166", "#ff6600", "#024b22","#3da0da"],
    ledgendLebel: [
      "Village",
      "Workers Population Rate (in %)",
      "Worker Population Rate (Male) (in %)",
      "Worker Population Rate (Female) (in %)",
      "Female Workers (in %)",
    ],
  },
  {
    headerName: "Working Population Profile",
    title: "Working Population Profile of the AOI",
    dataKey: ["village_name", "indu_workers_percentage", "tot_ag_workers_percentage"],
    color: ["#69c6fc", "#ff6600"],
    ledgendLebel: [
      "Village",
      "Industry/Infrastructure Workers (%)",
      "Agriculture based workers (%)",
    ],
  },
];

let tableList = [
  {
    tableName: "Demographic Profile",
    columnName: [
      { header: "Settlement Name", dataKey: "village_name" },
      // { header: "Total Geographical Area (in hectares)", dataKey: "village_name" },
      { header: "Total Population", dataKey: "tot_p" },
      { header: "Total Male Population", dataKey: "tot_m" },
      { header: "Total Female Population", dataKey: "tot_f" },
      // { header: "Population Density (persons/hectare)", dataKey: "village_name" },
      { header: "Sex Ratio", dataKey: "sex_ratio" },
      { header: "ST Population", dataKey: "p_st" },
      { header: "Indigenous Population (%)", dataKey: "indigenous_population" },
    ],
  },
  {
    tableName: "Literacy Profile & Education Infrastructure",
    columnName: [
      { header: "Settlement Name", dataKey: "village_name" },
      { header: "Total Literate", dataKey: "p_lit" },
      { header: "Total Male Literate", dataKey: "m_lit" },
      { header: "Total Female Literate", dataKey: "f_lit" },
      { header: "Literacy Rate", dataKey: "lit_rate" },
      { header: "Male Literacy Rate", dataKey: "m_lit_rate" },
      { header: "Female Literacy Rate", dataKey: "f_lit_rate" },
    ],
  },
  {
    tableName: "Employment and Working Population",
    columnName: [
      { header: "Settlement Name", dataKey: "village_name" },
      // { header: "Total Geographical Area (in hectares)", dataKey: "village_name" },
      { header: "Total Population", dataKey: "tot_p" },
      { header: "Total Male Population", dataKey: "tot_m" },
      { header: "Total Female Population", dataKey: "tot_f" },
      {
        header: "Population in age gourp 0-6 years (Persons)",
        dataKey: "p_06",
      },
      { header: "Population in age gourp 0-6 years (Males)", dataKey: "m_06" },
      {
        header: "Population in age gourp 0-6 years (Females)",
        dataKey: "f_06",
      },
      { header: "Total Workers (Persons)", dataKey: "tot_work_p" },
      { header: "Total Workers (Males)", dataKey: "tot_work_m" },
      { header: "Total Workers (Females)", dataKey: "tot_work_f" },
      {
        header: "Total Non workers excluding 0-6 years",
        dataKey: "tot_non_workers_exc_0_6",
      },
      {
        header: "Total Male Non workers excluding 0-6 years",
        dataKey: "tot_non_workers_m_exc_0_6",
      },
      {
        header: "Total Female Non workers excluding 0-6 years",
        dataKey: "tot_non_workers_f_exc_0_6",
      },
      {
        header: "Workers Population Rate (in %)",
        dataKey: "worker_population_rate",
      },
      {
        header: "Worker Population Rate (Male) (in %)",
        dataKey: "worker_population_rate_m",
      },
      {
        header: "Worker Population Rate (Female) (in %)",
        dataKey: "worker_population_rate_f",
      },
      { header: "Female Workers (in %)", dataKey: "worker_percentage_f" },
    ],
  },
  {
    tableName: "Employment and Working Population",
    columnName: [
      { header: "Settlement Name", dataKey: "village_name" },
      { header: "Total Workers (Persons)", dataKey: "tot_work_p" },
      { header: "Main Workers - Cultivators (Persons)", dataKey: "main_cl_p" },
      {
        header: "Main Workers - Agricultural Labourers  (Persons)",
        dataKey: "main_al_p",
      },
      {
        header: "Marginal Workers - Cultivators (Persons)",
        dataKey: "marg_cl_p",
      },
      {
        header: "Marginal Workers - Agricultural Labourers  (Persons)",
        dataKey: "marg_al_p",
      },
      { header: "Total Agriculture based workers", dataKey: "tot_ag_workers" },
      {
        header: "Agriculture based workers (%)",
        dataKey: "tot_ag_workers_percentage",
      },
      {
        header: "Main Workers - Other Workers (Persons)",
        dataKey: "main_ot_p",
      },
      {
        header: "Marginal Workers - Other Workers (Persons)",
        dataKey: "marg_ot_p",
      },
      {
        header: "Total Industry/Infrastructure Workers",
        dataKey: "tot_indu_workers",
      },
      {
        header: "Industry/Infrastructure Workers (%)",
        dataKey: "indu_workers_percentage",
      },
    ],
  },
];
export const createChartDataForCensus2011 = (rowData) => {
  console.log(rowData);
  let chartData = [];
  for (let item of graph) {
    if ((item.headerName === "Literacy Rate (%)")||(item.headerName === "Workers Population Rate and Female Workers (%)")||(item.headerName === "Working Population Profile")) {
      const data = [
        item.ledgendLebel,
        ...rowData.map((ele) => {
          return item.dataKey.map((k)=>{
            return ele[k]
          })
          // return [
          //   ele[item.dataKey[0]],
          //   ele[item.dataKey[1]],
          //   ele[item.dataKey[2]],
          //   ele[item.dataKey[3]],
          // ];
        }),
      ];
      const options = {
        title: `${item.title}`,
        //title: "Literacy Profile of the AOI",
        chartArea: { width: "80%" },
        hAxis: {
          title: "Village Name",
          minValue: 0,
        },
        vAxis: {
          title: `${item.headerName}`,
          //title: "Literacy Rate (%)",
        },
        legend: { position: "top" },
        colors: item.color,
        seriesType: "bars",
      };
      chartData.push({
        chartData: data,
        chartOptions: options,
        chartType: "ComboChart",
      });
    } else {
      const data = [
        ["Village Name", `${item.headerName}`, { role: "style" }],
        ...rowData.map((ele) => {
          return [ele.village_name, ele[item.dataKey], `${item.color}`];
        }),
      ];
      const options = {
        title: `${item.title}`,
        chartArea: { width: "80%" },
        hAxis: {
          title: "Village Name",
          minValue: 0,
        },
        vAxis: {
          title: `${item.headerName}`,
        },
        legend: { position: "top" },
        colors: [`${item.color}`],
      };
      chartData.push({
        chartData: data,
        chartOptions: options,
        chartType: "ColumnChart",
      });
    }
  }
  return chartData;
};

export const createReportChartDataForCensus2011 = (rowData) => {
  let reportChartData = [];
  for (let item of graph) {
    if (item.headerName === "Literacy Rate (%)"||(item.headerName === "Workers Population Rate and Female Workers (%)")||(item.headerName === "Working Population Profile")) {
      let newDataKeyList=item.dataKey.filter((k,i)=>i!==0)
      const data = {
        labels: rowData.map((ele) => ele[item.dataKey[0]]), // Village Names
        datasets: [
          ...newDataKeyList.map((key,ind)=>{
            return {
              label:item.ledgendLebel[ind+1],
              data: rowData.map((ele) => ele[key]),
              backgroundColor: item.color[ind],
              //stack: true,
            }
          })
          // {
          //   label: "Literacy Rate",
          //   data: rowData.map((ele) => ele[item.dataKey[1]]),
          //   backgroundColor: item.color[0],
          //   //stack: true,
          // },
          // {
          //   label: "Male Literacy Rate",
          //   data: rowData.map((ele) => ele[item.dataKey[2]]),
          //   backgroundColor: item.color[1],
          //   //stack: true,
          // },
          // {
          //   label: "Female Literacy Rate",
          //   data: rowData.map((ele) => ele[item.dataKey[3]]),
          //   backgroundColor: item.color[2],
          //   //stack: true,
          // },
        ],
      };

      // Options for the chart
      const options = {
        responsive: true,
        plugins: {
          legend: {
            position: "top",
          },
          title: {
            display: true,
            text: item.title,
          },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: "Village Name",
            },
          },
          y: {
            title: {
              display: true,
              text: item.headerName,
            },
            beginAtZero: true,
          },
        },
      };
      reportChartData.push({ data, options });
    } else {
      const data = {
        labels: rowData.map((ele) => ele.village_name),
        datasets: [
          {
            label: `${item.headerName}`,
            data: rowData.map((ele) => ele[item.dataKey]),
            backgroundColor: `${item.color}`,
          },
        ],
      };

      const options = {
        responsive: true,
        plugins: {
          legend: {
            position: "top",
            labels: {
              color: "green",
            },
          },
          title: {
            display: true,
            text: `${item.title}`,
          },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: "Village Name",
            },
          },
          y: {
            title: {
              display: true,
              text: `${item.headerName}`,
            },
            min: 0,
          },
        },
      };
      reportChartData.push({ data, options });
    }
  }
  return reportChartData;
};

export const createTableDataStructureForCensus2011 = (rowData) => {
  return tableList.map((ele) => {
    return {
      data: rowData,
      columnName: ele.columnName,
      tableHeader: ele.tableName,
    };
  });
};
