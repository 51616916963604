import React, { useEffect, useState } from "react";
import { material } from "../../library/material";
import { SocioIconomicSDropdown } from "../../utils/socioEconomicDropdown";
import { Controller, useFieldArray } from "react-hook-form";

function SocioEconomicDetailsForm({
  control,
  watch,
  register,
  setValue,
  parentIndex,
  parentName,
}) {
  const {
    religionList,
    castList,
    relationshipWithHeadOfHouseholdList,
    maritalStatusList,
    literacyList,
    discontinuingReasonsList,
    educationLevelList,
    disabilityList,
    livelihoodList,
    skillSetList,
    sexList,
    vulnerabilityOptions,
    expenditureItems,
    incomeItems,
  } = new SocioIconomicSDropdown();
  const {
    fields: householdMemberInfoField,
    append: appendMember,
    remove: removeMember,
  } = useFieldArray({
    control: control,
    name:
      parentIndex >= 0
        ? `${parentName}[${parentIndex}].socioEconomicDetails.householdMemberDetails`
        : "socioEconomicDetails.householdMemberDetails",
  });

  const {
    fields: householdExpenditureField,
    append: appendExpenditure,
    remove: removeExpenditure,
  } = useFieldArray({
    control: control,
    name:
      parentIndex >= 0
        ? `${parentName}[${parentIndex}].socioEconomicDetails.expenditures`
        : "socioEconomicDetails.expenditures",
    // Initialize with external data
    // keyName: "itemName", // Use 'itemName' as the unique key
    defaultValues: expenditureItems.map((item) => ({ ...item })),
  });

  const {
    fields: householdIncomeField,
    append: appendIncome,
    remove: removeIncome,
  } = useFieldArray({
    control: control,
    name:
      parentIndex >= 0
        ? `${parentName}[${parentIndex}].socioEconomicDetails.incomeInfo`
        : "socioEconomicDetails.incomeInfo",
    // Initialize with external data
    // keyName: "itemName", // Use 'itemName' as the unique key
    defaultValues: incomeItems.map((item) => ({ ...item })),
  });

  const [selectedOptions, setSelectedOptions] = useState([]);
  useEffect(() => {
    setValue(
      parentIndex >= 0
        ? `${parentName}[${parentIndex}].socioEconomicDetails.expenditures`
        : "socioEconomicDetails.expenditures",
      [...expenditureItems]
    );
    setValue(
      parentIndex >= 0
        ? `${parentName}[${parentIndex}].socioEconomicDetails.incomeInfo`
        : "socioEconomicDetails.incomeInfo",
      [...incomeItems]
    );
  }, []);
  const handleChange = (event) => {
    const {
      target: { value: selectedValues },
    } = event;
    setSelectedOptions(selectedValues);
  };

  console.log(`${parentName}`);

  return (
    <div className="row">
      {/* Basic Information Start*/}
      <div className="col-12">
        <div className="row border border-3 border-secondary rounded-2 p-2">
          <div
            className="col-sm-12 p-2 fw-bold text-dark fs-4 rounded-2"
            style={{ backgroundColor: "rgb(153 246 228)" }}
          >
            A. Basic Information
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4 px-2 mt-3">
            <material.TextField
              name="headOfHouseholdName"
              {...register(
                parentIndex >= 0
                  ? `${parentName}[${parentIndex}].socioEconomicDetails.basicInformation.headOfHouseholdName`
                  : `socioEconomicDetails.basicInformation.headOfHouseholdName`,
                { required: true }
              )}
              label="Name of Head of Household "
              id="outlined-size-small"
              size="small"
              type="text"
              fullWidth
              variant="standard"
              className="w-100"
              required
            />
          </div>

          <div className="col-sm-12 col-md-6 col-lg-4 px-2 mt-3">
            <material.FormControl
              size="small"
              variant="standard"
              className="w-100"
            >
              <material.InputLabel id="demo-select-small-label">
                Religion
              </material.InputLabel>
              <material.Select
                name="religion"
                {...register(
                  parentIndex >= 0
                    ? `${parentName}[${parentIndex}].socioEconomicDetails.basicInformation.religion`
                    : `socioEconomicDetails.basicInformation.religion`,
                  { required: true }
                )}
                labelId="demo-select-small-label"
                id="demo-select-small"
                // value={selectedRelation}
                label="Select an option"
                // onChange={onChangeRelationship}
                required
              >
                {religionList.map((ele, i) => (
                  <material.MenuItem key={i} value={ele.value}>
                    {ele.name}
                  </material.MenuItem>
                ))}
              </material.Select>
            </material.FormControl>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4 px-2 mt-3">
            <material.FormControl
              size="small"
              variant="standard"
              className="w-100"
            >
              <material.InputLabel id="demo-select-small-label">
                Caste
              </material.InputLabel>
              <material.Select
                name="caste"
                {...register(
                  parentIndex >= 0
                    ? `${parentName}[${parentIndex}].socioEconomicDetails.basicInformation.caste`
                    : `socioEconomicDetails.basicInformation.caste`,
                  { required: true }
                )}
                labelId="demo-select-small-label"
                id="demo-select-small"
                // value={selectedRelation}
                label="Select an option"
                // onChange={onChangeRelationship}
                required
              >
                {castList.map((ele, i) => (
                  <material.MenuItem key={i} value={ele.value}>
                    {ele.name}
                  </material.MenuItem>
                ))}
              </material.Select>
            </material.FormControl>
          </div>
          <div className="col-sm-12 px-2 mt-3">
            <div className="row">
              <div className="col-12">
                <material.InputLabel
                  id="demo-select-small-label"
                  className="fw-bold"
                >
                  Number of Household Members
                </material.InputLabel>
              </div>
              <div className="col-6">
                <material.TextField
                  name="male"
                  {...register(
                    parentIndex >= 0
                      ? `${parentName}[${parentIndex}].socioEconomicDetails.basicInformation.male`
                      : `socioEconomicDetails.basicInformation.male`,
                    { required: true }
                  )}
                  label="Number of male"
                  id="outlined-size-small"
                  size="small"
                  type="number"
                  fullWidth
                  variant="standard"
                  className="w-100"
                  required
                />
              </div>
              <div className="col-6">
                {" "}
                <material.TextField
                  name="female"
                  {...register(
                    parentIndex >= 0
                      ? `${parentName}[${parentIndex}].socioEconomicDetails.basicInformation.female`
                      : `socioEconomicDetails.basicInformation.female`,
                    { required: true }
                  )}
                  label="Number of female"
                  id="outlined-size-small"
                  size="small"
                  type="number"
                  fullWidth
                  variant="standard"
                  className="w-100"
                  required
                />
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4 px-2 mt-3">
            <material.TextField
              name="socialGroupName"
              {...register(
                parentIndex >= 0
                  ? `${parentName}[${parentIndex}].socioEconomicDetails.basicInformation.socialGroupName`
                  : `socioEconomicDetails.basicInformation.socialGroupName`,
                { required: true }
              )}
              label="Name Of Social Group"
              id="outlined-size-small"
              size="small"
              type="text"
              fullWidth
              variant="standard"
              className="w-100"
              required
            />
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4 px-2 mt-3">
            <material.FormControl
              size="small"
              variant="standard"
              className="w-100"
            >
              <material.InputLabel id="demo-select-small-label">
                Vulnerability (tick as many as applicable)
              </material.InputLabel>
              <material.Select
                name="vulnerability"
                {...register(
                  parentIndex >= 0
                    ? `${parentName}[${parentIndex}].socioEconomicDetails.basicInformation.vulnerability`
                    : `socioEconomicDetails.basicInformation.vulnerability`,
                  { required: true }
                )}
                labelId="demo-select-small-label"
                id="demo-select-small"
                label="Select an option"
                required
                multiple
                value={selectedOptions}
                onChange={handleChange}
                renderValue={(selected) => (
                  <div>
                    {selected.map((value) => (
                      <span key={value}>{value}, </span>
                    ))}
                  </div>
                )}
              >
                {vulnerabilityOptions.map((ele, i) => (
                  <material.MenuItem key={i} value={ele.value}>
                    <material.Checkbox
                      checked={selectedOptions.includes(ele.value)}
                      color="primary"
                    />
                    <material.ListItemText primary={ele.name} />
                  </material.MenuItem>
                ))}
              </material.Select>
            </material.FormControl>
          </div>
        </div>
      </div>
      {/* Basic Information End*/}
      {/*SECTION A.1. INFORMATION ABOUT THE MEMBERS OF THE HOUSEHOLD START*/}
      <div className="col-12 mt-2">
        <div className="row border border-3 border-secondary rounded-2 p-2">
          <div
            className="col-sm-12 p-2 fw-bold text-dark fs-4 rounded-2"
            style={{ backgroundColor: "rgb(153 246 228)" }}
          >
            A.1. Information About The Members Of The Household
          </div>
          <div className="col-12 mt-1">
            <span className="float-end">
              <material.Button
                type="button"
                variant="contained"
                onClick={() => appendMember({})}
              >
                Add Member
              </material.Button>
            </span>
          </div>
          <div className="col-12">
            {householdMemberInfoField.map((field, index) => (
              <div
                key={field.id}
                className="row py-2 border boder-2 border-success rounded-2 mt-2"
              >
                <div className="col-12">
                  <material.Button
                    type="button"
                    variant="contained"
                    color="error"
                    // hidden={watch("numberOfLandUser") == index - 1}
                    onClick={() => removeMember(index)}
                  >
                    Remove
                  </material.Button>
                </div>

                <div className="col-4 col-sm-12 col-md-6">
                  <material.TextField
                    // {...register(
                    //   `socioEconomicDetails[${parentIndex>=0}].householdMemberDetails[${index}].fullName`,
                    //   {
                    //     required: true,
                    //   }
                    // )}
                    {...register(
                      parentIndex >= 0
                        ? `${parentName}[${parentIndex}].socioEconomicDetails.householdMemberDetails[${index}].fullName`
                        : `socioEconomicDetails.householdMemberDetails[${index}].fullName`,
                      { required: true }
                    )}
                    label="Full Name"
                    variant="standard"
                    fullWidth
                    size="small"
                  />
                </div>
                <div className="col-4 col-sm-12 col-md-6">
                  <material.FormControl
                    variant="standard"
                    fullWidth
                    size="small"
                  >
                    <material.InputLabel id={`type-label-${index}`}>
                      Relationship with HOH
                    </material.InputLabel>
                    <material.Select
                      labelId={`type-label-${index}`}
                      // {...register(
                      //   `householdMemberDetails[${index}].relationshipWithHoh`,
                      //   { required: true }
                      // )}
                      {...register(
                        parentIndex >= 0
                          ? `${parentName}[${parentIndex}].socioEconomicDetails.householdMemberDetails[${index}].relationshipWithHoh`
                          : `socioEconomicDetails.householdMemberDetails[${index}].relationshipWithHoh`,
                        { required: true }
                      )}
                    >
                      {relationshipWithHeadOfHouseholdList.map((ele, ind) => (
                        <material.MenuItem key={ind} value={ele.value}>
                          {ele.name}
                        </material.MenuItem>
                      ))}
                    </material.Select>
                  </material.FormControl>
                </div>
                <div className="col-4 col-sm-12 col-md-6">
                  <material.FormControl
                    variant="standard"
                    fullWidth
                    size="small"
                  >
                    <material.InputLabel id={`duration-label-${index}`}>
                      Sex
                    </material.InputLabel>
                    <material.Select
                      labelId={`duration-label-${index}`}
                      // {...register(`householdMemberDetails[${index}].sex`, {
                      //   required: true,
                      // })}
                      {...register(
                        parentIndex >= 0
                          ? `${parentName}[${parentIndex}].socioEconomicDetails.householdMemberDetails[${index}].sex`
                          : `socioEconomicDetails.householdMemberDetails[${index}].sex`,
                        { required: true }
                      )}
                    >
                      {sexList.map((ele, ind) => (
                        <material.MenuItem key={ind} value={ele.value}>
                          {ele.name}
                        </material.MenuItem>
                      ))}
                    </material.Select>
                  </material.FormControl>
                </div>
                <div className="col-4 col-sm-12 col-md-6">
                  <material.TextField
                    // {...register(`householdMemberDetails[${index}].age`, {
                    //   required: true,
                    // })}
                    {...register(
                      parentIndex >= 0
                        ? `${parentName}[${parentIndex}].socioEconomicDetails.householdMemberDetails[${index}].age`
                        : `socioEconomicDetails.householdMemberDetails[${index}].age`,
                      { required: true }
                    )}
                    label="Age"
                    variant="standard"
                    fullWidth
                    size="small"
                  />
                </div>
                <div className="col-4 col-sm-12 col-md-6">
                  <material.FormControl
                    variant="standard"
                    fullWidth
                    size="small"
                  >
                    <material.InputLabel id={`duration-label-${index}`}>
                      Marital Status
                    </material.InputLabel>
                    <material.Select
                      labelId={`duration-label-${index}`}
                      // {...register(
                      //   `householdMemberDetails[${index}].maritalStatus`,
                      //   {
                      //     required: true,
                      //   }
                      // )}
                      {...register(
                        parentIndex >= 0
                          ? `${parentName}[${parentIndex}].socioEconomicDetails.householdMemberDetails[${index}].maritalStatus`
                          : `socioEconomicDetails.householdMemberDetails[${index}].maritalStatus`,
                        { required: true }
                      )}
                    >
                      {maritalStatusList.map((ele, ind) => (
                        <material.MenuItem key={ind} value={ele.value}>
                          {ele.name}
                        </material.MenuItem>
                      ))}
                    </material.Select>
                  </material.FormControl>
                </div>
                <div className="col-4 col-sm-12 col-md-6">
                  <material.FormControl
                    variant="standard"
                    fullWidth
                    size="small"
                  >
                    <material.InputLabel id={`duration-label-${index}`}>
                      Literacy
                    </material.InputLabel>
                    <material.Select
                      labelId={`duration-label-${index}`}
                      // {...register(
                      //   `householdMemberDetails[${index}].literacy`,
                      //   {
                      //     required: true,
                      //   }
                      // )}
                      {...register(
                        parentIndex >= 0
                          ? `${parentName}[${parentIndex}].socioEconomicDetails.householdMemberDetails[${index}].literacy`
                          : `socioEconomicDetails.householdMemberDetails[${index}].literacy`,
                        { required: true }
                      )}
                    >
                      {literacyList.map((ele, ind) => (
                        <material.MenuItem key={ind} value={ele.value}>
                          {ele.name}
                        </material.MenuItem>
                      ))}
                    </material.Select>
                  </material.FormControl>
                </div>

                <div className="col-4 col-sm-12 col-md-6 d-flex flex-row align-items-center">
                  <label
                    id="demo-row-radio-buttons-group-label"
                    className="me-2"
                  >
                    Currently Studying?
                  </label>
                  <Controller
                    // name={`householdMemberDetails[${index}].currentlyStudying`}
                    name={
                      parentIndex >= 0
                        ? `${parentName}[${parentIndex}].socioEconomicDetails.householdMemberDetails[${index}].currentlyStudying`
                        : `socioEconomicDetails.householdMemberDetails[${index}].currentlyStudying`
                    }
                    control={control}
                    defaultValue=""
                    rules={{ required: "Please select an option" }}
                    render={({ field }) => (
                      <material.RadioGroup
                        row
                        aria-label="selectedOption"
                        {...field}
                      >
                        <material.FormControlLabel
                          value="true"
                          control={<material.Radio />}
                          label="Yes"
                        />
                        <material.FormControlLabel
                          value="false"
                          control={<material.Radio />}
                          label="No"
                        />
                      </material.RadioGroup>
                    )}
                  />
                </div>
                {watch(
                  parentIndex >= 0
                    ? `${parentName}[${parentIndex}].socioEconomicDetails.householdMemberDetails[${index}].currentlyStudying`
                    : `socioEconomicDetails.householdMemberDetails[${index}].currentlyStudying`
                ) == "false" && (
                  <>
                    <div className="col-4 col-sm-12 col-md-6">
                      <material.FormControl
                        variant="standard"
                        fullWidth
                        size="small"
                      >
                        <material.InputLabel id={`duration-label-${index}`}>
                          Reason for Discontinuing of education
                        </material.InputLabel>
                        <material.Select
                          labelId={`duration-label-${index}`}
                          {...register(
                            parentIndex >= 0
                              ? `${parentName}[${parentIndex}].socioEconomicDetails.householdMemberDetails[${index}].educationDiscontinueReson`
                              : `socioEconomicDetails.householdMemberDetails[${index}].educationDiscontinueReson`,
                            {
                              required: true,
                            }
                          )}
                        >
                          {discontinuingReasonsList.map((ele, ind) => (
                            <material.MenuItem key={ind} value={ele.value}>
                              {ele.name}
                            </material.MenuItem>
                          ))}
                        </material.Select>
                      </material.FormControl>
                    </div>
                  </>
                )}
                <div className="col-4 col-sm-12 col-md-6">
                  <material.FormControl
                    variant="standard"
                    fullWidth
                    size="small"
                  >
                    <material.InputLabel id={`duration-label-${index}`}>
                      Last level of education completed
                    </material.InputLabel>
                    <material.Select
                      labelId={`duration-label-${index}`}
                      // {...register(
                      //   `householdMemberDetails[${index}].lastLevelOfEducation`,
                      //   {
                      //     required: true,
                      //   }
                      // )}
                      {...register(
                        parentIndex >= 0
                          ? `${parentName}[${parentIndex}].socioEconomicDetails.householdMemberDetails[${index}].lastLevelOfEducation`
                          : `socioEconomicDetails.householdMemberDetails[${index}].lastLevelOfEducation`,
                        {
                          required: true,
                        }
                      )}
                    >
                      {educationLevelList.map((ele, ind) => (
                        <material.MenuItem key={ind} value={ele.value}>
                          {ele.name}
                        </material.MenuItem>
                      ))}
                    </material.Select>
                  </material.FormControl>
                </div>
                <div className="col-4 col-sm-12 col-md-6">
                  <material.FormControl
                    variant="standard"
                    fullWidth
                    size="small"
                  >
                    <material.InputLabel id={`duration-label-${index}`}>
                      Disability
                    </material.InputLabel>
                    <material.Select
                      labelId={`duration-label-${index}`}
                      // {...register(
                      //   `householdMemberDetails[${index}].disability`,
                      //   {
                      //     required: true,
                      //   }
                      // )}
                      {...register(
                        parentIndex >= 0
                          ? `${parentName}[${parentIndex}].socioEconomicDetails.householdMemberDetails[${index}].disability`
                          : `socioEconomicDetails.householdMemberDetails[${index}].disability`,
                        {
                          required: true,
                        }
                      )}
                    >
                      {disabilityList.map((ele, ind) => (
                        <material.MenuItem key={ind} value={ele.value}>
                          {ele.name}
                        </material.MenuItem>
                      ))}
                    </material.Select>
                  </material.FormControl>
                </div>
                <div className="col-4 col-sm-12 col-md-6">
                  <material.FormControl
                    variant="standard"
                    fullWidth
                    size="small"
                  >
                    <material.InputLabel id={`duration-label-${index}`}>
                      Primary Occupation
                    </material.InputLabel>
                    <material.Select
                      labelId={`duration-label-${index}`}
                      {...register(
                        parentIndex >= 0
                          ? `${parentName}[${parentIndex}].socioEconomicDetails.householdMemberDetails[${index}].primaryOccupation`
                          : `socioEconomicDetails.householdMemberDetails[${index}].primaryOccupation`,
                        { required: true }
                      )}
                    >
                      {livelihoodList.map((ele, ind) => (
                        <material.MenuItem key={ind} value={ele.value}>
                          {ele.name}
                        </material.MenuItem>
                      ))}
                    </material.Select>
                  </material.FormControl>
                </div>
                <div className="col-4 col-sm-12 col-md-6">
                  <material.FormControl
                    variant="standard"
                    fullWidth
                    size="small"
                  >
                    <material.InputLabel id={`duration-label-${index}`}>
                      Secondary Occupation
                    </material.InputLabel>
                    <material.Select
                      labelId={`duration-label-${index}`}
                      // {...register(
                      //   `householdMemberDetails[${index}].secondaryOccupation`,
                      //   {
                      //     required: true,
                      //   }
                      // )}
                      {...register(
                        parentIndex >= 0
                          ? `${parentName}[${parentIndex}].socioEconomicDetails.householdMemberDetails[${index}].secondaryOccupation`
                          : `socioEconomicDetails.householdMemberDetails[${index}].secondaryOccupation`,
                        { required: true }
                      )}
                    >
                      {livelihoodList.map((ele, ind) => (
                        <material.MenuItem key={ind} value={ele.value}>
                          {ele.name}
                        </material.MenuItem>
                      ))}
                    </material.Select>
                  </material.FormControl>
                </div>
                <div className="col-4 col-sm-12 col-md-6">
                  <material.FormControl
                    variant="standard"
                    fullWidth
                    size="small"
                  >
                    <material.InputLabel id={`duration-label-${index}`}>
                      Any Existing Skill Set
                    </material.InputLabel>
                    <material.Select
                      labelId={`duration-label-${index}`}
                      // {...register(
                      //   `householdMemberDetails[${index}].skillSet `,
                      //   {
                      //     required: true,
                      //   }
                      // )}
                      {...register(
                        parentIndex >= 0
                          ? `${parentName}[${parentIndex}].socioEconomicDetails.householdMemberDetails[${index}].skillSet`
                          : `socioEconomicDetails.householdMemberDetails[${index}].skillSet`,
                        { required: true }
                      )}
                    >
                      {skillSetList.map((ele, ind) => (
                        <material.MenuItem key={ind} value={ele.value}>
                          {ele.name}
                        </material.MenuItem>
                      ))}
                    </material.Select>
                  </material.FormControl>
                </div>
                <div className="col-4 col-sm-12 col-md-6 d-flex flex-row align-items-center">
                  <label
                    id="demo-row-radio-buttons-group-label"
                    className="me-2"
                  >
                    Do you have a certificate for any training received ?
                  </label>
                  <Controller
                    // name={`householdMemberDetails[${index}].currentlyStudying`}
                    name={
                      parentIndex >= 0
                        ? `${parentName}[${parentIndex}].socioEconomicDetails.householdMemberDetails[${index}].isAnyTrainingCertificatePresent`
                        : `socioEconomicDetails.householdMemberDetails[${index}].isAnyTrainingCertificatePresent`
                    }
                    control={control}
                    defaultValue=""
                    rules={{ required: "Please select an option" }}
                    render={({ field }) => (
                      <material.RadioGroup
                        row
                        aria-label="selectedOption"
                        {...field}
                      >
                        <material.FormControlLabel
                          value="true"
                          control={<material.Radio />}
                          label="Yes"
                        />
                        <material.FormControlLabel
                          value="false"
                          control={<material.Radio />}
                          label="No"
                        />
                      </material.RadioGroup>
                    )}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/*SECTION A.1. INFORMATION ABOUT THE MEMBERS OF THE HOUSEHOLD END*/}
      {/*SECTION B.1– HH EXPENDITURE (In Rupee) (Preferably to be undertaken in presence of both men and women of the household) start*/}
      <div className="col-12 mt-2">
        <div className="row border border-3 border-secondary rounded-2 p-2">
          <div
            className="col-sm-12 p-2 fw-bold text-dark fs-4 rounded-2"
            style={{ backgroundColor: "rgb(153 246 228)" }}
          >
            B.1– HH EXPENDITURE (In Rupee) (Preferably to be undertaken in
            presence of both men and women of the household){" "}
          </div>
          <div className="col-12">
            <material.TableContainer
              component={material.Paper}
              className="w-100 mt-1 p-0"
            >
              <material.Table size="small">
                <material.TableHead>
                  <material.TableRow>
                    <material.TableCell>Expenditure Item</material.TableCell>
                    <material.TableCell>
                      Frequency of Expenditure (write the frequency in numbers)
                    </material.TableCell>
                    <material.TableCell>
                      Expenditure in Rupee (Unit cost)
                    </material.TableCell>
                    <material.TableCell>
                      Total expense (System Generated)
                    </material.TableCell>
                  </material.TableRow>
                </material.TableHead>
                <material.TableBody>
                  {householdExpenditureField.map((item, expenditureIndex) => (
                    <material.TableRow key={item.itemName}>
                      <material.TableCell>{item.itemName}</material.TableCell>
                      <material.TableCell>
                        <material.TextField
                          // {...register(
                          //   `expenditures[${index}].frequencyOfExpenditure`
                          // )}
                          {...register(
                            parentIndex >= 0
                              ? `${parentName}[${parentIndex}].socioEconomicDetails.expenditures[${expenditureIndex}].frequencyOfExpenditure`
                              : `socioEconomicDetails.expenditures[${expenditureIndex}].frequencyOfExpenditure`,
                            { required: true }
                          )}
                          defaultValue={item.frequencyOfExpenditure}
                          type="number"
                          size="small"
                        />
                      </material.TableCell>
                      <material.TableCell>
                        <material.TextField
                          // {...register(
                          //   `expenditures[${index}].expenditureInRupee`
                          // )}
                          {...register(
                            parentIndex >= 0
                              ? `${parentName}[${parentIndex}].socioEconomicDetails.expenditures[${expenditureIndex}].expenditureInRupee`
                              : `socioEconomicDetails.expenditures[${expenditureIndex}].expenditureInRupee`,
                            { required: true }
                          )}
                          defaultValue={item.expenditureInRupee}
                          type="number"
                          size="small"
                        />
                      </material.TableCell>
                      <material.TableCell>
                        <material.TextField
                          // {...register(`expenditures[${index}].totalExpense`)}
                          {...register(
                            parentIndex >= 0
                              ? `${parentName}[${parentIndex}].socioEconomicDetails.expenditures[${expenditureIndex}].totalExpense`
                              : `socioEconomicDetails.expenditures[${expenditureIndex}].totalExpense`,
                            { required: true }
                          )}
                          defaultValue={item.expenditureInRupee}
                          type="number"
                          size="small"
                        />
                      </material.TableCell>
                    </material.TableRow>
                  ))}
                </material.TableBody>
              </material.Table>
            </material.TableContainer>
          </div>
        </div>
      </div>
      {/*SECTION B.1– HH EXPENDITURE (In Rupee) (Preferably to be undertaken in presence of both men and women of the household) end*/}
      {/* SECTION B.2: SOURCES OF HOUSEHOLD INCOME (IN Rupee) (Preferably to be undertaken in presence of both men and women of the household) start*/}
      <div className="col-12 mt-2">
        <div className="row border border-3 border-secondary rounded-2 p-2">
          <div
            className="col-sm-12 p-2 fw-bold text-dark fs-4 rounded-2"
            style={{ backgroundColor: "rgb(153 246 228)" }}
          >
            B.1– HH EXPENDITURE (In Rupee) (Preferably to be undertaken in
            presence of both men and women of the household){" "}
          </div>
          <div className="col-12">
            <material.TableContainer
              component={material.Paper}
              className="w-100 mt-1 p-0"
            >
              <material.Table size="small">
                <material.TableHead>
                  <material.TableRow>
                    <material.TableCell>Source: Income</material.TableCell>
                    <material.TableCell>
                      i. Frequency of Unit (in numbers)
                    </material.TableCell>
                    <material.TableCell>
                      ii. Amount for one unit (in Rs)
                    </material.TableCell>
                    <material.TableCell>
                      iii. Total Income, System Generated
                    </material.TableCell>
                  </material.TableRow>
                </material.TableHead>
                <material.TableBody>
                  {householdIncomeField.map((item, incomeIndex) => (
                    <material.TableRow key={item.itemName}>
                      <material.TableCell>{item.itemName}</material.TableCell>
                      <material.TableCell>
                        <material.TextField
                          // {...register(`incomeInfo[${index}].frequencyOfUnit`)}
                          {...register(
                            parentIndex >= 0
                              ? `${parentName}[${parentIndex}].socioEconomicDetails.incomeInfo[${incomeIndex}].frequencyOfUnit`
                              : `socioEconomicDetails.incomeInfo[${incomeIndex}].frequencyOfUnit`,
                            { required: true }
                          )}
                          defaultValue={item.frequencyOfUnit}
                          type="number"
                          size="small"
                        />
                      </material.TableCell>
                      <material.TableCell>
                        <material.TextField
                          // {...register(`incomeInfo[${index}].amountForOneUnit`)}
                          {...register(
                            parentIndex >= 0
                              ? `${parentName}[${parentIndex}].socioEconomicDetails.incomeInfo[${incomeIndex}].amountForOneUnit`
                              : `socioEconomicDetails.incomeInfo[${incomeIndex}].amountForOneUnit`,
                            { required: true }
                          )}
                          defaultValue={item.amountForOneUnit}
                          type="number"
                          size="small"
                        />
                      </material.TableCell>
                      <material.TableCell>
                        <material.TextField
                          // {...register(`incomeInfo[${index}].totalIncome`)}
                          {...register(
                            parentIndex >= 0
                              ? `${parentName}[${parentIndex}].socioEconomicDetails.incomeInfo[${incomeIndex}].totalIncome`
                              : `socioEconomicDetails.incomeInfo[${incomeIndex}].totalIncome`,
                            { required: true }
                          )}
                          defaultValue={item.totalIncome}
                          type="number"
                          size="small"
                        />
                      </material.TableCell>
                    </material.TableRow>
                  ))}
                </material.TableBody>
              </material.Table>
            </material.TableContainer>
          </div>
        </div>
      </div>
      {/* SECTION B.2: SOURCES OF HOUSEHOLD INCOME (IN Rupee) (Preferably to be undertaken in presence of both men and women of the household) end */}
      {/* SECTION B.3  BANK ACCOUNT AND SAVING DETAILS Start */}

      {/* SECTION B.3  BANK ACCOUNT AND SAVING DETAILS End */}
    </div>
  );
}

export default SocioEconomicDetailsForm;
