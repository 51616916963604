import { type } from "@testing-library/user-event/dist/type";

export const createLuLcChartData = (data, totalArea) => {
  // Transform data to format required by Google Charts
  const chartData = [
    ["LULC Class", "Percentage", { role: "style" }],
    ...data.map(({ lulcClass, area, color }) => [
      lulcClass,
      (area / totalArea) * 100,
      color,
    ]),
  ];

  const slices = data.map(({ color }) => ({
    color: color,
    offset: 0.1, // Adjust this value to create gaps between slices
  }));

  const options = {
    title: "LULC Class Percentage",
    is3D: true,
    slices: slices,
    pieSliceText: "none", // Hide the slice text for a cleaner look
    //legend: { position: 'labeled' }  // Display the labels on the legend
  };

  return { chartData, options };
};

  export const createReportChartDataForLuLc = (rawData, totalArea) => {
    const data = {
      labels: rawData.map((item) => item.lulcClass),
      datasets: [
        {
          type:'pie',  
          data: rawData.map((item) => Number(((item.area / totalArea) * 100).toFixed(2))), // Percentages
          backgroundColor: rawData.map((item) => item.color),
          hoverOffset: 10,
          borderWidth: 2,
          borderColor: "#FFFFFF",
        },
      ],
    };
  
    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
        },
        tooltip: {
          callbacks: {
            label: function(tooltipItem) {
              const percentage = tooltipItem.raw;
              return `${tooltipItem.label}: ${percentage}%`;
            }
          }
        }
      }
    };
  
    return [{ options, data }];; // Return as an object instead of an array
  };
  

export const extractStyles = (html) => {
  const styleTags = [];
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");
  const styles = doc.querySelectorAll("style");

  styles.forEach((style) => {
    styleTags.push(style.innerHTML);
    style.remove();
  });
  // Remove the second table
  const tables = doc.querySelectorAll("table");
  if (tables.length > 1) {
    tables[1].remove();
  }
  // Remove the specific div
  const printDiv = doc.querySelector('div[style*="float:right"]');
  if (printDiv) {
    printDiv.remove();
  }
  return { styleTags, cleanHtml: doc.body.innerHTML };
};

export const applyStyles = (styles) => {
  const styleElement = document.createElement("style");
  styleElement.innerHTML = styles.join("\n");
  document.head.appendChild(styleElement);
};

export const extractTableData = (html) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");

  const rows = doc.querySelectorAll("#analysis_div table tr");
  const data = [];

  rows.forEach((row, index) => {
    // Skip header row
    if (index === 0) return;

    const cells = row.querySelectorAll("td");
    if (cells.length === 3) {
      const rowData = {
        color: cells[0].getAttribute("bgcolor"),
        lulcClass: cells[1].innerText.trim(),
        area: cells[2].innerText.trim(),
      };
      data.push(rowData);
    }
  });

  return data;
};
export const updateHtmlWithPercentages = (html, data, totalArea) => {
  // const totalArea = data.reduce((sum, item) => sum + item.area, 0);
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");

  const table = doc.querySelector("#analysis_div table");
  if (!table) return html; // Exit if no table is found

  const rows = table.querySelectorAll("tr");
  rows.forEach((row, index) => {
    if (index === 0) {
      // Add header for percentage column
      const headerCell = document.createElement("th");
      headerCell.innerHTML = "<b>Percentage of Area</b>";
      headerCell.rowSpan = 2;
      row.appendChild(headerCell);
      return;
    }

    const cells = row.querySelectorAll("td");
    if (cells.length === 3) {
      const lulcClass = cells[1].innerText.trim();
      const item = data.find((d) => d.lulcClass === lulcClass);
      if (item) {
        const percentage = ((item.area / totalArea) * 100).toFixed(2);
        const percentageCell = document.createElement("td");
        percentageCell.innerText = `${percentage}%`;
        row.appendChild(percentageCell);
      }
    }
  });

  return doc.body.innerHTML;
};
