import axios from "axios";
import { config } from "exceljs";

let navigateCallback;

export const setNavigateCallback = (callback) => {
    navigateCallback = callback;
};


export const axiosInstance = axios.create();
axiosInstance.interceptors.request.use(config=>{
    if(config.url.startsWith(process.env.REACT_APP_GEOSERVER_URL)){
        delete config.headers.Authorization;
    }
    console.log(config)
    return config
})
axiosInstance.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.response && error.response.status === 401) {
            setTimeout(() => {
                navigateCallback("/login")
            }, 3000)
        }
        return Promise.reject(error);
    }
);