export const generateRandomColors = (count,opacity=.5) => {
  const colors = [];

  for (let i = 0; i < count; i++) {
    const red = Math.floor(Math.random() * 256);
    const green = Math.floor(Math.random() * 256);
    const blue = Math.floor(Math.random() * 256);
    const color = `rgb(${red}, ${green}, ${blue},${opacity})`;
    colors.push(color);
  }

  return colors;
};
