import React from "react";
import "./Agriculture.css";
import { material } from "../../library/material";
import { useFieldArray } from "react-hook-form";
import { SocioIconomicSDropdown } from "../../utils/socioEconomicDropdown";

function Agriculture({ control, watch, register, setValue }) {
  const dropDownValue = new SocioIconomicSDropdown();
  const {
    fields: agricultureField,
    append: appendAgriculture,
    remove: removeAgriculture,
  } = useFieldArray({
    control: control,
    name: "agricultureDetails",
  });

  return (
    <>
      <div className="col-12 mt-3">
        <span className="float-end">
          <material.Button
            type="button"
            variant="contained"
            onClick={() => appendAgriculture()}
          >
            Add Agriculture Details
          </material.Button>
        </span>
      </div>
      <div className="col-12 mt-2">
        {agricultureField.map((field, index) => (
          <div
            key={field.id}
            className="row py-2 px-2 border border-3 border-primary rounded mt-2"
          >
            <div className="col-12">
              <material.Button
                type="button"
                variant="contained"
                // hidden={watch("numberOfLandUserInGovLand") == index - 1}
                onClick={() => removeAgriculture(index)}
                color="error"
              >
                Remove
              </material.Button>
            </div>
            <div className="col-4 col-sm-12 col-md-6 col-lg-4">
              <material.FormControl
                size="small"
                variant="standard"
                className="w-100"
              >
                <material.InputLabel id="demo-select-small-label">
                  Crop Type (Code)
                </material.InputLabel>
                <material.Select
                  name="cropType"
                  {...register(`agricultureDetails[${index}].cropType`, {
                    required: true,
                  })}
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  // value={selectedRelation}
                  label="Select an option"
                  // onChange={onChangeRelationship}
                  required
                >
                  {dropDownValue.cropCodeList.map((ele, cropind) => (
                    <material.MenuItem key={cropind} value={ele.value}>
                      {ele.name}
                    </material.MenuItem>
                  ))}
                </material.Select>
              </material.FormControl>
            </div>
            <div className="col-4 col-sm-12 col-md-6 col-lg-4">
              <material.FormControl
                size="small"
                variant="standard"
                className="w-100"
              >
                <material.InputLabel id="demo-select-small-label">
                  Crop Status (Code)
                </material.InputLabel>
                <material.Select
                  name="cropStatus"
                  {...register(`agricultureDetails[${index}].cropStatus`, {
                    required: true,
                  })}
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  // value={selectedRelation}
                  label="Select an option"
                  // onChange={onChangeRelationship}
                  required
                >
                  {dropDownValue.cropStatusList.map((ele, cropind) => (
                    <material.MenuItem key={cropind} value={ele.value}>
                      {ele.name}
                    </material.MenuItem>
                  ))}
                </material.Select>
              </material.FormControl>
            </div>
            <div className="col-4 col-sm-12 col-md-6 col-lg-4">
              <material.FormControl
                size="small"
                variant="standard"
                className="w-100"
              >
                <material.InputLabel id="demo-select-small-label">
                  Variety of Crop Type (Code)
                </material.InputLabel>
                <material.Select
                  name="varietyOfCropType"
                  {...register(
                    `agricultureDetails[${index}].varietyOfCropType `,
                    {
                      required: true,
                    }
                  )}
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  // value={selectedRelation}
                  label="Select an option"
                  // onChange={onChangeRelationship}
                  required
                >
                  {dropDownValue.cropVarietyList.map((ele, cropind) => (
                    <material.MenuItem key={cropind} value={ele.value}>
                      {ele.name}
                    </material.MenuItem>
                  ))}
                </material.Select>
              </material.FormControl>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <material.TextField
                name="percentageOfParcelCrop"
                {...register(
                  `agricultureDetails[${index}].percentageOfParcelCrop`,
                  {
                    required: true,
                  }
                )}
                label="Percentage of Parcel Cropped "
                id="outlined-size-small"
                size="small"
                type="text"
                fullWidth
                variant="standard"
                className="w-100"
                required
              />
            </div>
            <div className="col-4 col-sm-12 col-md-6 col-lg-4">
              <material.FormControl
                size="small"
                variant="standard"
                className="w-100"
              >
                <material.InputLabel id="demo-select-small-label">
                  Source of Irrigation (Code)
                </material.InputLabel>
                <material.Select
                  name="sourceOfIrrigation"
                  {...register(
                    `agricultureDetails[${index}].sourceOfIrrigation`,
                    {
                      required: true,
                    }
                  )}
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  // value={selectedRelation}
                  label="Select an option"
                  // onChange={onChangeRelationship}
                  required
                >
                  {dropDownValue.irrigationSourceList.map((ele, cropind) => (
                    <material.MenuItem key={cropind} value={ele.value}>
                      {ele.name}
                    </material.MenuItem>
                  ))}
                </material.Select>
              </material.FormControl>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <material.TextField
                name="cropProductionCost"
                {...register(
                  `agricultureDetails[${index}].cropProductionCost`,
                  {
                    required: true,
                  }
                )}
                label="Input Costs for Crop Production"
                id="outlined-size-small"
                size="small"
                type="number"
                fullWidth
                variant="standard"
                className="w-100"
                required
              />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4 ">
              <material.TextField
                name="numberOfLabourersEngaged"
                {...register(
                  `agricultureDetails[${index}].numberOfLabourersEngaged`,
                  {
                    required: true,
                  }
                )}
                label="Number of Labourers engaged"
                id="outlined-size-small"
                size="small"
                type="number"
                fullWidth
                variant="standard"
                className="w-100"
                required
              />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4 ">
              <material.TextField
                name="averageWageRate"
                {...register(`agricultureDetails[${index}].averageWageRate`, {
                  required: true,
                })}
                label="Average wage rate (in rupees)"
                id="outlined-size-small"
                size="small"
                type="number"
                fullWidth
                variant="standard"
                className="w-100"
                required
              />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <material.TextField
                name="totalExpenditure"
                {...register(`agricultureDetails[${index}].totalExpenditure`, {
                  required: true,
                })}
                label="Total expenditure (in rupees)"
                id="outlined-size-small"
                size="small"
                type="number"
                fullWidth
                variant="standard"
                className="w-100"
                required
              />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4 ">
              <material.TextField
                name="annualTurnover"
                {...register(`agricultureDetails[${index}].annualTurnover`, {
                  required: true,
                })}
                label="Annual turnover (in rupees)"
                id="outlined-size-small"
                size="small"
                type="number"
                fullWidth
                variant="standard"
                className="w-100"
                required
              />
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default Agriculture;
