import { React, useEffect, useState } from 'react';
import { material } from '../../../library/material';
import './PolutionDataShowingDialog.css'
import { getAllAirEmission, getAllWasteGeneration, getAllWaterDischarge, getAllWaterWithdrawal } from '../../../services/FacilityInfo';
import PolutionDataTable from '../../table/table-for-polution-data/PolutionDataTable';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import Chart from 'react-google-charts';
import Loader from '../../loader/Loader';

const StyledTableCell = styled(material.TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#0d47a1",
        color: theme.palette.common.white,
        fontWeight: "bold",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,

    },
}));

function PolutionDataShowingDialog({ polutionData, close }) {

    const [polutionDatadetails, setPolutionDatadeatils] = useState({ ...polutionData });
    const [wasteData, setWasteData] = useState([]);
    const [withdrawalData, setWithdrawalData] = useState([]);
    const [waterDischargeData, setWaterDischargeData] = useState([]);
    const [airEmissionData, setAirEmissionData] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [isLoading, setIsloading] = useState(false)
    useEffect(() => {
        setPolutionDatadeatils(polutionData);
        getPolutionData();
    }, [polutionData])

    const data = [
        ["Year", "Sales"],
        ["2014", 1000],
        ["2015", 1170,],
        ["2016", 660,],
        ["2017", 1030],
    ];

    // const options = {
    //     chart: {
    //         title: "Population of Largest U.S. Cities",
    //         subtitle: "Based on most recent and previous census data",
    //     },
    //     vAxis: {
    //         title: 'Sales (USD)',
    //         format: '#ML', // Format axis labels as currency
    //         minValue: 0,
    //     },
    //     hAxis: {
    //         title: 'Year',
    //     },
    // };

    const closePolutionDialog = () => {
        close()
    }

    const getPolutionData = async () => {
        setIsloading(true)
        let facilityRefNo = polutionData?.industryDetails?.ref_no;
        let corporateRefNo = polutionData?.industryDetails?.corporate_ref_no;
        if (polutionData?.openFor === 'waste') {
            await getAllWasteGeneration(facilityRefNo, corporateRefNo).then(rep => {
                setWasteData(rep.data.dtoList)
                createChartWasteData(rep.data.dtoList)
            })
            setIsloading(false)
        } else if (polutionData?.openFor === 'water') {
            Promise.all([getAllWaterWithdrawal(facilityRefNo, corporateRefNo), getAllWaterDischarge(facilityRefNo, corporateRefNo)]).then(resp => {
                let waterWithDrawalData = resp[0].data.withdrawalDataDtoList.map(ele => {
                    if (ele.waterStressed) {
                        ele.waterStressed = "Yes";
                    } else {
                        ele.waterStressed = "No";
                    }
                    return ele;
                })
                setWithdrawalData(waterWithDrawalData);

                // setWaterDischargeData(resp[1].data.dischargeDataList)

                // Group data by dischargePoint
                const groupedData = resp[1].data.dischargeDataList.reduce((result, currentItem) => {
                    const dischargePoint = currentItem.dischargePoint;

                    // Check if dischargePoint already exists in result
                    const existingItem = result.find(item => item.dischargePoint === dischargePoint);

                    if (existingItem) {
                        // Append to existing dischargePoint
                        existingItem.data.push(currentItem);
                    } else {
                        // Create a new dischargePoint entry
                        result.push({
                            dischargePoint: dischargePoint,
                            data: [currentItem]
                        });
                    }

                    return result;
                }, []);

                setWaterDischargeData(groupedData)

            })
        } else if (polutionData?.openFor === 'air') {
            getAllAirEmission(facilityRefNo, corporateRefNo).then(respon => {
                console.log(respon.data.airEmissionsPointSourceDtos)
                setAirEmissionData(respon.data.airEmissionsPointSourceDtos)
                createChartAirData(respon.data.airEmissionsPointSourceDtos)
            })
        }
    }

    const createChartAirData = (airDtolist) => {
        let airChartData = airDtolist.map(ele => {
            let sourceIds = ele.sourceIds;
            let obj = { sourceIds }
            let polutantObj = {}
            ele.airEmissionPointSourcePollutantDto.map(res => {
                polutantObj[res.pollutant] = res.pollutantRate

            })
            let mainObj = { ...obj, ...polutantObj }
            //console.log(mainObj)
            return mainObj
        })
        const chartDataAir = [
            ['Source ID', 'NO2', 'SO2', 'PM10', 'CO', 'Max Limit'],
            ...airChartData.map(({ sourceIds, NO2, SO2, PM10, CO }) => [
                sourceIds, NO2, SO2, PM10, CO, 50 // Example max limit value (you can adjust this)
            ])
        ];
        setChartData(chartDataAir)
    }

    const createChartWasteData = (wasteDataList) => {
        let chartData = [
            ['Waste Category', 'Amount (MT)'], // Chart header
            ...wasteDataList.map((item) => [checkFirstBracketIspresentOnTheString(item.wasteCategory), item.amount]), // Map data to chart rows
        ];
        setChartData(chartData)
    }

    const checkFirstBracketIspresentOnTheString = (stringValue) => {
        const containsClosingParenthesis = /\(/.test(stringValue);
        if (containsClosingParenthesis) {
            return stringValue.split('(')[0]
        } else {
            return stringValue
        }

    }

    const generateColumsForWaste = (columns) => {
        const newArray = columns.filter(item => ["wasteCategory", "type", "activity", "amount", "unit"].includes(item));
        return newArray || [];
    }

    const generateColumsForWaterWithdrawal = (columns) => {
        const newArray = columns.filter(item => ["typeOfSource", "typeOfWater", "waterStressed", "amount", "unit"].includes(item));
        return newArray || [];
    }

    // const generateColumsForWaterDischarge = (columns) => {
    //     const newArray = columns.filter(item => ["dischargePoint", "typeOfWater", "waterStressed", "amount", "unit"].includes(item));
    //     return newArray || [];
    // }

    const calculateFlowrate = (diaMeter, velocity) => {
        let radius = (diaMeter / 2).toFixed(2)
        const pi = Math.PI;
        const area = pi * Math.pow(radius, 2);
        let flowRate = Number(area) * Number(velocity);
        return flowRate.toFixed(2);
    }

    return (
        <div>
            <material.Dialog fullScreen hideBackdrop open={polutionDatadetails?.open} sx={{ p: 4, mt: 12 }}>
                <material.DialogTitle className='fw-bold'>
                    {polutionDatadetails?.openFor === 'water' ? "Water Withdrawal And Water Discharge History" :
                        polutionDatadetails?.openFor === 'air' ? "Air Emissions - Point Source History" :
                            polutionDatadetails?.openFor === 'waste' ? "Waste Generation History" : null
                    }
                </material.DialogTitle>
                <material.DialogContent className='mt-2'>
                    {
                        polutionDatadetails?.openFor === 'waste' ?
                            (
                                <div className="row">
                                    <div className="col-12">
                                        <PolutionDataTable columns={generateColumsForWaste(Object.keys(wasteData.length > 0 ? wasteData[0] : {}))} data={wasteData} />
                                    </div>
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-6 p-1">
                                                <material.Card className='w-100 h-100 mt-2'>
                                                    <Chart
                                                        width='100%'
                                                        height={'400px'}
                                                        chartType="Bar"
                                                        loader={<Loader isLoading={isLoading} />}
                                                        data={chartData}
                                                        options={{
                                                            chart: {
                                                                title: 'Waste Generation History',
                                                                subtitle: 'In Metric Tons (MT)',
                                                            },
                                                            hAxis: {
                                                                title: 'Amount (MT)',
                                                                minValue: 0,
                                                            },
                                                            vAxis: {
                                                                title: 'Waste Amounts by Category',
                                                            },
                                                        }}
                                                    />
                                                </material.Card>
                                            </div>
                                            <div className="col-6 p-1">
                                                <material.Card className='w-100 h-100 mt-2'>
                                                    <Chart
                                                        chartType="PieChart"
                                                        data={chartData}
                                                        options={{
                                                            title: "Waste Generation History",
                                                        }}
                                                        width={"100%"}
                                                        height={"400px"}
                                                    />
                                                </material.Card>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : null

                    }
                    {
                        polutionDatadetails?.openFor === 'water' ?
                            (
                                <div className='row'>
                                    <div className="col-12">
                                        <div className='fw-bold my-2 text-dark fs-5 bg-light py-2 ps-2 '>
                                            Water Withdrawal
                                        </div>
                                        <PolutionDataTable columns={generateColumsForWaterWithdrawal(Object.keys(withdrawalData.length > 0 ? withdrawalData[0] : {}))} data={withdrawalData} />
                                    </div>
                                    <div className="col-12 mt-2">
                                        <div className='fw-bold my-2 text-dark fs-5 bg-light py-2 ps-2 '>
                                            Water Discharge
                                        </div>
                                        <material.Paper>
                                            <material.TableContainer sx={{ maxHeight: 390 }}>
                                                <material.Table stickyHeader aria-label="sticky table">
                                                    <material.TableHead sx={{ fontWeight: 'bold', color: 'white', textTransform: 'capitalize', backgroundColor: 'blue' }}>
                                                        <material.TableRow sx={{ textTransform: 'capitalize', height: 20 }} >
                                                            <StyledTableCell size='large'
                                                                align='center'
                                                            >
                                                                Discharge point
                                                            </StyledTableCell>
                                                            <StyledTableCell size='large'
                                                                align='center'
                                                            >
                                                                Discharge amount
                                                            </StyledTableCell>
                                                            <StyledTableCell size='large'
                                                                align='center'
                                                            >
                                                                Pollutant
                                                            </StyledTableCell>
                                                            <StyledTableCell size='large'
                                                                align='center'
                                                            >
                                                                Conc.
                                                            </StyledTableCell>
                                                            <StyledTableCell size='large'
                                                                align='center'
                                                            >
                                                                Unit
                                                            </StyledTableCell>
                                                            <StyledTableCell size='large'
                                                                align='center'
                                                            >
                                                                Discharge Destination
                                                            </StyledTableCell>
                                                        </material.TableRow>
                                                    </material.TableHead>
                                                    <material.TableBody>
                                                        {waterDischargeData.map((rowData, ind) =>
                                                            <material.TableRow key={ind}>
                                                                <material.TableCell size='large' align='center'>
                                                                    {rowData.dischargePoint}
                                                                </material.TableCell>
                                                                <material.TableCell size='large' align='center'>
                                                                    {
                                                                        // rowData.data.map((respon, ind) =>
                                                                        //     <div key={ind} className='py-2 border border-1'>
                                                                        //         {respon.finalDischarge}
                                                                        //     </div>
                                                                        // )
                                                                        rowData.data[0].finalDischarge
                                                                    }
                                                                </material.TableCell>
                                                                <material.TableCell size='large' align='center'>
                                                                    {
                                                                        rowData.data.map((respon, ind) =>
                                                                            <div key={ind} className='py-2 border border-1'>
                                                                                {respon.qualityPollutantParameter}
                                                                            </div>
                                                                        )
                                                                    }
                                                                </material.TableCell>
                                                                <material.TableCell size='large' align='center'>
                                                                    {
                                                                        rowData.data.map((respon, ind) =>
                                                                            <div key={ind} className='py-2 border border-1'>
                                                                                {respon.qualityPollutantValue}
                                                                            </div>
                                                                        )
                                                                    }
                                                                </material.TableCell>
                                                                <material.TableCell size='large' align='center'>
                                                                    {
                                                                        rowData.data.map((respon, ind) =>
                                                                            <div key={ind} className='py-2 border border-1'>
                                                                                {respon.qualityUnit}
                                                                            </div>
                                                                        )
                                                                    }
                                                                    {/* {rowData.qualityUnit} */}
                                                                </material.TableCell>
                                                                <material.TableCell size='large' align='center'>
                                                                    {
                                                                        rowData.data.map((respon, ind) =>
                                                                            <div key={ind} className='py-2 border border-1'>
                                                                                {respon.dischargeDestination}
                                                                            </div>
                                                                        )
                                                                    }
                                                                    {/* {rowData.dischargeDestination} */}
                                                                </material.TableCell>
                                                            </material.TableRow>
                                                        )}
                                                    </material.TableBody>
                                                </material.Table>
                                            </material.TableContainer>
                                        </material.Paper>
                                    </div>
                                </div>
                            ) : null

                    }
                    {
                        polutionDatadetails?.openFor === 'air' ?
                            <div className='row'>
                                <div className="col-12">
                                    <material.Paper>
                                        <material.TableContainer sx={{ maxHeight: 390 }}>
                                            <material.Table stickyHeader aria-label="sticky table">
                                                <material.TableHead sx={{ fontWeight: 'bold', color: 'white', textTransform: 'capitalize', backgroundColor: 'blue' }}>
                                                    <material.TableRow sx={{ textTransform: 'capitalize', height: 20 }} >
                                                        <StyledTableCell size='large'
                                                            align='center'
                                                        >
                                                            Source ID
                                                        </StyledTableCell>
                                                        <StyledTableCell size='large'
                                                            align='center'
                                                        >
                                                            Flow rate (m³/sec)
                                                        </StyledTableCell>
                                                        <StyledTableCell size='large'
                                                            align='center'
                                                        >
                                                            Emission parameter
                                                        </StyledTableCell>
                                                        <StyledTableCell size='large'
                                                            align='center'
                                                        >
                                                            Concentration (mg/m³)
                                                        </StyledTableCell>
                                                    </material.TableRow>
                                                </material.TableHead>
                                                <material.TableBody>
                                                    {airEmissionData.map((rowData, ind) =>
                                                        <material.TableRow key={ind}>
                                                            <material.TableCell size='large' align='center'>
                                                                {rowData.sourceIds}
                                                            </material.TableCell>
                                                            <material.TableCell size='large' align='center'>
                                                                {calculateFlowrate(rowData.stackDia, rowData.stackVe)}
                                                            </material.TableCell>
                                                            <material.TableCell size='large' align='center'>
                                                                {
                                                                    rowData.airEmissionPointSourcePollutantDto.map((respon, ind) =>
                                                                        <div key={ind} className='py-2 border border-1'>
                                                                            {respon.pollutant}
                                                                        </div>
                                                                    )
                                                                }
                                                            </material.TableCell>
                                                            <material.TableCell size='large' align='center'>
                                                                {
                                                                    rowData.airEmissionPointSourcePollutantDto.map((respon, ind) =>
                                                                        <div key={ind} className='py-2 border border-1'>
                                                                            {respon.concentration}
                                                                        </div>
                                                                    )
                                                                }
                                                            </material.TableCell>
                                                        </material.TableRow>
                                                    )}
                                                </material.TableBody>
                                            </material.Table>
                                        </material.TableContainer>
                                    </material.Paper>
                                </div>
                                <div className="col-12">
                                    <material.Card className='mt-2 w-100 p-1'>
                                        <Chart
                                            width="100%"
                                            height={'400px'}
                                            chartType="ComboChart"
                                            loader={<div>Loading Chart...</div>}
                                            data={chartData}
                                            options={{
                                                title: 'Emission Data by Source ID',
                                                subtitle: 'In Parts per Million (PPM)',
                                                vAxis: { title: 'Source ID' },
                                                hAxis: { title: 'Concentration' },
                                                seriesType: 'bars',
                                                series: { 4: { type: 'line' } }, // Line series for Max Limit
                                                colors: ['#3366CC', '#DC3912', '#FF9900', '#109618', '#990099'], // Custom colors
                                            }}
                                        />
                                    </material.Card>
                                </div>
                            </div>
                            : null
                    }
                </material.DialogContent>
                <material.DialogActions sx={{ pr: 2, pb: 2 }}>
                    <material.Button variant="contained" color='error' sx={{ textTransform: "none" }} startIcon={<material.CloseIcon />} onClick={closePolutionDialog}>Close</material.Button>
                </material.DialogActions>
            </material.Dialog>
        </div>
    )
}

export default PolutionDataShowingDialog