import { getFeatureByQuery, getLuLcData } from "../services/LayerInfoService";
import { rooApiUrl } from "../environment/Environment";
import * as turf from "@turf/turf";
// import { fromLonLat, toLonLat } from "ol/proj";
import { toLonLat } from "ol/proj";
import { MultiLineString } from "ol/geom";
import { getDistance } from "ol/sphere";
// import Polygon from 'ol/geom/Polygon';
import MultiPolygon from "ol/geom/MultiPolygon";
import { circular } from "ol/geom/Polygon";
import { transform } from "ol/proj";
import { getArea } from 'ol/sphere';
export const generateListOfUrlForFetchingSpatialQueryData = (
  selectedLayerList,
  selectedGeom,
  markerType,
  cordList,
  lengthValue
) => {
  let urlList = [];
  if (markerType == "Point") {
    urlList = selectedLayerList.map((ele, ind) => {
      return ele !== "lulc:WB_LULC50K_1516"
        ? `${rooApiUrl}/haldia/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=${ele}&CQL_FILTER=DWITHIN(${selectedGeom},${markerType.toUpperCase()}(${cordList}),${Number(
            lengthValue
          )},${"meters"})&outputFormat=application/json`
        : "LULC";
    });
  } else {
    const coordinatesString = cordList[0]
      .map((coord) => coord.join(" "))
      .join(", ");
    const polygonWkt = `POLYGON((${coordinatesString}))`;
    urlList = selectedLayerList.map((ele, ind) => {
      return ele !== "lulc:WB_LULC50K_1516"
        ? `${rooApiUrl}/haldia/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=${ele}&CQL_FILTER=INTERSECTS(${selectedGeom},${polygonWkt})&outputFormat=application/json`
        : null;
    });
    //let url = `${rooApiUrl}/haldia/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=${formData.featureOf}&CQL_FILTER=INTERSECTS(${selectedGeom},${polygonWkt})&outputFormat=application/json`;
  }
  return urlList;
};

export const generateDataForSpatialQuery = async (
  urlList,
  layerList,
  pointCord,
  radiusInMeters,
  cordList,
  area
) => {
  try {
    const results = await Promise.all(
      urlList.map(async (ele, ind) => {
        let res;
        if (ele !== null && ele !== "LULC") {
          res = await getFeatureByQuery(ele);
        } else if (pointCord !== null && ele === "LULC") {
          // let centerCoordinates=pointCord.split(' ').map(ele=>Number(ele))
          const polygon = circular(
            transform(pointCord, "EPSG:3857", "EPSG:4326"),
            radiusInMeters
          );
          let circleToPolygonCord = polygon.getCoordinates();
          let circleArea = (
            (Math.PI * Math.pow(radiusInMeters, 2)) /
            1000000
          ).toFixed(2);
          circleArea=Number(circleArea)+Number(circleArea*0.08);
          //console.log(circleArea)
          const convertCoordinates = (coords) => {
            return coords.map((coord) => coord);
          };
          const coordinatesString = convertCoordinates(circleToPolygonCord[0])
            .map((coord) => coord.join(" "))
            .join(", ");
          const polygonWkt = `POLYGON((${coordinatesString}))`;
          let data = await getLuLcData(Number(circleArea), polygonWkt);
          let htmlToJsonData = extractTableData(data.data);

          let layerName = layerList[ind].split(":")[1];
          return {
            layerName: layerName,
            tableData: [...htmlToJsonData],
            columnName: Object.keys(htmlToJsonData[0]),
            htmlContent: data.data,
            totalSelectedArea: circleArea,
          };
        } else {
          const convertCoordinate = (coords) => {
            return coords.map((coord) => toLonLat(coord));
          };
          const coordinatesString = convertCoordinate(cordList[0])
            .map((coord) => coord.join(" "))
            .join(", ");
          const polygonWkt = `POLYGON((${coordinatesString}))`;
          let data = await getLuLcData(area, polygonWkt);
          let htmlToJsonData = extractTableData(data.data);

          let layerName = layerList[ind].split(":")[1];
          return {
            layerName: layerName,
            tableData: [...htmlToJsonData],
            columnName: Object.keys(htmlToJsonData[0]),
            htmlContent: data.data,
            totalSelectedArea: area,
          };
        }
        //api is called in this line;

        var featureCord;
        var distance;
        if (pointCord !== null && layerList[ind] !== "lulc:WB_LULC50K_1516") {
          res.data.features.forEach((feature) => {
            if (
              feature.geometry.type === "Polygon" ||
              feature.geometry.type === "MultiPolygon"
            ) {
              featureCord = feature.geometry.coordinates;
              const multipolygon = new MultiPolygon(featureCord);
              let minDistance = Infinity;
              let closestPoint = null;
              multipolygon.getPolygons().forEach((polygon) => {
                const currentClosestPoint = polygon.getClosestPoint(pointCord);
                const distance = getDistance(
                  toLonLat(currentClosestPoint),
                  toLonLat(pointCord)
                );
                if (distance < minDistance) {
                  minDistance = distance;
                  closestPoint = currentClosestPoint;
                }
              });
              distance = calculateDistance(closestPoint, pointCord);
            } else if (feature.geometry.type === "MultiLineString") {
              featureCord = feature.geometry.coordinates;
              const multiLineString = new MultiLineString(featureCord);
              let minDistance = Infinity;
              let closestPoint = null;
              multiLineString.getLineStrings().forEach((lineString) => {
                const currentClosestPoint =
                  lineString.getClosestPoint(pointCord);
                const distance = getDistance(
                  toLonLat(currentClosestPoint),
                  toLonLat(pointCord)
                );
                if (distance < minDistance) {
                  minDistance = distance;
                  closestPoint = currentClosestPoint;
                }
              });
              distance = calculateDistance(closestPoint, pointCord);
            } else if (feature.geometry.type === "LineString") {
              featureCord = feature.geometry.coordinates;
            } else if (feature.geometry.type === "Point") {
              featureCord = feature.geometry.coordinates;
              distance = calculateDistance(featureCord, pointCord);
            }
            feature.properties["distance"] = distance?.toFixed(2)?.concat("km");
          });
        }
        let columnName =
          res?.data?.features?.length > 0
            ? Object.keys(res.data.features[0].properties)
            : [];
        //columnName = (columnName.length !== 0) && (columnName.length) > 10 ? columnName.filter((colName, colInd) => colInd <= 9) : columnName;
        let tableData =
          res?.data?.features?.length > 0
            ? res.data.features.map((ele) => ele.properties)
            : [];
        let layerName = layerList[ind].split(":")[1];
        let obj = {
          layerName: layerName,
          tableData: tableData,
          columnName: columnName,
          htmlContent: null,
          totalSelectedArea: null,
        };
        return obj;
      })
    );
    return results;
  } catch (error) {
    // Handle errors if needed
    console.error(error);
    throw error; // Re-throw the error to propagate it if necessary
  }
};
// const getClosestPoint = (featureCoords, pointCoords) => {
//   const point = turf.point(pointCoords);
//   const polygon = turf.lineStrings(featureCoords);
//   const closestPointOnPolygon = turf.nearestPointOnLine(polygon, point);
//   return closestPointOnPolygon.geometry.coordinates;
// };
const calculateDistance = (featureCoords, pointCoords) => {
  const [longitude, latitude] = toLonLat(pointCoords);
  const [featureLongitude, featureLatitude] = toLonLat(featureCoords);
  // Calculate distance between the two points (result in kilometers by default)
  const options = { units: "kilometers" }; // Change units as needed
  const distance = turf.distance(
    [longitude, latitude],
    [featureLongitude, featureLatitude],
    options
  );
  return distance;
};

export const getSpatialQueryForOneMeter = async (
  layerList,
  selectedGeom,
  markerType,
  cordList
) => {
  let urlList = generateListOfUrlForFetchingSpatialQueryData(
    layerList,
    selectedGeom,
    markerType,
    cordList,
    1
  );
  try {
    const results = await Promise.all(
      urlList.map(async (ele, ind) => {
        let res = await getFeatureByQuery(ele); //api is called in this line;
        let columnName =
          res.data?.features?.length > 0
            ? Object.keys(res.data.features[0].properties)
            : [];
        columnName =
          columnName.length !== 0 && columnName.length > 10
            ? columnName.filter((colName, colInd) => colInd <= 9)
            : columnName;
        let tableData =
          res.data.features.length > 0
            ? res.data.features.map((ele) => ele.properties)
            : [];
        let layerName = layerList[ind].split(":")[1];
        let obj = {
          layerName: layerName,
          tableData: tableData,
          columnName: columnName,
        };
        return obj;
      })
    );
    return results;
  } catch (error) {
    // Handle errors if needed
    console.error(error);
    throw error; // Re-throw the error to propagate it if necessary
  }
};

export const updateDistanceValue = (spatilDataForOneMeter, spatilData) => {
  // spatilDataForOneMeter.forEach((obj1) => {
  //   spatilData.forEach((obj2) => {
  //     if (obj1.layerName === obj2.layerName) {
  //       obj1.tableData.forEach((data1) => {
  //         obj2.tableData.forEach((data2) => {
  //           if (data1.gid === data2.gid) {
  //             data2.distance = "Present in it";
  //           }
  //         });
  //       });
  //     }
  //   });
  // });
  return spatilData;
};

const extractTableData = (html) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");

  const rows = doc.querySelectorAll("#analysis_div table tr");
  const data = [];

  rows.forEach((row, index) => {
    // Skip header row
    if (index === 0) return;

    const cells = row.querySelectorAll("td");
    if (cells.length === 3) {
      const rowData = {
        color: cells[0].getAttribute("bgcolor"),
        lulcClass: cells[1].innerText.trim(),
        area: cells[2].innerText.trim(),
      };
      data.push(rowData);
    }
  });

  return data;
};
